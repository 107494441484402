import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Block from "components/templatesComponents/Block";
import Button from "components/templatesComponents/Button";
import Link from "components/templatesComponents/Link";
import { ImageContainer } from "components/templatesComponents/Image";
import SubTemplateWrapper from "components/LayoutFront/SubTemplateWrapper";
import { tabsIdentifier } from "components/templates/who/Who";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.up("lg")]: {
    container: {
      position: "relative",
    },
    image: {
      width: "60%",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: -1,
    },
    content: {
      width: "50%",
      float: "right",
    },

    shortDescription: {
      position: "relative",
      padding: "60px",
      minHeight: "170px",
      "&:before": {
        content: `""`,
        background: "white",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        border: "2px solid #e7e7e7",
        zIndex: "-1",
      },
    },
  },
  buttonLink: {
    marginTop: theme.spacing(1),
  },
  links: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    "& button": {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up("lg")]: {
      zIndex: 1,
      padding: "0 60px",
      marginTop: "-60px",
    },
  },
}));

const HomeWho = (props) => {
  const { page } = props;
  const { title, shortDescription, fullPath, image, contents } = page;

  const { history, decrees, governance } = contents;

  const classes = useStyles();
  const theme = useTheme();

  return (
    <SubTemplateWrapper backgroundColor={theme.palette.primary[10]} title={title}>
      <div className={classes.container}>
        <div className={classes.image}>
          <ImageContainer ratio={55}>{image}</ImageContainer>
        </div>
        <div className={classes.content}>
          <Block className={classes.shortDescription}>{shortDescription}</Block>
          <Block>
            <div className={classes.links}>
              <Button
                fullWidth
                component={Link}
                linkComponent
                url={`${fullPath}?${tabsIdentifier}=${history.tab.url}`}
                page={history}
                size="lg"
                aria-label={history.tab.title}
              >
                {history.tab.title}
              </Button>
              <Button
                fullWidth
                className={`${classes.buttonLink}`}
                component={Link}
                linkComponent
                url={`${fullPath}?${tabsIdentifier}=${decrees.tab.url}`}
                page={decrees}
                size="lg"
                aria-label={decrees.tab.title}
              >
                {decrees.tab.title}
              </Button>
              <Button
                fullWidth
                className={`${classes.buttonLink}`}
                component={Link}
                linkComponent
                url={`${fullPath}?${tabsIdentifier}=${governance.tab.url}`}
                page={governance}
                size="lg"
                aria-label={governance.tab.title}
              >
                {governance.tab.title}
              </Button>
            </div>
          </Block>
        </div>
      </div>
    </SubTemplateWrapper>
  );
};

HomeWho.propTypes = {
  page: PropTypes.shape(),
};

HomeWho.defaultProps = {
  page: {},
};

export default HomeWho;
