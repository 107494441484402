import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import PageTitle from "components/templatesComponents/PageTitle";
import CardContext from "components/templatesComponents/CardContext";

const Missions = (props) => {
  const { page } = props;
  const { title, shortDescription, contents } = page;
  const { pageLinks } = contents;

  return (
    <Wrapper>
      <Block>
        <PageTitle>{title}</PageTitle>
      </Block>
      <Block>{shortDescription}</Block>
      <CardContext.Provider
        value={{
          Wrapper: Box,
          wrapperProps: {
            my: 1,
          },
        }}
      >
        {pageLinks}
      </CardContext.Provider>
    </Wrapper>
  );
};

Missions.propTypes = { page: PropTypes.shape().isRequired };

export default Missions;
