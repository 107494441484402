import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import PageTitle from "components/templatesComponents/PageTitle";
import { makeStyles } from "@material-ui/styles";
import SectionTitle from "components/templatesComponents/SectionTitle";
import Hidden from "components/templatesComponents/Hidden";
import Tabs from "components/templatesComponents/Tabs";
import Accordion from "components/templatesComponents/Accordion";
import { stringToPath } from "utils/urlUtils";
import HelpForm from "components/HelpForm";

const useStyles = makeStyles((theme) => ({
  frequentlyAskedQuestionsWrapper: {
    [theme.breakpoints.up("lg")]: {
      paddingBottom: theme.spacing(10),
    },
  },
  frequentlyAskedQuestionsTabs: {
    height: "97px",
    backgroundColor: theme.palette.componentColors[10],
    borderBottom: `8px solid ${theme.palette.secondary.main}`,
    textTransform: "none",
    fontSize: "0.875rem",
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      "button&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    "& .MuiTab-wrapper": {
      display: "block",
    },
    "&.MuiTab-textColorInherit": {
      opacity: "inherit",
    },
    "&.Mui-focusVisible": {
      border: "2px solid #0a76f6",
    },
  },
  frequentlyAskedQuestionsPanel: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.componentColors[30]}`,
  },
  sectionTitle: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(4),
    },
  },
}));

const Help = (props) => {
  const { page } = props;
  const { title, contents } = page;
  const { frequentlyAskedQuestionsTitle, frequentlyAskedQuestions } = contents;

  let { tabs: frequentlyAskedQuestionsTabs = [] } = frequentlyAskedQuestions;

  const displayTabs = typeof frequentlyAskedQuestionsTitle === "object" || frequentlyAskedQuestionsTabs.length > 0;

  const displayTabsBlock = frequentlyAskedQuestionsTitle || displayTabs;

  const [sendTag, setSendTag] = useState(false);

  const classes = useStyles();

  // frequently asked questions
  if (!Array.isArray(frequentlyAskedQuestionsTabs)) {
    frequentlyAskedQuestionsTabs = [frequentlyAskedQuestionsTabs];
  }
  frequentlyAskedQuestionsTabs = frequentlyAskedQuestionsTabs.map((t) => ({ ...t, url: stringToPath(t.title) }));

  return (
    <Fragment>
      <Wrapper>
        <Block>
          <PageTitle>{title}</PageTitle>
        </Block>
        {displayTabsBlock && (
          <Block className={classes.frequentlyAskedQuestionsWrapper}>
            {frequentlyAskedQuestionsTitle && (
              <SectionTitle className={classes.sectionTitle}>{frequentlyAskedQuestionsTitle}</SectionTitle>
            )}
            {displayTabs && (
              <>
                <Hidden mdDown>
                  <Tabs
                    {...frequentlyAskedQuestions}
                    tabsProps={{ className: classes.frequentlyAskedQuestionsTabs }}
                    panelClass={classes.frequentlyAskedQuestionsPanel}
                    tabs={frequentlyAskedQuestionsTabs}
                    variant="fullWidth"
                    sendTag={sendTag}
                    setSendTag={setSendTag}
                  />
                </Hidden>
                <Hidden lgUp>
                  <Accordion
                    panels={frequentlyAskedQuestionsTabs.map((q) => ({
                      id: q.id,
                      summary: q.title,
                      details: q.children,
                    }))}
                  />
                </Hidden>
              </>
            )}
          </Block>
        )}
      </Wrapper>
      <HelpForm page={page} />
    </Fragment>
  );
};

Help.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default Help;
