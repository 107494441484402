import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/styles";
import MuiModal from "@material-ui/core/Modal";

const useActionsStyles = makeStyles((theme) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    "&> *": {
      marginLeft: theme.spacing(1),
    },
  },
}));

export const ModalActions = (props) => {
  const classes = useActionsStyles();

  return <div className={classes.actions} {...props} />;
};

const useStyles = makeStyles((theme) => ({
  wrapper: ({ alignItems }) => ({
    position: "absolute",
    top: alignItems === "flex-start" ? "56px" : "0",
    left: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems,
    justifyContent: "center",
  }),
  main: ({ size }) => ({
    width: `${size && theme.breakpoints.values[size] ? `${theme.breakpoints.values[size]}px` : "auto"}`,
    maxWidth: `100%`,
    maxHeight: "95%",
    overflow: "auto",
  }),
}));

const Modal = (props) => {
  const { children, onClose, size, alignItems, className, ...others } = props;

  const classes = useStyles({ size, alignItems });

  return (
    <MuiModal onClose={onClose} {...others}>
      <div className={classes.wrapper} onClick={onClose}>
        <Paper onClick={(e) => e.stopPropagation()} className={classes.main}>
          <Box p={2} className={className}>
            {children}
          </Box>
        </Paper>
      </div>
    </MuiModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  alignItems: PropTypes.oneOf(["flex-start", "center"]),
};

Modal.defaultProps = {
  className: "",
  children: null,
  size: null,
  alignItems: "center",
};

export default Modal;
