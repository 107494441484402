import React from "react";
import PropTypes from "prop-types";

const HoneyPot = (props) => {
  const { name } = props;

  if (name === null) return null;

  return (
    <div style={{ display: "none" }}>
      <input type="text" name={name} id={name} value="" />
    </div>
  );
};

HoneyPot.propTypes = {
  name: PropTypes.string.isRequired,
};

export default HoneyPot;
