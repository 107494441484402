import CookieService from "./CookieService";

const COOKIE_CONSENT = "consent";
const COOKIE_CONSENT_ID = "consentId";

const EULERIAN_ID_COOKIENAME = "etuix";
const EULERIAN_CONSENT_COOKIENAME = "eulerian";

class ConsentService {
  static getConsent = () => {
    if (typeof document !== "undefined") {
      const cookieConsentId = CookieService.get(COOKIE_CONSENT_ID);

      if (process.env.cookie_consent_id && cookieConsentId !== process.env.cookie_consent_id) {
        CookieService.delete(COOKIE_CONSENT);
        CookieService.delete(COOKIE_CONSENT_ID);
        CookieService.delete(EULERIAN_ID_COOKIENAME);
        CookieService.delete(EULERIAN_CONSENT_COOKIENAME);
      }
    }
    return CookieService.get(COOKIE_CONSENT);
  };

  static acceptConsent = () => {
    if (typeof document !== "undefined") {
      CookieService.set(COOKIE_CONSENT, true, new Date(Date.now() + this.getConsentMaxeAge() * 1000));
      CookieService.set(
        COOKIE_CONSENT_ID,
        process.env.cookie_consent_id,
        new Date(Date.now() + this.getConsentMaxeAge() * 1000)
      );
    }
  };

  static refuseConsent = () => {
    if (typeof document !== "undefined") {
      CookieService.set(COOKIE_CONSENT, false, new Date(Date.now() + this.getConsentMaxeAge() * 1000));
      CookieService.set(
        COOKIE_CONSENT_ID,
        process.env.cookie_consent_id,
        new Date(Date.now() + this.getConsentMaxeAge() * 1000)
      );
    }
  };

  static getConsentMaxeAge = () => {
    return 13 * 30 * 24 * 60 * 60; // 13 monthes
  };
}

export default ConsentService;
