import elementsTypes from "utils/elementsTypes";
import Home from "components/templates/home/Home";

export default {
  key: "HOME",
  label: "Accueil ANTS",
  Component: Home,
  initialContents: [
    {
      key: "contentTitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "",
    },
    {
      key: "subtitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "",
    },
    {
      key: "linkImmat",
      type: elementsTypes.LINK,
      value: "",
      children: [
        {
          key: "text",
          value: "Immatriculation",
        },
        {
          key: "url",
          value: "https://www.immatriculation.ants.gouv.fr/",
        },
        {
          type: "CHECKBOX",
          key: "external",
          value: "true",
        },
      ],
    },
    {
      key: "linkPermis",
      type: elementsTypes.LINK,
      value: "",
      children: [
        {
          key: "text",
          value: "Permis de conduire",
        },
        {
          key: "url",
          value: "https://www.permisdeconduire.ants.gouv.fr/",
        },
        {
          type: "CHECKBOX",
          key: "external",
          value: "true",
        },
      ],
    },
    {
      key: "linkPasseport",
      type: elementsTypes.LINK,
      value: "",
      children: [
        {
          key: "text",
          value: "Passeport et carte d'identité",
        },
        {
          key: "url",
          value: "https://www.passeport.ants.gouv.fr/",
        },
        {
          type: "CHECKBOX",
          key: "external",
          value: "true",
        },
      ],
    },
    {
      key: "subPages",
      type: elementsTypes.SUBPAGES_LIST,
      value: "",
      children: [],
    },
  ],
};
