import React from "react";
import PropTypes from "prop-types";
import MuiGrid from "@material-ui/core/Grid";

const Grid = (props) => {
  const { size, sizeXs, items } = props;

  if (!items || items.length === 0) {
    return null;
  }

  let arrayItems = items;
  if (!Array.isArray(arrayItems)) {
    arrayItems = [items];
  }

  return (
    <MuiGrid container spacing={2}>
      {arrayItems.map((item) => (
        <MuiGrid key={item.key} item xs={parseInt(sizeXs, 10)} lg={parseInt(size, 10)}>
          {item}
        </MuiGrid>
      ))}
    </MuiGrid>
  );
};

Grid.propTypes = {
  size: PropTypes.string,
  sizeXs: PropTypes.string,
  items: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

Grid.defaultProps = {
  size: 6,
  sizeXs: 12,
  items: [],
};

export default Grid;
