import generateCRUD from "components/adminServices/generateCRUD";
import { generateContentId, adminProperties, replacePagesByIds } from "utils/adminContentsUtils";
import elementsTypes from "utils/elementsTypes";
import { removeProperties } from "utils/commonUtils";

const SitesCRUD = generateCRUD("sites");

const childCacheKey = "updated";

const prepareContents = (contents) => {
  const cleanContents = contents.map((c) => removeProperties(c, adminProperties));
  return cleanContents.map(replacePagesByIds);
};

export default class AdminSitesService extends SitesCRUD {
  static resetSiteCache = (site) => {
    const { id, contents } = site;
    const cacheChild = contents.find((c) => c.key === childCacheKey);
    let newContents = [];
    if (cacheChild) {
      newContents = contents.map((c) => (c.id === cacheChild.id ? { ...cacheChild, value: `${new Date()}` } : c));
    } else {
      newContents = [
        ...contents,
        generateContentId({
          type: elementsTypes.INPUT,
          key: childCacheKey,
          value: `${new Date()}`,
        }),
      ];
    }

    SitesCRUD.update({ id, contents: prepareContents(newContents) });
  };

  static update = (site) => {
    const { contents } = site;
    return SitesCRUD.update({
      ...site,
      contents: prepareContents(contents),
    });
  };
}
