import elementsTypes from "utils/elementsTypes";
import Process from "components/templates/process/Process";

export default {
  key: "PROCESS",
  label: "Démarche",
  Component: Process,
  initialContents: [
    {
      key: "processDescription",
      type: elementsTypes.EDITABLE_INPUT,
      value: "Description de la démarche",
    },
    {
      key: "toKnow",
      type: elementsTypes.TEXT,
      value: "",
    },
    {
      key: "startLink",
      value: "",
      type: elementsTypes.BUTTON,
      children: [
        {
          key: "text",
          type: elementsTypes.INPUT,
          value: "Commencer votre démarche en ligne",
        },
        {
          key: "link",
          type: elementsTypes.LINK,
          value: "",
          children: [
            {
              key: "url",
              type: elementsTypes.URL,
              value: "",
            },
            {
              type: elementsTypes.CHECKBOX,
              key: "external",
              value: "true",
            },
          ],
        },
        {
          key: "icon",
          type: elementsTypes.ICON,
          value: "",
          children: [
            {
              key: "icon",
              type: elementsTypes.INPUT,
              value: "long-arrow-right",
            },
          ],
        },
        {
          key: "primary",
          type: elementsTypes.CHECKBOX,
          value: "true",
        },
      ],
    },
    {
      key: "startLinkFullWidth",
      value: "",
      type: elementsTypes.BUTTON,
      children: [
        {
          key: "text",
          type: elementsTypes.INPUT,
          value: "Commencer votre démarche en ligne",
        },
        {
          key: "link",
          type: elementsTypes.LINK,
          value: "",
          children: [
            {
              key: "url",
              type: elementsTypes.URL,
              value: "",
            },
            {
              type: elementsTypes.CHECKBOX,
              key: "external",
              value: "true",
            },
          ],
        },
        {
          key: "icon",
          type: elementsTypes.ICON,
          value: "",
          children: [
            {
              key: "icon",
              type: elementsTypes.INPUT,
              value: "long-arrow-right",
            },
          ],
        },
        {
          key: "primary",
          type: elementsTypes.CHECKBOX,
          value: "true",
        },
        {
          key: "fullWidth",
          type: elementsTypes.CHECKBOX,
          value: "true",
        },
      ],
    },
    {
      key: "documentsRequiredCount",
      type: elementsTypes.EDITABLE_INPUT,
      value: "Documents à fournir",
    },
    {
      key: "documentsRequired",
      type: elementsTypes.TEXT,
      value: "",
    },
    {
      key: "completionTime",
      type: elementsTypes.EDITABLE_INPUT,
      value: "X minutes",
    },
    {
      key: "stepper",
      type: elementsTypes.STEPPER,
      value: "",
      children: [],
    },
    {
      key: "videosList",
      type: elementsTypes.VIDEOS_LIST,
      value: "",
      children: [
        {
          key: "limit",
          value: "1",
        },
        {
          key: "video",
          type: elementsTypes.VIDEO,
          value: "",
        },
      ],
    },
    {
      key: "relatedQuestions",
      type: elementsTypes.ACCORDION,
      value: "",
      children: [],
    },
  ],
};
