import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import Wrapper from "components/LayoutFront/Wrapper";
import { ImageContainer } from "components/templatesComponents/Image";
import { styleKeys } from "components/templatesComponents/card/cardStyles";
import CardContext from "components/templatesComponents/CardContext";
import Block from "components/templatesComponents/Block";
import SubPageContext from "components/templatesComponents/SubPageContext";
import HomeMairieSubPage from "components/templates/homeMairie/HomeMairieSubPage";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import HomeLastNews from "components/templates/home/HomeLastNews";
import lastNews from "components/templates/news/lastNews";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(2, 0, 0, 0),
    [theme.breakpoints.up("lg")]: {
      maxWidth: "40%",
      margin: theme.spacing(3, 0),
    },
  },
  links: {
    paddingLeft: theme.spacing(2),
    width: "100%",
    margin: 0,
    "& > div": {
      padding: theme.spacing(0.5, 0),
    },
    [theme.breakpoints.up("lg")]: {
      position: "relative",
      zIndex: 1,
      marginTop: "-40px",
      paddingLeft: 0,
      width: `calc(100% + ${theme.spacing(2)}px)`,
      margin: -theme.spacing(1),
      "& > div": {
        padding: theme.spacing(1),
      },
    },
  },
  topContainer: {
    [theme.breakpoints.up("lg")]: {
      position: "relative",
      marginTop: theme.spacing(4),
    },
  },
  image: {
    [theme.breakpoints.up("lg")]: {
      width: "60%",
      position: "absolute",
      top: 0,
      right: 0,
    },
  },
  content: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexDirection: "column",
      height: "423px",
    },
  },
  shortDescription: {
    [theme.breakpoints.up("lg")]: {
      width: "50%",
      padding: "60px",
      boxSizing: "border-box",
      border: `1px solid ${theme.palette.componentColors[30]}`,
      background: "white",
      flex: "1 1 0px",
      zIndex: 1,
    },
  },
  hiddenMobile: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "inherit",
    },
  },
  visibleMobile: {
    display: "inherit",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
}));

const getSubTemplate = (k) => {
  if (k === lastNews.key) {
    return HomeLastNews;
  }
  return HomeMairieSubPage;
};

const HomeMairie = (props) => {
  const { page } = props;

  const { image, shortDescription, contents } = page;

  const { contentTitle, cardCNI, cardPM, cardComedec, subpages } = contents;

  const theme = useTheme();
  const classes = useStyles();

  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Fragment>
      <Wrapper leftProps={{ className: classes.backgroundSide }} rightProps={{ className: classes.background }}>
        <div className={classes.topContainer}>
          <div className={classes.image}>
            <div className={classes.hiddenMobile}>
              <ImageContainer ratio={68}>{image}</ImageContainer>
            </div>
          </div>
          <div className={classes.content}>
            <div className={classes.hiddenMobile}>{!isMobile && <h1 className={classes.title}>{contentTitle}</h1>}</div>
            <Block className={classes.visibleMobile}>
              {isMobile && <h1 className={classes.title}>{contentTitle}</h1>}
            </Block>
            <Block className={classes.shortDescription}>{shortDescription}</Block>
          </div>
        </div>

        <Grid container justifyContent="center" alignItems="stretch" className={classes.links}>
          <Grid item xs={12} lg={4}>
            <CardContext.Provider
              value={{
                desktopStyleKey: styleKeys.VERTICAL_BIG_ONE_LINE,
                mobileStyleKey: styleKeys.HORIZONTAL,
                styleOverride: {
                  root: {
                    backgroundColor: theme.palette.primary[30],
                    "a&:hover": {
                      backgroundColor: theme.palette.primary[30],
                    },
                  },
                },
                mobileStyleOverride: {
                  title: {
                    fontWeight: 600,
                    color: "#fff",
                  },
                  arrow: {
                    color: "#fff",
                  },
                },
              }}
            >
              {cardCNI}
            </CardContext.Provider>
          </Grid>
          <Grid item xs={12} lg={4}>
            <CardContext.Provider
              value={{
                desktopStyleKey: styleKeys.VERTICAL_BIG_ONE_LINE,
                mobileStyleKey: styleKeys.HORIZONTAL,
                styleOverride: {
                  root: {
                    backgroundColor: theme.palette.primary[40],
                    "a&:hover": {
                      backgroundColor: theme.palette.primary[40],
                    },
                  },
                },
                mobileStyleOverride: {
                  title: {
                    fontWeight: 600,
                    color: "#fff",
                  },
                  arrow: {
                    color: "#fff",
                  },
                },
              }}
            >
              {cardPM}
            </CardContext.Provider>
          </Grid>
          <Grid item xs={12} lg={4}>
            <CardContext.Provider
              value={{
                desktopStyleKey: styleKeys.VERTICAL_BIG_ONE_LINE,
                mobileStyleKey: styleKeys.HORIZONTAL,
                styleOverride: {
                  root: {
                    backgroundColor: theme.palette.primary[50],
                    "a&:hover": {
                      backgroundColor: theme.palette.primary[50],
                    },
                  },
                },
                mobileStyleOverride: {
                  title: {
                    fontWeight: 600,
                    color: "#fff",
                  },
                  arrow: {
                    color: "#fff",
                  },
                },
              }}
            >
              {cardComedec}
            </CardContext.Provider>
          </Grid>
        </Grid>
      </Wrapper>
      <SubPageContext.Provider value={{ getSubTemplate }}>{subpages}</SubPageContext.Provider>
    </Fragment>
  );
};

HomeMairie.propTypes = {
  page: PropTypes.shape(),
};

HomeMairie.defaultProps = {
  page: {},
};

export default HomeMairie;
