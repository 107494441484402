import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  rectangle: (props) => ({
    "&:before": {
      position: "absolute",
      top: `${props.top}`,
      bottom: `${props.bottom}`,
      left: props.position === "left" ? 0 : "unset",
      right: 0,
      width: `${props.width}`,
      background: `${props.backgroundColor}`,
      zIndex: -1,
      height: props.bottom === "auto" ? `${props.height}` : "auto",
      [theme.breakpoints.up("lg")]: {
        content: `""`,
      },
    },
  }),
}));

const RectangleBackground = (props) => {
  const { children, backgroundColor, top, bottom, height, position } = props;

  let { width } = props;

  if (width === 0.875) {
    width = "calc(960px + (100% - 960px) * 0.75)";
  } else if (width === 0.75) {
    width = "calc(960px + (100% - 960px) * 0.5)";
  } else if (width === 0.65) {
    width = "calc(960px + (75% - 960px))";
  }
  const classes = useStyles({ backgroundColor, top, bottom, height, position, width });

  return (
    <div className={classes.root}>
      <div className={classes.rectangle}>{children}</div>
    </div>
  );
};

RectangleBackground.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  top: PropTypes.string,
  bottom: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.oneOf([0.65, 0.75, 0.875]),
  position: PropTypes.oneOf(["left", "right"]),
};

RectangleBackground.defaultProps = {
  children: null,
  backgroundColor: "#EFF4FD",
  top: "0",
  bottom: "auto",
  height: "100%",
  width: "100%",
  position: "left",
};

export default RectangleBackground;
