import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import CardVertical from "components/templatesComponents/CardVertical";
import SiteContext from "components/SiteContext";
import PageService from "services/PageService";
import useAxiosCache from "hooks/axiosCache";
import news from "components/templates/news/news";
import CardHighlightedContext from "components/CardHighlightedContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  noResult: {
    marginTop: theme.spacing(3),
  },
}));

const LastNewsBlock = (props) => {
  const { highlightedNews, lang } = props;

  const classes = useStyles();

  const site = useContext(SiteContext);
  const { isHorizontal } = useContext(CardHighlightedContext);

  const { name: siteName } = site || {};

  const filters = useMemo(() => {
    const newFilters = [`page.lang||eq||${lang}`];
    if (highlightedNews.props?.cards?.props?.page?.id) {
      newFilters.push(`id||ne||${highlightedNews.props?.cards?.props?.page?.id}`);
    }
    return newFilters;
  }, [highlightedNews, lang]);

  const [{ data = [], loading }] = useAxiosCache(
    PageService.getConfig("findPagesByTemplateKeys", {
      templates: [news.key],
      siteName,
      filters,
      perPage: !isHorizontal && highlightedNews?.props?.cards ? 2 : 3,
    })
  );

  const newsPages = data?.pages || data;

  if (!data) {
    return null;
  }

  return (
    <Grid container spacing={2} style={{ padding: "16px 0" }}>
      {Array.isArray(newsPages) && newsPages.length > 0 ? (
        <>
          {(highlightedNews?.props?.cards || highlightedNews?.props?.children) && (
            <Grid item xs={12} md={isHorizontal ? 12 : 4}>
              {highlightedNews}
            </Grid>
          )}
          {newsPages.map((p) => (
            <Grid item xs={12} md={4} key={p.id}>
              <CardVertical page={p} />
            </Grid>
          ))}
        </>
      ) : (
        <Grid container justifyContent="center" className={classes.noResult}>
          {loading ? <CircularProgress /> : <h2>{t[lang].common.news.no_result}</h2>}
        </Grid>
      )}
    </Grid>
  );
};

LastNewsBlock.propTypes = {
  highlightedNews: PropTypes.shape().isRequired,
  lang: PropTypes.string.isRequired,
};

export default LastNewsBlock;
