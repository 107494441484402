import Who from "components/templates/who/Who";
import elementsTypes from "utils/elementsTypes";

export default {
  key: "WHO",
  label: "Qui sommes-nous",
  Component: Who,
  initialContents: [
    {
      key: "history",
      value: "",
      children: [
        {
          key: "tab",
          value: "",
          children: [
            {
              key: "title",
              value: "L'historique",
            },
            {
              key: "url",
              value: "historique",
            },
          ],
        },
        {
          key: "text",
          type: "TEXT",
          value: "",
        },
        {
          key: "timelineTitle",
          type: elementsTypes.EDITABLE_INPUT,
          value: "Les dates clés",
        },
        {
          key: "timeline",
          type: "TIMELINE",
          value: "",
          children: [],
        },
      ],
    },
    {
      key: "decrees",
      value: "",
      children: [
        {
          key: "tab",
          value: "",
          children: [
            {
              key: "title",
              value: "Les décrets",
            },
            {
              key: "url",
              value: "decrets",
            },
          ],
        },
        {
          key: "decrees",
          type: "ACCORDION",
          value: "",
          children: [],
        },
      ],
    },
    {
      key: "governance",
      value: "",
      children: [
        {
          key: "tab",
          value: "",
          children: [
            {
              key: "title",
              value: "La gouvernance",
            },
            {
              key: "url",
              value: "gouvernance",
            },
          ],
        },
        {
          key: "governersTitle",
          type: elementsTypes.EDITABLE_INPUT,
          value: "Direction de l'ANTS",
        },
        {
          key: "dynamicPart",
          type: "DYNAMIC",
          value: "",
          children: [
            {
              type: "TEXT",
              value: "",
              key: "dynamicElements",
              children: [],
            },
            {
              type: "GRID",
              children: [
                {
                  type: "ATTACHMENT",
                  children: [
                    {
                      type: "FILE",
                      key: "file",
                      children: [],
                      value: "",
                    },
                    {
                      type: elementsTypes.INPUT,
                      value: "Télécharger le fichier",
                      key: "linkText",
                      children: [],
                    },
                  ],
                  key: "items",
                  value: "",
                },
                {
                  type: "SELECT",
                  key: "size",
                  value: "4",
                  children: [],
                },
                {
                  type: "SELECT",
                  key: "sizeXs",
                  value: "12",
                  children: [],
                },
              ],
              key: "dynamicElements",
              value: "",
            },
          ],
        },
      ],
    },
  ],
};
