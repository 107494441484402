import HomeProcessSites from "components/templates/homeProcessSites/HomeProcessSites";
import elementsTypes from "utils/elementsTypes";

export default {
  key: "HOME_PROCESS_SITES",
  label: "Accueil démarches",
  Component: HomeProcessSites,
  initialContents: [
    {
      key: "contentTitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "",
    },
    {
      key: "subpages",
      type: elementsTypes.SUBPAGES_LIST,
      value: "",
      children: [],
    },
  ],
};
