import React, { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Icon from "components/templatesComponents/Icon";
import { makeStyles } from "@material-ui/styles";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  icon: (props) => ({
    color: `${props.iconColor}`,
    fontSize: "16px",
    marginRight: theme.spacing(1),
  }),
  uppercase: {
    "&:first-letter": { textTransform: "uppercase" },
  },
}));

const Tags = (props) => {
  const { tags, noIcon, iconColor, withDSFR, tagLink, selectedTags, handleSelectTags, ...others } = props;

  const classes = useStyles(props);

  const { language } = useContext(LanguageContext);

  const selected = React.useCallback(
    (s) => {
      return selectedTags.includes(s);
    },
    [selectedTags]
  );

  if (!Array.isArray(tags) || tags.length === 0) {
    return null;
  }

  if (withDSFR) {
    return (
      <ul className="fr-tags-group">
        {tags.map((tag) => (
          <li key={tag}>
            <Box
              component={tagLink ? "a" : "button"}
              type={tagLink ? undefined : "button"}
              className={classnames("fr-tag", classes.uppercase)}
              aria-pressed={selected(tag) ? "true" : "false"}
              onClick={() => handleSelectTags(tag)}
              style={
                tagLink
                  ? {
                      color: "var(--text-action-high-blue-france)",
                      backgroundColor: "var(--background-action-low-blue-france)",
                    }
                  : undefined
              }
            >
              {tag}
            </Box>
          </li>
        ))}
      </ul>
    );
  }

  return (
    tags.length > 0 && (
      <Grid container alignItems="center" {...others}>
        {!noIcon && (
          <Icon
            icon="tag"
            iconDSFR="bookmark-line"
            className={classes.icon}
            title={t[language].components.tag_icon_title}
          />
        )}
        {Array.isArray(tags) &&
          tags.map((tag) => (
            <Box key={tag} component="span" mr={1}>
              <span>#{tag}</span>
            </Box>
          ))}
      </Grid>
    )
  );
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  noIcon: PropTypes.bool,
  iconColor: PropTypes.string,
  withDSFR: PropTypes.bool,
  tagLink: PropTypes.bool,
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  handleSelectTags: PropTypes.func,
};

Tags.defaultProps = {
  tags: [],
  noIcon: false,
  iconColor: "inherit",
  withDSFR: false,
  tagLink: false,
  selectedTags: [],
  handleSelectTags: null,
};

export default Tags;
