import React, { useState, useContext, useEffect } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import EulerianService from "services/EulerianService";
import PageVersionContext from "components/PageVersionContext";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

export const componentClassName = "Da-ExpansionPanel";

const useStyles = makeStyles(() => ({ selectedItem: { position: "relative" } }));

const ExpansionPanel = (props) => {
  const { summary, details, className, tag, initialExpanded, children, onChange, noPadding, role, ...others } = props;
  const [isExpanded, setIsExpanded] = useState(initialExpanded);
  const { search } = useLocation();

  const classes = useStyles();

  const { currentPageVersion } = useContext(PageVersionContext);
  const { breadcrumb = [] } = currentPageVersion || {};

  useEffect(() => {
    if (initialExpanded || !tag) {
      setIsExpanded(initialExpanded);
    }
  }, [initialExpanded, tag]);

  const handleIsExpanded = (e) => {
    if (typeof onChange === "function") {
      onChange(e, !isExpanded);
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  useEffect(() => {
    if (isExpanded && breadcrumb) {
      const tabQuery = "?t=";
      const pageTag = {
        page_name: summary,
      };
      Object.keys(breadcrumb).forEach((key) => {
        if (key > 0) {
          pageTag[`page_category${Number(key) + 1}`] = breadcrumb[key].title;
        }
      });

      if (search && search.includes(tabQuery)) {
        pageTag[`page_category${Object.keys(breadcrumb).length + 1}`] = search.replace(tabQuery, "");
      }
      EulerianService.sendPage(pageTag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  return (
    <section className="fr-accordion">
      <h3 className="fr-accordion__title">
        <button
          type="button"
          className={classnames(classes.selectedItem, "fr-accordion__btn")}
          aria-expanded={isExpanded ? "true" : "false"}
          onClick={handleIsExpanded}
          {...others}
        >
          {summary}
        </button>
      </h3>
      <Box
        style={{
          backgroundColor: "#fff",
          padding: isExpanded && !noPadding ? "16px 16px 24px" : 0,
          display: isExpanded ? "block" : "none",
        }}
      >
        {children || details}
      </Box>
    </section>
  );
};

ExpansionPanel.propTypes = {
  summary: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  details: PropTypes.node,
  tag: PropTypes.bool,
  initialExpanded: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  noPadding: PropTypes.bool,
  role: PropTypes.string,
};

ExpansionPanel.defaultProps = {
  summary: "",
  details: null,
  className: "",
  tag: false,
  initialExpanded: false,
  onChange: null,
  children: null,
  noPadding: false,
  role: undefined,
};

export default ExpansionPanel;
