import React from "react";
import DefaultAxios from "axios";

class CustomCache {
  constructor() {
    this.reset();
  }

  reset() {
    this.items = {};
  }

  get(key) {
    return this.items[key];
  }

  set(key, value) {
    this.items[key] = value;
  }

  dump() {
    return Object.keys(this.items).map((k) => ({
      k,
      v: this.items[k],
    }));
  }

  load(data) {
    this.reset();
    data.forEach((hit) => {
      const { k, v } = hit;
      this.set(k, v);
    });
  }
}

const actions = {
  REQUEST_START: "REQUEST_START",
  REQUEST_END: "REQUEST_END",
};

const ssrPromises = [];

let cache = new CustomCache();
let axiosInstance = DefaultAxios;

export function configure(options) {
  if (options.axios) {
    axiosInstance = options.axios;
  }

  if (options.cache) {
    // eslint-disable-next-line prefer-destructuring
    cache = options.cache;
  }
}

export const resetCache = () => {
  cache.reset();
};

export function loadCache(data) {
  cache.load(data);
}

export async function serializeCache() {
  await Promise.all(ssrPromises);

  ssrPromises.length = 0;

  return cache.dump();
}

const getRequestKeyFromConfig = (config = {}) => {
  const { url, keyUrl, method = "get", params, data } = config;
  return JSON.stringify({ url: keyUrl || url, method, params, data });
};

async function cacheAdapter(_config) {
  const config = { ..._config };
  const cacheKey = getRequestKeyFromConfig(config);
  const hit = cache.get(cacheKey);
  if (hit) {
    return hit;
  }

  delete config.adapter;

  const response = await axiosInstance(config);

  const responseForCache = { ...response };
  delete responseForCache.config;
  delete responseForCache.request;

  cache.set(cacheKey, responseForCache);

  return response;
}

function createInitialState(options, config) {
  const cacheKey = getRequestKeyFromConfig(config);
  const hit = cache.get(cacheKey);
  const { data } = hit || {};
  return {
    loading: !data && !options.manual,
    data,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case actions.REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case actions.REQUEST_END:
      return {
        ...state,
        loading: false,
        ...(action.error ? {} : { data: action.payload.data }),
        [action.error ? "error" : "response"]: action.payload,
      };
    default:
      return state;
  }
}

async function request(config, dispatch) {
  try {
    dispatch({ type: actions.REQUEST_START });
    const response = await axiosInstance(config);
    dispatch({ type: actions.REQUEST_END, payload: response });
  } catch (err) {
    dispatch({ type: actions.REQUEST_END, payload: err, error: true });
  }
}

function executeRequestWithCache(config, dispatch) {
  request({ ...config, adapter: cacheAdapter }, dispatch);
}

function executeRequestWithoutCache(config, dispatch) {
  return request(config, dispatch);
}

export default function useAxios(_config, options = { manual: false }) {
  let config = { ..._config };
  if (typeof config === "string") {
    config = {
      url: config,
    };
  }

  const [state, dispatch] = React.useReducer(reducer, createInitialState(options, config));

  if (typeof window === "undefined") {
    ssrPromises.push(axiosInstance({ ...config, adapter: cacheAdapter }));
  }

  React.useEffect(() => {
    if (!options.manual) {
      executeRequestWithCache(config, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequestKeyFromConfig(config), options.manual]);

  return [
    state,
    (configOverride) => {
      return executeRequestWithoutCache({ ...config, ...configOverride }, dispatch);
    },
  ];
}
