import axios from "axios";
import generateCRUD from "components/adminServices/generateCRUD";
import { generateContentId, adminProperties, replacePagesByIds } from "utils/adminContentsUtils";
import { removeProperties } from "utils/commonUtils";
import elementsTypes from "utils/elementsTypes";

export const pageVersionImageKey = "versionImage";

const name = "page-versions";

const PageVersionsCRUD = generateCRUD(name);

const prepareVersion = (version, updatedIds = {}) => {
  const { contents = [], description, path, shortDescription, template, title, image, tags } = version;
  const cleanContents = contents.map((c) => removeProperties(c, adminProperties));
  const contentsWithPagesIds = cleanContents.map((content) => replacePagesByIds(content, updatedIds));
  const contentsWithoutVersionImage = contentsWithPagesIds.filter((content) => content.key !== pageVersionImageKey);
  if (image) {
    const pageVersionImage = contentsWithPagesIds.find((content) => content.key === pageVersionImageKey);
    let imageFile = {};
    if (pageVersionImage) {
      imageFile = JSON.parse(pageVersionImage.value);
    }
    contentsWithoutVersionImage.push(
      generateContentId({
        key: pageVersionImageKey,
        value: JSON.stringify({
          url: image.url,
          thumbs: image.thumbs,
          id: image.id || imageFile.id,
          name: image.name,
          mimeType: image.mimeType,
          size: image.size,
        }),
        type: elementsTypes.FILE_IMAGE,
      })
    );
  }

  return {
    contents: contentsWithoutVersionImage,
    description,
    path,
    shortDescription,
    template,
    title,
    image: image && {
      url: image.url,
      alt: image.alt,
      title: image.title,
    },
    tags,
  };
};

export default class AdminPageVersionsService extends PageVersionsCRUD {
  static getPageVersions = (pageId) => axios.get("/getPageVersions", { params: { pageId } }).then((r) => r.data);

  static create = (version) => {
    const { contents = [], page } = version;
    const updatedIds = {};
    const contentsWithIds = contents.map((c) => generateContentId(c, updatedIds));

    const cleanVersion = prepareVersion({ ...version, contents: contentsWithIds }, updatedIds);

    return PageVersionsCRUD.create({ ...cleanVersion, pageId: page.id }).then((v) => {
      return v;
    });
  };

  static update = (version) => {
    const { id, updated, versionName } = version;

    const cleanVersion = prepareVersion(version);

    return PageVersionsCRUD.update({
      ...cleanVersion,
      id,
      updated,
      versionName,
    });
  };

  static updateStatus = (pageVersion, status) =>
    axios.patch(`/api/${name}/${pageVersion.id}/status`, { updated: pageVersion.updated, status }).then((r) => r.data);

  static exportContentMedia = (allSites, mediaIds) =>
    axios.post("/exportContentMedia", { allSites, mediaIds }).then((r) => r.data);

  static readMediaFile = (file) => {
    return axios.post("/read-media-file", file).then((r) => r.data);
  };
}
