import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import PageTitle from "components/templatesComponents/PageTitle";
import CardContext from "components/templatesComponents/CardContext";
import RectangleBackground from "components/templatesComponents/RectangleBackground";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    zIndex: 1,
    position: "relative",
  },
  cardWrapper: {
    display: "flex",
    minHeight: "242px",
    [theme.breakpoints.up("lg")]: {
      minHeight: "312px",
    },
    ...(theme.templates && theme.templates.PageLink && theme.templates.PageLink.cardWrapper),
  },
  cardArrow: {
    backgroundColor: theme.palette.secondary.main,
    ...(theme.templates && theme.templates.PageLink && theme.templates.PageLink.cardArrow),
  },
}));

const PageLinks = (props) => {
  const { page } = props;
  const { title, shortDescription, contents } = page;
  const { pageLinks } = contents;

  const classes = useStyles();

  return (
    <Fragment>
      <Wrapper>
        <Block>
          <PageTitle>{title}</PageTitle>
          {shortDescription}
        </Block>
      </Wrapper>
      <div className={classes.wrapper}>
        <RectangleBackground top="106px" bottom="106px" position="left" width={0.875}>
          <Wrapper>
            <Block>
              <Grid container spacing={2}>
                {/* Si besoin d'afficher toutes les cards possibles */}
                {/* {Object.keys(styleKeys).map(key => <div style={{"margin": "8px"}}><Card {...cards[0]} title={key} desktopStyleKey={key} mobileStyleKey={key} icon /></div>)} */}
                <CardContext.Provider
                  value={{
                    Wrapper: Grid,
                    wrapperProps: {
                      item: true,
                      xs: 12,
                      md: 4,
                      className: classes.cardWrapper,
                    },
                  }}
                >
                  {pageLinks}
                </CardContext.Provider>
              </Grid>
            </Block>
          </Wrapper>
        </RectangleBackground>
      </div>
    </Fragment>
  );
};

PageLinks.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default PageLinks;
