import React, { useContext, useReducer } from "react";
import { Route, Switch } from "react-router-dom";
import { createGenerateClassName, StylesProvider } from "@material-ui/core/styles";
import LayoutFront from "components/LayoutFront/LayoutFront";
import { PageVersionContextProvider } from "components/PageVersionContext";
import { LanguageContextProvider } from "components/LanguageContext";
import PageSwitcher from "components/PageSwitcher";
import ResultsSearch from "components/ResultsSearch";
import MessageContext from "components/MessageContext";
import Snackbar from "components/Snackbar";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import SiteContext from "components/SiteContext";
import themes from "./themes/themes";

const generateClassName = createGenerateClassName({
  productionPrefix: "ants-",
});

const messageTypes = {
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  ERROR: "ERROR",
};

const snackbarReducer = (snackbarState, action) => {
  const { type, message } = action || {};
  switch (type) {
    case messageTypes.SUCCESS:
      return {
        open: true,
        variant: "success",
        message,
      };
    case messageTypes.WARNING:
      return {
        open: true,
        variant: "warning",
        message,
      };
    case messageTypes.ERROR:
      return {
        open: true,
        duration: null,
        variant: "error",
        message,
      };
    default:
      return {
        ...snackbarState,
        open: false,
      };
  }
};

const App = () => {
  const site = useContext(SiteContext);

  const [snackbar, dispatch] = useReducer(snackbarReducer, { open: false });

  const displaySuccess = (message) => {
    dispatch({ type: messageTypes.SUCCESS, message });
  };

  const displayWarning = (message) => {
    dispatch({ type: messageTypes.WARNING, message });
  };

  const displayError = (message) => {
    dispatch({ type: messageTypes.ERROR, message });
  };

  const handleCloseSnackbar = () => {
    dispatch();
  };

  const { settings: siteSettings } = site;
  let { socialNetworks } = siteSettings || {};

  if (socialNetworks && !Array.isArray(socialNetworks)) {
    socialNetworks = [socialNetworks];
  }

  return (
    <ThemeProvider theme={themes[site.name] || themes.ANTS}>
      <StylesProvider generateClassName={generateClassName}>
        <LanguageContextProvider>
          <PageVersionContextProvider>
            <MessageContext.Provider
              value={{
                displaySuccess,
                displayWarning,
                displayError,
              }}
            >
              <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
              <LayoutFront>
                <Switch>
                  <Route path="/rechercher" component={ResultsSearch} />
                  <Route path="/">
                    <PageSwitcher />
                  </Route>
                </Switch>
              </LayoutFront>
            </MessageContext.Provider>
          </PageVersionContextProvider>
        </LanguageContextProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
