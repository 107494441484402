import React, { useContext } from "react";
import PropTypes from "prop-types";
import MuiButton from "@material-ui/core/Button";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import Icon from "components/templatesComponents/Icon";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

export const componentClassName = "Da-Button";
const useStyles = makeStyles((theme) => ({
  button: (props) => ({
    backgroundColor: props.primary ? theme.palette.primary.main : theme.palette.secondary.main,
    color: props.primary
      ? theme.palette.getContrastText(theme.palette.primary.main)
      : theme.palette.getContrastText(theme.palette.secondary.main),
    "a&:hover, button&:hover": {
      backgroundColor: props.primary ? theme.palette.primary.dark : theme.palette.secondary.dark,
    },
  }),
  icon: (props) => ({
    paddingLeft: theme.spacing(props.seeAll ? 3 : 1),
    display: "flex",
    alignItems: "center",
    fontSize: "0.875rem",
  }),
  content: (props) => ({
    flex: "1 1 auto",
    padding: props.seeAll ? theme.spacing(2, 0) : undefined,
    display: "flex",
    justifyContent: props.seeAll ? "space-between" : "center",
    alignItems: "center",
    textAlign: "center",
    // Même transition que le bouton de base MUI sur cet élément ça va p-e changer si on enlève les transitions au hover
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
  }),
}));
const Button = (props) => {
  // Voir pour un meilleur nom que seeAll
  const {
    className,
    outlined,
    external,
    fullWidth,
    primary,
    seeAll,
    icon: constIcon,
    iconPosition,
    size,
    disabled,
    children,
    url,
    page,
    ...others
  } = props;
  let { icon } = props;
  if (typeof icon === "string") {
    icon = { icon };
  }

  const classes = useStyles({ primary, seeAll, outlined, icon });

  const { language } = useContext(LanguageContext);

  const displayFontIcon = !external && icon?.icon && !icon?.iconDSFR;

  const startIcon = displayFontIcon && iconPosition === "left" && (
    <Icon icon={icon?.icon} title={t[language].components.button_icon_title} className={classes.icon} />
  );

  const endIcon = displayFontIcon && iconPosition === "right" && (
    <Icon icon={icon?.icon} title={t[language].components.button_icon_title} className={classes.icon} />
  );

  const dynamicProps = {
    color: primary ? "primary" : "secondary",
    variant: outlined ? "outlined" : "contained",
    disabled,
    fullWidth,
    startIcon,
    endIcon,
    target: external ? "_blank" : "_self",
    rel: external ? "noopener noreferrer" : undefined,
  };

  return (
    <MuiButton
      className={classnames(
        `fr-btn fr-btn--${size}${outlined ? " fr-btn--secondary" : ""}${
          iconPosition ? ` fr-btn--icon-${iconPosition}` : ""
        }${!external && icon?.iconDSFR ? ` fr-icon-${icon?.iconDSFR}` : ""}`,
        outlined ? "" : classes.button,
        className,
        componentClassName
      )}
      url={url}
      page={page}
      external={external}
      aria-hidden={disabled}
      aria-label={
        typeof children === "string" && children !== ""
          ? children
          : `${t[language].components.link_to} ${url || page?.title}`
      }
      {...dynamicProps}
      {...others}
    >
      {children && (
        <span className={classes.content}>
          {children}
          {seeAll && (
            <Icon
              icon="long-arrow-right"
              iconDSFR="arrow-right-line"
              title={t[language].components.button_icon_title}
              className={classes.icon}
            />
          )}
        </span>
      )}
    </MuiButton>
  );
};
Button.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  external: PropTypes.bool,
  outlined: PropTypes.bool,
  fullWidth: PropTypes.bool,
  primary: PropTypes.bool,
  seeAll: PropTypes.bool,
  iconPosition: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  url: PropTypes.string,
  page: PropTypes.node,
};
Button.defaultProps = {
  className: "",
  external: false,
  outlined: false,
  fullWidth: false,
  primary: false,
  children: null,
  icon: null,
  seeAll: false,
  iconPosition: "",
  size: "md",
  disabled: false,
  url: "",
  page: null,
};
export default Button;
