import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import Helmet from "react-helmet";
import Wrapper from "components/LayoutFront/Wrapper";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import PageService from "services/PageService";
import useAxiosCache from "hooks/axiosCache";
import home from "components/templates/home/home";
import homeProcessSites from "components/templates/homeProcessSites/homeProcessSites";
import homeMairie from "components/templates/homeMairie/homeMairie";
import contact from "components/templates/contact/contact";
import help from "components/templates/help/help";
import helpMairie from "components/templates/help/helpMairie";
import missions from "components/templates/missions/missions";
import processes from "components/templates/processes/processes";
import { ImageContainer } from "components/templatesComponents/Image";
import Link from "components/templatesComponents/Link";
import Block from "components/templatesComponents/Block";
import PageTitle from "components/templatesComponents/PageTitle";
import LanguageContext from "components/LanguageContext";
import SiteContext from "components/SiteContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  wrapperProps: {
    position: "relative",
  },
  imageContainer: {
    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      bottom: 0,
      right: "5%",
      width: "40%",
      zIndex: -1,
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  listLink: {
    padding: 0,
    listStyle: "inside",
    marginTop: theme.spacing(2),
    fontWeight: 600,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      "& li:before ": {
        content: "none !important",
      },
      "& li:after": {
        content: `"\\f105"`,
        fontWeight: 400,
        fontSize: "16px",
        verticalAlign: "middle",
        textIndent: "0",
        marginLeft: theme.spacing(1),
        display: "inline-block",
        fontFamily: `"Font Awesome 5 Pro"`,
      },
    },
    "& li:not(last-child)": {
      marginBottom: theme.spacing(2.5),
    },
    "& a": {
      color: theme.palette.primary.main,
      backgroundImage: "var(--underline-img), var(--underline-img) !important",
    },
  },
}));

const NotFound = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));
  const { language } = useContext(LanguageContext);
  const site = useContext(SiteContext);
  const { name: siteName } = site || {};

  const [{ data }] = useAxiosCache(
    PageService.getConfig("findPagesByTemplateKeys", {
      templates: [
        home.key,
        homeProcessSites.key,
        homeMairie.key,
        contact.key,
        help.key,
        helpMairie.key,
        missions.key,
        processes.key,
      ],
      filters: [`page.lang||eq||${language}`],
    })
  );

  const { pages = [] } = data || {};

  const homePage =
    pages && pages.find((version) => [home.key, homeProcessSites.key, homeMairie.key].includes(version.template));
  const contactPage =
    pages && pages.find((version) => [contact.key, help.key, helpMairie.key].includes(version.template));
  const missionPage = pages && pages.find((version) => version.template === missions.key);
  const processesPage = pages && pages.find((version) => version.template === processes.key);

  return (
    <Wrapper wrapperProps={{ className: classes.wrapperProps }}>
      <Helmet>
        <title>
          {t[language].not_found.balise_title}
          {siteName === "ANTS" ? "" : " - "}
          {t[language].site_name[siteName]} - France Titres (ANTS)
        </title>
      </Helmet>
      <Grid container justifyContent="space-between">
        <Grid item lg={6} container direction="column">
          <Grid item>
            <Block>
              <PageTitle>{t[language].not_found.balise_title}</PageTitle>
            </Block>
          </Grid>
          <Grid item>
            <Block>
              <h2>{t[language].not_found.subtitle}</h2>
              <ul className={classes.listLink}>
                {homePage && (
                  <li>
                    <Link page={homePage}>{t[language].not_found.home_message}</Link>
                  </li>
                )}
                {missionPage && (
                  <li>
                    <Link page={missionPage}>{t[language].not_found.missions_message}</Link>
                  </li>
                )}
                {processesPage && (
                  <li>
                    <Link page={processesPage}>{t[language].not_found.processes_message}</Link>
                  </li>
                )}
                {contactPage && (
                  <li>
                    <Link page={contactPage}>{t[language].not_found.contact_message}</Link>
                  </li>
                )}
              </ul>
            </Block>
          </Grid>
        </Grid>
      </Grid>

      <div className={classes.imageContainer}>
        <ImageContainer ratio={!isMobile ? 60 : 42}>
          <img src="/NotFound.jpg" className={classes.image} alt={t[language].not_found.img_alt} />
        </ImageContainer>
      </div>
    </Wrapper>
  );
};

export default NotFound;
