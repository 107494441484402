import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  skip: {
    left: "-999px",
    position: "absolute",
    top: "auto",
    overflow: "hidden",
    zIndex: "-999",
    "&:focus": {
      backgroundColor: theme.palette.primary[10],
      border: `1px solid ${theme.palette.primary.light}`,
      color: theme.palette.primary.main,
      left: 4,
      top: 4,
      overflow: "auto",
      fontWeight: 600,
      padding: theme.spacing(3),
      textAlign: "center",
      zIndex: "9999",
    },
  },
}));

const SkipTo = (props) => {
  const classes = useStyles();
  const { link, children } = props;

  return (
    <a className={classes.skip} href={link} tabIndex="1">
      {children}
    </a>
  );
};

SkipTo.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.node,
};

SkipTo.defaultProps = {
  children: null,
};

export default SkipTo;
