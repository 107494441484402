import React from "react";
import PropTypes from "prop-types";
import Text from "components/templatesComponents/Text";
import Wrapper from "components/LayoutFront/Wrapper";
import { makeStyles } from "@material-ui/styles";
import Hidden from "components/templatesComponents/Hidden";

const useStyles = makeStyles((theme) => ({
  container: {
    "@media screen ": {
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
    "& > div": {
      flex: "1 1 0px",
    },
  },
  description: {
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.primary[10],
    },
  },
  text: {
    padding: 0,
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(4, 10, 4, 0),
    },
  },
  timelineTitle: {
    margin: theme.spacing(4, 0),
  },
  timeline: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: "-10px",
    },
  },
}));

const History = (props) => {
  const { text, timelineTitle, timeline } = props;
  const classes = useStyles();

  return (
    <Wrapper className={classes.container} leftProps={{ className: classes.description }}>
      <div className={classes.description}>
        <Text className={classes.text} tabIndex={-1}>
          {text}
        </Text>
      </div>

      <div className={classes.dates}>
        <Hidden lgUp>
          <h2 className={classes.timelineTitle}>{timelineTitle}</h2>
        </Hidden>
        <div className={classes.timeline}>{timeline}</div>
      </div>
    </Wrapper>
  );
};

History.propTypes = {
  text: PropTypes.node,
  timelineTitle: PropTypes.node,
  timeline: PropTypes.node,
};

History.defaultProps = {
  text: "",
  timelineTitle: "",
  timeline: null,
};

export default History;
