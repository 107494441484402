import axios from "axios";

class ContactService {
  static getWebmail = (contentId, pageId, captchaToken) => {
    return axios.post("/@webmail", { contentId, pageId, captchaToken }).then((response) => response.data);
  };

  static postMail = (sendForm) => {
    return axios
      .post("/mail", sendForm)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        const error = new Error(err.response.data);
        error.status = err.response && err.response.status;
        throw error;
      });
  };
}

export default ContactService;
