import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  block: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2, 0),
    },
  },
}));

const Block = (props) => {
  const { className, ...others } = props;
  const classes = useStyles();
  return <Box className={classnames(classes.block, className)} {...others} />;
};

Block.propTypes = {
  className: PropTypes.string,
};

Block.defaultProps = {
  className: "",
};

export default Block;
