import React, { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import merge from "lodash.merge";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Link from "components/templatesComponents/Link";
import MuiLink from "@material-ui/core/Link";
import cardStyles, { styleKeys } from "components/templatesComponents/card/cardStyles";
import CardContext from "components/templatesComponents/CardContext";
import { ImageContainer } from "components/templatesComponents/Image";
import Icon from "components/templatesComponents/Icon";
import Text from "components/templatesComponents/Text";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const useStyles = (props) =>
  makeStyles((theme) => ({
    image: {
      display: "none",
    },
    root: {
      height: "100%",
      "&:hover": {
        color: "initial",
      },
    },
    arrow: {
      fontSize: 24,
      [theme.breakpoints.up("lg")]: {
        fontSize: 32,
      },
    },
    title: {
      whiteSpace: "normal",
      wordBreak: "break-word",
      marginBottom: "8px",
    },
    [theme.breakpoints.down("md")]: {
      ...merge(props.mobileStyle.style(theme, props), props.styleOverride, props.mobileStyleOverride),
    },
    [theme.breakpoints.up("lg")]: {
      ...merge(props.desktopStyle.style(theme, props), props.styleOverride, props.desktopStyleOverride),
    },
  }))();

const Card = (props) => {
  const { link, page, icon, index, subtitle } = props;

  let { title, description, linkLabel } = props;

  title = (page && page.title) || title;
  description = (page && page.shortDescription) || description;

  const {
    Wrapper = Fragment,
    wrapperProps = {},
    mobileStyleKey,
    desktopStyleKey,
    styleOverride,
    desktopStyleOverride,
    mobileStyleOverride,
    camaieuStart,
  } = useContext(CardContext);

  const { language } = useContext(LanguageContext);

  if (!linkLabel) {
    linkLabel = t[language].components.default_linkLabel_card;
  }

  const image = page && page.image;

  if (page) {
    link.page = page;
  }

  const { id: linkId, ...linkProps } = link;

  const mobileStyle = cardStyles[mobileStyleKey] || cardStyles[styleKeys.LITTLE_LIGHT];
  const desktopStyle = cardStyles[desktopStyleKey] || cardStyles[styleKeys.LIGHT_BIG];
  const classes = useStyles({
    mobileStyle,
    desktopStyle,
    styleOverride,
    mobileStyleOverride,
    desktopStyleOverride,
    index,
    camaieuStart,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const displayImage = !!image && ((isMobile && mobileStyle.imageRatio) || (!isMobile && desktopStyle.imageRatio));

  return (
    <Wrapper {...wrapperProps}>
      <Link {...linkProps} title={linkProps.title || title || null} className={classes.root} tabIndex={0}>
        {displayImage && (
          <div className={classes.image}>
            <ImageContainer ratio={isMobile ? mobileStyle.imageRatio : desktopStyle.imageRatio}>{image}</ImageContainer>
          </div>
        )}
        <div className={classes.content}>
          {icon && <Icon {...icon} className={classes.icon} title={`icone-${title}`} />}
          {title && (
            <h3 className={classnames(classes.title, isMobile ? mobileStyle.titleVariant : desktopStyle.titleVariant)}>
              {title}
            </h3>
          )}
          {subtitle && (
            <p className="fr-text--md" style={{ marginBottom: "0.75rem" }} role="presentation">
              {subtitle}
            </p>
          )}
          {description && (
            <Text className={classes.description} tabIndex={-1}>
              {description}
            </Text>
          )}
        </div>

        <div className={classes.arrowContainer}>
          <MuiLink component="span" className={classes.linkLabel}>
            {linkLabel}
          </MuiLink>
          <Icon icon="long-arrow-right" iconDSFR="arrow-right-line" className={classes.arrow} title={title} />
        </div>
      </Link>
    </Wrapper>
  );
};

Card.propTypes = {
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  link: PropTypes.shape(),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  linkLabel: PropTypes.string,
  index: PropTypes.number.isRequired,
};

Card.defaultProps = {
  page: null,
  link: {},
  icon: null,
  title: null,
  subtitle: null,
  description: null,
  linkLabel: "",
};

export default Card;
