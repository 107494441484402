/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import AdminSitesService from "components/adminServices/AdminSitesService";
import AdminPagesService from "components/adminServices/AdminPagesService";
import AdminPageVersionsService from "components/adminServices/AdminPageVersionsService";
import { createQueryParams, getQueryParams } from "utils/urlUtils";
import LanguageContext from "components/LanguageContext";

const AdminContext = React.createContext({
  currentSite: null,
  setCurrentSite: () => null,
  allSites: [],
  currentPage: null,
  setCurrentPage: () => null,
  currentPageVersion: null,
  setCurrentPageVersion: () => null,
  pageVersions: [],
  refreshPageVersions: () => null,
});

export default AdminContext;

export const queryParamSite = "site";
export const queryParamPage = "page";
export const queryParamVersion = "version";

export const AdminContextProvider = withRouter((props) => {
  const { location, history, children } = props;

  const { pathname } = location;

  const { language } = useContext(LanguageContext);

  const queryParams = getQueryParams();

  const siteId = queryParams[queryParamSite];

  const [allSites, setAllSites] = useState([]);
  const [currentSite, setCurrentSite] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [pageVersions, setPageVersions] = useState([]);
  const [currentPageVersion, setCurrentPageVersion] = useState(null);

  const refreshSite = () => {
    if (currentSite) {
      return AdminSitesService.get(currentSite.id).then((site) => {
        setCurrentSite(site);
        return site;
      });
    }
    return new Promise((resolve) => resolve({}));
  };

  const refreshPageVersions = () => {
    if (currentSite && currentPage) {
      return AdminPageVersionsService.getPageVersions(currentPage.id).then((versions) => {
        setPageVersions(versions);
        return versions;
      });
    }
    return new Promise((resolve) => resolve([]));
  };

  const handleChangeSite = (site) => {
    const { id } = site;
    history.push(
      `${pathname}?${createQueryParams({
        [queryParamSite]: id,
      })}`
    );
  };

  const handleChangePage = (page) => {
    const { id } = page;
    history.push(
      `${pathname}?${createQueryParams({
        [queryParamSite]: queryParams[queryParamSite],
        [queryParamPage]: id,
      })}`
    );
  };

  const handleChangePageVersion = (pageVersion = {}) => {
    const { id } = pageVersion;
    if (currentPageVersion && currentPageVersion.id !== id) {
      history.push(
        `${pathname}?${createQueryParams({
          ...queryParams,
          [queryParamVersion]: id,
        })}`
      );
    } else {
      setCurrentPageVersion(pageVersion);
    }
  };

  const goToHomePage = () => {
    if (currentSite) {
      AdminPageVersionsService.find({
        params: {
          join: ["page"],
          filter: [`page.siteId||eq||${currentSite.id}`, `page.parentId||isnull`],
        },
      }).then((versions) => {
        const currentLanguageVersions = versions.filter((v) => v.page.lang === language);
        const [version] = currentLanguageVersions.length ? currentLanguageVersions : versions;
        if (version) {
          handleChangePage(version.page);
        }
      });
    }
  };

  // Load Sites
  useEffect(() => {
    AdminSitesService.find({
      params: {
        sort: "name,ASC",
      },
    }).then(setAllSites);
  }, []);

  // Set current Site
  useEffect(() => {
    if (allSites.length) {
      let site = allSites.find((s) => s.id === siteId);
      if (site) {
        setCurrentSite(site);
      } else {
        site = allSites.find((s) => s.name === "ANTS") || allSites[0];
        handleChangeSite(site);
      }
    }
  }, [allSites, siteId]);

  // Get current Page from query param
  // Redirect to home if not found or no param
  useEffect(() => {
    if (!queryParams[queryParamPage]) {
      goToHomePage();
    }
  }, [currentSite]);

  useEffect(() => {
    if (queryParams[queryParamPage]) {
      AdminPagesService.get(queryParams[queryParamPage])
        .then(setCurrentPage)
        .catch(() => goToHomePage());
    }
  }, [queryParams[queryParamPage]]);

  // Refresh versions when current page change
  useEffect(() => {
    refreshPageVersions();
  }, [currentSite, currentPage && currentPage.id]);

  // Set current version
  useEffect(() => {
    if (pageVersions.length) {
      const version = pageVersions.find((v) => v.id === queryParams[queryParamVersion]);
      if (version && (!currentPageVersion || version.id !== currentPageVersion.id)) {
        setCurrentPageVersion(version);
      } else if (!version) {
        handleChangePageVersion(pageVersions[0]);
      }
    }
  }, [pageVersions, queryParams[queryParamVersion]]);

  if (!currentSite) {
    return "loading site";
  }

  return (
    <AdminContext.Provider
      value={{
        currentSite,
        setCurrentSite: handleChangeSite,
        refreshSite,
        allSites,
        currentPage,
        setCurrentPage: handleChangePage,
        currentPageVersion,
        setCurrentPageVersion: handleChangePageVersion,
        pageVersions,
        refreshPageVersions,
        goToHomePage,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
});
