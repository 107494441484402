import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import MuiLink from "@material-ui/core/Link";
import SectionService from "services/SectionService";
import useAxiosCache from "hooks/axiosCache";
import Link from "components/templatesComponents/Link";
import Icon from "components/templatesComponents/Icon";
import generateTemplatePropsFromContents from "utils/templatePropsUtils";
import CookiesModalContext from "components/CookiesModalContext";
import LanguageContext from "components/LanguageContext";
import SiteContext from "components/SiteContext";
import { getEnglishItems } from "utils/urlUtils";
import { eventKey } from "utils/commonUtils";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  externalIcon: {
    paddingLeft: "6px",
    "&::before": {
      "--icon-size": "0.875rem",
    },
  },
  externalLink: {
    paddingLeft: "4px",
  },
  partners: {
    fontSize: "14px",
    display: "flex",
    flexDirection: "column",
    paddingTop: "15px",
    paddingBottom: "16px",
    alignItems: "flex-start",
    marginBottom: "-40px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      width: "100%",
    },
  },
  partnersLogos: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
    alignSelf: "stretch",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    "& a": {
      backgroundImage: "none",
    },
  },
}));

const Footer = (props) => {
  const { allPnuPages, homePage } = props;
  const { handleCookiesModal } = useContext(CookiesModalContext);
  const { language } = useContext(LanguageContext);

  const site = useContext(SiteContext);
  const [{ data: footer }] = useAxiosCache(SectionService.getConfig("getFooter", site.id));
  const { path: homePagePath, fullPath: homePageFullPath } = homePage;

  const footerContents = (footer && generateTemplatePropsFromContents(footer.contents)) || {};
  let { LinkExternal = [], LinkInternal1 = [], LinkInternal2 = [] } = footerContents;
  if (!Array.isArray(LinkExternal)) {
    LinkExternal = [LinkExternal];
  }
  if (!Array.isArray(LinkInternal1)) {
    LinkInternal1 = [LinkInternal1];
  }
  if (!Array.isArray(LinkInternal2)) {
    LinkInternal2 = [LinkInternal2];
  }

  const LinkInternal1WithPNU = React.useMemo(() => {
    if (language.toUpperCase() === "EN") {
      return getEnglishItems({ items: LinkInternal1, allPnuPages }) || [];
    }
    return LinkInternal1 || [];
  }, [language, LinkInternal1, allPnuPages]);

  const LinkInternal2WithPNU = React.useMemo(() => {
    if (language.toUpperCase() === "EN") {
      return getEnglishItems({ items: LinkInternal2, allPnuPages }) || [];
    }
    return LinkInternal2 || [];
  }, [language, LinkInternal2, allPnuPages]);

  const { name: siteName, settings: siteSettings } = site || {};
  let { socialNetworks = [] } = siteSettings || {};

  if (socialNetworks && !Array.isArray(socialNetworks)) {
    socialNetworks = [socialNetworks];
  }

  const classes = useStyles({ LinkExternal });

  const ExternalIcon = () => (
    <Icon
      icon="external-link"
      iconDSFR="external-link-line"
      title={t[language].footer.new_tab_title}
      className={classes.externalIcon}
      isHidden={false}
      aria-label={t[language].footer.new_tab_title}
    />
  );

  const LinkList1 = () =>
    (LinkInternal1WithPNU &&
      LinkInternal1WithPNU.map((list1item) => (
        <li className="fr-footer__bottom-item" key={list1item.id}>
          <Link className="fr-footer__bottom-link" {...list1item.link}>
            {list1item.title}
            {list1item && list1item.link && list1item.link.external && <ExternalIcon />}
          </Link>
        </li>
      ))) ||
    null;

  const LinkList2 = () =>
    (LinkInternal2WithPNU &&
      LinkInternal2WithPNU.map((list2item) => (
        <li className="fr-footer__bottom-item" key={list2item.id}>
          <Link className="fr-footer__bottom-link" {...list2item.link}>
            {list2item.title}
            {list2item && list2item.link && list2item.link.external && <ExternalIcon />}
          </Link>
        </li>
      ))) ||
    null;

  const handleKeyDown = (event) => {
    if (event.key === eventKey) {
      handleCookiesModal();
    }
  };

  return (
    <footer className="fr-footer" role="contentinfo" id="footer" style={{ backgroundColor: "white" }}>
      <div className="fr-container">
        <div className="fr-footer__body">
          <div className="fr-footer__brand fr-enlarge-link" src="/logo-republique-francaise.svg">
            <Link
              url={homePageFullPath || homePagePath}
              title={t[language].footer.back_to_home}
              style={{ marginRight: "24px" }}
            >
              <p className="fr-logo">
                république
                <br />
                française
              </p>
            </Link>
            <img src="/logo_France_Titres_clair.svg" alt="" />
          </div>
          <div className="fr-footer__content">
            <ul>
              {LinkExternal.map((list1item) => (
                <li>
                  <Link {...list1item.link} key={list1item.id} className="fr-footer__content-desc">
                    {list1item.title}
                    {list1item && list1item.link && list1item.link.external && <ExternalIcon />}
                  </Link>
                </li>
              ))}
            </ul>
            <ul className="fr-footer__content-list">
              <li className="fr-footer__content-item">
                <Link external linkComponent className="fr-footer__content-link" url="https://legifrance.gouv.fr">
                  info.gouv.fr
                </Link>
              </li>
              <li className="fr-footer__content-item">
                <Link external linkComponent className="fr-footer__content-link" url="https://service-public.fr">
                  service-public.fr
                </Link>
              </li>
              <li className="fr-footer__content-item">
                <Link external linkComponent className="fr-footer__content-link" url="https://gouvernement.fr">
                  legifrance.gouv.fr
                </Link>
              </li>
              <li className="fr-footer__content-item">
                <Link external linkComponent className="fr-footer__content-link" url="https://data.gouv.fr">
                  data.gouv.fr
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="fr-footer__bottom">
          <div className={classes.partners}>
            <h3 className="fr-text--sm fr-text--regular fr-m-0">{t[language].footer.partners} :</h3>
            <div className={classes.partnersLogos}>
              {["ANTS", "IMMAT"].includes(siteName) && (
                <Link url="https://europa.eu/youreurope/index.htm#en" title="Your Europe">
                  <img src="/logo-yourEurope.svg" alt="Logo your Europe" />
                </Link>
              )}
              <Link url="https://www.plus.transformation.gouv.fr/" title="Services Publics +">
                <img src="/logo-servicesPublicsPlus.svg" alt="Logo Services Publics +" />
              </Link>
            </div>
          </div>
        </div>
        <div className="fr-footer__bottom">
          <ul className="fr-footer__bottom-list">
            <LinkList1 />
            <li className="fr-footer__bottom-item">
              <MuiLink
                className="fr-footer__bottom-link"
                color="inherit"
                onClick={() => handleCookiesModal()}
                onKeyDown={(e) => handleKeyDown(e)}
                tabIndex={0}
              >
                {t[language].footer.cookies_link}
              </MuiLink>
            </li>
            <LinkList2 />
          </ul>
          <div className="fr-footer__bottom-copy">
            <p>
              {t[language].footer.etalab}{" "}
              <Link
                url="https://www.etalab.gouv.fr/licence-ouverte-open-licence"
                external
                linkComponent
                color="inherit"
              >
                licence etalab-2.0
              </Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  allPnuPages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  homePage: PropTypes.shape(),
};

Footer.defaultProps = {
  homePage: {},
};

export default Footer;
