import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ImageContainer } from "components/templatesComponents/Image";
import Link from "components/templatesComponents/Link";
import Tags from "components/templatesComponents/Tags";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import ClampLines from "react-clamp-lines";
import Hidden from "components/templatesComponents/Hidden";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",

    "&:hover": {
      color: "initial",
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3, 0),
      borderBottom: `1px solid ${theme.palette.componentColors[30]}`,
      "&:last-child": {
        border: "none",
        paddingBottom: 0,
      },
      "&:first-child": {
        paddingTop: 0,
      },
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
      "&:hover $tags": {
        backgroundColor: theme.palette.secondary[30],
      },
    },
  },
  image: {
    minWidth: "64px",
    [theme.breakpoints.up("lg")]: {
      position: "relative",
      width: "344px",
      marginBottom: theme.spacing(2),
    },
  },
  tags: {
    fontSize: "0.75rem",
    opacity: "0.9",
    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      bottom: 0,
      left: 0,
      maxWidth: "60%",
      backgroundColor: theme.palette.secondary[20],
      padding: theme.spacing(2, 3),
    },
  },
  title: {
    paddingBottom: theme.spacing(1),
    fontWeight: 600,
    flex: 1,
    overflow: "hidden",
    whiteSpace: "normal",
    color: "#353535",
    fontSize: "1.1rem",
    fontFamily: ['"Open Sans"', "sans-serif"].join(","),
    lineHeight: "1.6",
    [theme.breakpoints.up("lg")]: {},
  },
  content: {
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexDirection: "column",
      height: 82,
    },
  },
  span: {
    display: "flex",
    color: theme.palette.primary[40],
    fontWeight: 600,
  },
}));

const KnowEverythingNews = (props) => {
  const { page } = props;
  const { title, tags, image, id } = page;

  const { language } = useContext(LanguageContext);

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Link page={page} className={classes.root} aria-label={title}>
      {image && (
        <div className={classes.image} aria-hidden>
          <ImageContainer ratio={isMobile ? 100 : 98}>{image}</ImageContainer>
          <Hidden mdDown>
            {tags && tags.length > 0 && (
              <div className={classes.tags}>
                <Tags tags={page.tags} noIcon />{" "}
              </div>
            )}
          </Hidden>
        </div>
      )}
      <div className={classes.content}>
        {title && (
          <ClampLines id={id} text={title} lines={isMobile ? 3 : 2} buttons={false} className={classes.title} />
        )}
        <Hidden lgUp>
          {tags && tags.length > 0 && (
            <div aria-hidden>
              <Tags tags={page.tags} noIcon />
            </div>
          )}
        </Hidden>
        <Hidden implementation="js" initialWidth="lg" mdDown>
          <span className={classes.span} aria-hidden>
            {t[language].common.see_more} <ChevronRightIcon role="img" />
          </span>
        </Hidden>
      </div>
    </Link>
  );
};

KnowEverythingNews.propTypes = {
  page: PropTypes.shape(),
};

KnowEverythingNews.defaultProps = {
  page: {},
};

export default KnowEverythingNews;
