import React, { useState, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import PageTitle from "components/templatesComponents/PageTitle";
import { ImageContainer } from "components/templatesComponents/Image";

const useStyles = makeStyles((theme) => ({
  rightPart: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
      position: "absolute",
      width: "40%",
      top: 0,
      right: 0,
    },
  },
  image: {
    padding: theme.spacing(0, 0, 2, 2),
  },
}));

const FloatingImage = (props) => {
  const { page } = props;
  const { title, contents, image } = page;
  const { dynamicPart } = contents;

  const classes = useStyles();

  const [rightPartPlaceHolderStyle, setRightPartPlaceHolderStyle] = useState(null);

  const marginRight = typeof window !== "undefined" && (992 - window.innerWidth) / 2;

  const rightPartRef = useRef();

  const handleResize = useCallback(() => {
    if (rightPartRef.current !== null) {
      setTimeout(() => {
        setRightPartPlaceHolderStyle({
          height: rightPartRef.current.offsetHeight || 400,
          width: rightPartRef.current.offsetWidth,
        });
      }, 0);
    }
  }, [rightPartRef]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return (
    <div style={{ position: "relative" }}>
      <div
        className={classes.rightPart}
        style={{ position: "absolute", width: "50%", top: 0, right: 0 }}
        ref={rightPartRef}
      >
        {image && (
          <div className={classes.image}>
            <ImageContainer ratio={50}>{image}</ImageContainer>
          </div>
        )}
      </div>
      <Wrapper>
        {rightPartPlaceHolderStyle && (
          <div
            style={{
              float: "right",
              marginRight,
              ...rightPartPlaceHolderStyle,
            }}
          />
        )}
        <Block>
          <PageTitle>{title}</PageTitle>
        </Block>
        {dynamicPart}
      </Wrapper>
    </div>
  );
};

FloatingImage.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default FloatingImage;
