import React, { useState } from "react";
import { withRouter } from "react-router-dom";

const CookiesModalContext = React.createContext({
  openCookiesModal: false,
  setOpenCookiesModal: () => null,
});
export default CookiesModalContext;

export const CookiesModalContextProvider = withRouter((props) => {
  const { children } = props;

  const [openCookiesModal, setOpenCookiesModal] = useState(false);

  const handleCookiesModal = () => {
    setOpenCookiesModal(!openCookiesModal);
  };

  return (
    <CookiesModalContext.Provider
      value={{
        openCookiesModal,
        handleCookiesModal,
      }}
    >
      {children}
    </CookiesModalContext.Provider>
  );
});
