import { stringToSnakeCaseString } from "utils/commonUtils";

const getSiteName = (s) => {
  switch (s) {
    case "ANTS":
      return "chapeau";
    case "PASSEPORT_CNI":
      return "Carte d'identité et Passeport";
    case "IMMAT":
      return "Immatriculation";
    case "PERMIS":
      return "Permis de conduire";
    case "PHA":
      return "Professionnel de l'automobile";
    case "AUTO_ECOLE":
      return "Auto-Ecole et CSSR";
    case "MAIRIE":
      return "Mairie";
    default:
      return "";
  }
};

const CATEGORIE_MESURE_AUDIENCE = "Mesure audience";
const CATEGORIE_STRICTEMENT_NECESSAIRE = "Cookies strictement nécessaires";

class EulerianService {
  static sendPage = (info, siteName) => {
    if (typeof window?.EA_push === "function" && !!process.env.eulerian_tag) {
      const pageTag = { ...info, page_category1: getSiteName(siteName) };
      window.EA_datalayer = [];
      Object.keys(pageTag).reduce((newObj, key) => {
        const infoTag = { ...newObj };
        infoTag[key] = typeof pageTag[key] === "string" ? stringToSnakeCaseString(pageTag[key]) : pageTag[key];
        return window.EA_datalayer.push(key, infoTag[key]);
      }, {});
      return window.EA_push(window.EA_datalayer);
    }
    return null;
  };

  static sendClick = (info) => {
    if (typeof window?.EA_push === "function" && !!process.env.eulerian_tag) {
      Object.keys(info).reduce((newObj, key) => {
        const infoTag = { ...newObj };
        infoTag[key] = typeof info[key] === "string" ? stringToSnakeCaseString(info[key]) : info[key];
        return window.EA_push(key, infoTag[key]);
      }, {});
    }
    return null;
  };

  static acceptCategorie = (name) => {
    if (typeof window?.EA_push === "function" && !!process.env.eulerian_tag) {
      window.EA_push("ondone", function () {
        const categoriesList = window.EA_epmGet();
        const categorie = categoriesList.find((element) => element.name === name);

        if (categorie) {
          categorie.allowed = true;
          categorie.denied = false;
          window.EA_epmSet(categorie);
        }
      });
    }
  };

  static denyCategorie = (name) => {
    if (typeof window?.EA_push === "function" && !!process.env.eulerian_tag) {
      window.EA_push("ondone", function () {
        const categoriesList = window.EA_epmGet();
        const categorie = categoriesList.find((element) => element.name === name);

        if (categorie) {
          categorie.allowed = false;
          categorie.denied = true;
          window.EA_epmSet(categorie);
        }
      });
    }
  };

  static sendConsent = () => {
    if (typeof window?.EA_push === "function" && !!process.env.eulerian_tag) {
      window.EA_push("ondone", function () {
        window.EA_epmEnd();
      });
    }
  };

  static acceptAll = () => {
    if (typeof window?.EA_push === "function" && !!process.env.eulerian_tag) {
      window.EA_push("ondone", function () {
        window.EA_epmSetAllowAll();
        window.EA_epmEnd();
      });
    }
  };

  static denyAll = () => {
    //  on doit au moins laisser les "cookies nécessaires" d'activer
    EulerianService.acceptCategorie(CATEGORIE_STRICTEMENT_NECESSAIRE);
    EulerianService.denyCategorie(CATEGORIE_MESURE_AUDIENCE);
    EulerianService.sendConsent();
  };
}
export default EulerianService;
