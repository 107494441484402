import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  separator: {
    height: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      height: theme.spacing(8),
    },
  },
}));

const Separator = () => {
  const classes = useStyles();
  return <div className={classes.separator} />;
};

export default Separator;
