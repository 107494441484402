import Article from "components/templates/article/Article";

export default {
  key: "ARTICLE",
  label: "Article",
  Component: Article,
  containsOnlyOnePage: true,
  initialContents: [
    {
      key: "dynamicPart",
      type: "DYNAMIC",
      value: "",
      children: [],
    },
  ],
};
