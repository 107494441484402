import React, { createContext } from "react";
import PropTypes from "prop-types";
import SiteService from "services/SiteService";
import useAxiosCache from "hooks/axiosCache";
import generateTemplatePropsFromContents from "utils/templatePropsUtils";
import { getDefaultContentByType, generateContentId } from "utils/adminContentsUtils";
import elementsTypes from "utils/elementsTypes";

const SiteContext = createContext();
export default SiteContext;

export const SiteContextProvider = (props) => {
  const { site, children } = props;
  const [{ data }] = useAxiosCache(SiteService.getConfig("refresh"));

  // il est possible de passer un site props, cas du back office
  let currentSite = (typeof data === "object" && data.id && data) || site || {};
  if (Array.isArray(currentSite.contents) && !currentSite.contents.find((c) => c.key === "onboarding")) {
    currentSite.contents.push(generateContentId(getDefaultContentByType(elementsTypes.SITE_ONBOARDING)));
  }
  if (currentSite && currentSite.contents) {
    const contentsProps = generateTemplatePropsFromContents(currentSite.contents);
    currentSite = {
      ...contentsProps,
      ...currentSite,
    };
  }

  return <SiteContext.Provider value={currentSite}>{children}</SiteContext.Provider>;
};

SiteContextProvider.propTypes = {
  site: PropTypes.shape(),
  children: PropTypes.node,
};

SiteContextProvider.defaultProps = {
  site: null,
  children: null,
};
