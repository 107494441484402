import React, { useContext } from "react";
import PropTypes from "prop-types";
import Link from "components/templatesComponents/Link";
import { makeStyles } from "@material-ui/styles";
import Button from "components/templatesComponents/Button";
import Block from "components/templatesComponents/Block";
import SubTemplateWrapper from "components/LayoutFront/SubTemplateWrapper";
import ResultList from "components/templatesComponents/ResultList";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(10),
  },
  link: {
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      "& button": {
        width: "100%",
      },
    },
  },
  resultTableContainer: {
    backgroundColor: theme.palette.primary[10],
    margin: theme.spacing(1, 0, 3, 0),
  },
}));

const HomeResults = (props) => {
  const { page } = props;
  const { title, contents } = page;
  const { dynamicPart } = contents;

  const { language } = useContext(LanguageContext);

  const classes = useStyles();

  const displayResults =
    Array.isArray(dynamicPart) &&
    dynamicPart
      .filter((el) => el.props?.children?.type === ResultList)
      ?.find((list) => list.props?.children?.props?.results?.length > 0);

  return (
    <SubTemplateWrapper title={title} standard>
      {displayResults && <div className={classes.resultTableContainer}>{displayResults}</div>}
      <div className={classes.link}>
        <Block>
          <Button component={Link} linkComponent page={page} aria-label={t[language].home_results.button_aria_label}>
            {t[language].home_results.button_label}
          </Button>
        </Block>
      </div>
    </SubTemplateWrapper>
  );
};

HomeResults.propTypes = {
  page: PropTypes.shape(),
};

HomeResults.defaultProps = {
  page: {},
};

export default HomeResults;
