import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { v4 as uuidv4 } from "uuid";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  hiddenMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Pagination = (props) => {
  const { items, handleChangePage, count, queryParams } = props;
  const classes = useStyles();
  const { language } = useContext(LanguageContext);

  return (
    <nav role="navigation" className="fr-pagination" aria-label={t[language].components.aria_label_pagination}>
      <ul className="fr-pagination__list">
        {count && handleChangePage && (
          <li key={uuidv4()} className={classes.hiddenMobile}>
            <button
              type="button"
              className="fr-pagination__link fr-pagination__link--first fr-pagination__link--lg-label"
              onClick={(e) => handleChangePage(e, 1)}
              disabled={!queryParams.p || Number(queryParams.p) === 1}
            />
          </li>
        )}
        {items.map(({ page: pageArticle, type, selected, ...item }) => {
          const renderItem = () => {
            switch (type) {
              case "start-ellipsis":
                return (
                  <button type="button" className="fr-pagination__link">
                    …
                  </button>
                );
              case "end-ellipsis":
                return (
                  <button type="button" className="fr-pagination__link">
                    …
                  </button>
                );
              case "page":
                return (
                  <button type="button" className="fr-pagination__link" title={`Page ${pageArticle}`} {...item}>
                    {pageArticle}
                  </button>
                );
              case "previous":
                return (
                  <button
                    type="button"
                    className="fr-pagination__link fr-pagination__link--prev fr-pagination__link--lg-label"
                    {...item}
                  >
                    {count < 5 ? t[language].components.previous_page : ""}
                  </button>
                );
              case "next":
                return (
                  <button
                    type="button"
                    className="fr-pagination__link fr-pagination__link--next fr-pagination__link--lg-label"
                    {...item}
                  >
                    {count < 5 ? t[language].components.next_page : ""}
                  </button>
                );
              default:
                return (
                  <button type="button" {...item}>
                    {type}
                  </button>
                );
            }
          };
          return <li key={uuidv4()}>{renderItem()}</li>;
        })}
        {count && handleChangePage && (
          <li key={uuidv4()} className={classes.hiddenMobile}>
            <button
              type="button"
              className="fr-pagination__link fr-pagination__link--last fr-pagination__link--lg-label"
              onClick={(e) => handleChangePage(e, count)}
              disabled={Number(queryParams.p) === count}
            />
          </li>
        )}
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  items: PropTypes.PropTypes.arrayOf(PropTypes.shape()),
  handleChangePage: PropTypes.func,
  count: PropTypes.number,
  queryParams: PropTypes.shape(),
};

Pagination.defaultProps = {
  items: [],
  handleChangePage: null,
  count: 0,
  queryParams: {},
};

export default Pagination;
