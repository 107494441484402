import Processes from "components/templates/processes/Processes";
import elementsTypes from "utils/elementsTypes";
import { styleKeys } from "components/templatesComponents/card/cardStyles";

export default {
  key: "PROCESSES",
  label: "Toutes les démarches",
  Component: Processes,
  servesOtherPages: true,
  initialContents: [
    {
      key: "cardsGroups",
      type: elementsTypes.CONTENTS_GROUPS_LIST,
      value: "",
      children: [],
    },
    {
      key: "cardZero",
      type: elementsTypes.CARD,
      value: "",
      children: [],
    },
    {
      key: "cardOne",
      type: elementsTypes.CARDS_LIST,
      value: "",
      children: [
        {
          key: "desktopStyleKey",
          type: elementsTypes.SELECT,
          value: styleKeys.VERTICAL_BIG,
        },
        {
          key: "mobileStyleKey",
          type: elementsTypes.SELECT,
          value: styleKeys.LITTLE_LIGHT,
        },
        {
          key: "limit",
          value: "1",
        },
        {
          key: "cards",
          type: elementsTypes.CARD,
          value: "",
          children: [],
        },
      ],
    },
    {
      key: "cardTwo",
      type: elementsTypes.CARDS_LIST,
      value: "",
      children: [
        {
          key: "desktopStyleKey",
          type: elementsTypes.SELECT,
          value: styleKeys.VERTICAL_BIG,
        },
        {
          key: "mobileStyleKey",
          type: elementsTypes.SELECT,
          value: styleKeys.LITTLE_LIGHT,
        },
        {
          key: "limit",
          value: "1",
        },
        {
          key: "cards",
          type: elementsTypes.CARD,
          value: "",
          children: [],
        },
      ],
    },
    {
      key: "cardThree",
      type: elementsTypes.CARDS_LIST,
      value: "",
      children: [
        {
          key: "desktopStyleKey",
          type: elementsTypes.SELECT,
          value: styleKeys.VERTICAL_BIG,
        },
        {
          key: "mobileStyleKey",
          type: elementsTypes.SELECT,
          value: styleKeys.LITTLE_LIGHT,
        },
        {
          key: "limit",
          value: "1",
        },
        {
          key: "cards",
          type: elementsTypes.CARD,
          value: "",
          children: [],
        },
      ],
    },
    {
      key: "cardFour",
      type: elementsTypes.CARDS_LIST,
      value: "",
      children: [
        {
          key: "desktopStyleKey",
          type: elementsTypes.SELECT,
          value: styleKeys.VERTICAL_BIG,
        },
        {
          key: "mobileStyleKey",
          type: elementsTypes.SELECT,
          value: styleKeys.LITTLE_LIGHT,
        },
        {
          key: "limit",
          value: "1",
        },
        {
          key: "cards",
          type: elementsTypes.CARD,
          value: "",
          children: [],
        },
      ],
    },
    {
      key: "otherCards",
      type: elementsTypes.CARDS_LIST,
      value: "",
      children: [
        {
          key: "desktopStyleKey",
          type: elementsTypes.SELECT,
          value: styleKeys.VERTICAL_BIG,
        },
        {
          key: "mobileStyleKey",
          type: elementsTypes.SELECT,
          value: styleKeys.LITTLE_LIGHT,
        },
        {
          key: "cards",
          type: elementsTypes.CARD,
          value: "",
          children: [],
        },
      ],
    },
    {
      key: "subtitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "",
    },
    {
      key: "otherProcesses",
      type: elementsTypes.CARDS_LIST,
      value: "",
      children: [
        {
          key: "desktopStyleKey",
          type: elementsTypes.SELECT,
          value: styleKeys.VERTICAL_BIG,
        },
        {
          key: "mobileStyleKey",
          type: elementsTypes.SELECT,
          value: styleKeys.LITTLE_LIGHT,
        },
        {
          key: "cards",
          type: elementsTypes.CARD,
          value: "",
          children: [],
        },
      ],
    },
  ],
};
