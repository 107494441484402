import React, { useState } from "react";
import PropTypes from "prop-types";
import languages from "utils/languagesTypes";

const LanguageContext = React.createContext({
  language: "",
  setLanguage: () => null,
});
export default LanguageContext;

export const LanguageContextProvider = (props) => {
  const { children } = props;

  const [language, setLanguage] = useState(
    (typeof localStorage !== "undefined" && localStorage.getItem("language") !== "null"
      ? localStorage.getItem("language")
      : Object.keys(languages)[0]) || Object.keys(languages)[0]
  );

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

LanguageContextProvider.propTypes = {
  children: PropTypes.node,
};

LanguageContextProvider.defaultProps = {
  children: null,
};
