import antsTheme from "./antsTheme";
import immatTheme from "./immatTheme";
import permisTheme from "./permisTheme";
import passeportTheme from "./passeportTheme";
import autoEcoleTheme from "./autoEcoleTheme";
import mairieTheme from "./mairieTheme";
import phaTheme from "./phaTheme";

export default {
  ANTS: antsTheme,
  IMMAT: immatTheme,
  PERMIS: permisTheme,
  PASSEPORT_CNI: passeportTheme,
  MAIRIE: mairieTheme,
  PHA: phaTheme,
  AUTO_ECOLE: autoEcoleTheme,
};
