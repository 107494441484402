const defaultHorizontalStyle = () => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrowContainer: {
    display: "flex",
    alignItems: "center",
  },
});

const defaultVerticalStyle = (theme) => ({
  root: {
    display: "flex",
    flex: "1 1 0px",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    border: `1px solid ${theme.palette.componentColors[30]}`,
  },
  arrowContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const getCamaieu = ({ camaieu, camaieuStart, index, defaultColor }) => {
  if (camaieuStart === undefined) {
    return defaultColor;
  }
  return camaieu[(index + camaieuStart) % camaieu.length];
};

const styles = {
  LIGHT_BIG: {
    key: "LIGHT_BIG",
    titleVariant: "fr-h3",
    style: (theme) => ({
      root: {
        ...defaultVerticalStyle(theme).root,
        backgroundColor: "white",
        "a&:hover": {
          backgroundColor: theme.palette.secondary[20],
        },
      },
      content: {
        padding: theme.spacing(4, 3, 2, 3),
      },
      icon: { display: "none" },
      title: {},
      description: {},
      arrowContainer: {
        ...defaultVerticalStyle(theme).arrowContainer,
      },
      arrow: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
        color: "white",
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  LITTLE_LIGHT: {
    key: "LITTLE_LIGHT",
    titleVariant: "fr-h4",
    style: (theme) => ({
      root: {
        ...defaultVerticalStyle(theme).root,
        backgroundColor: "white",
      },
      content: {
        padding: theme.spacing(2),
      },
      icon: { display: "none" },
      title: { margin: theme.spacing(0, 0, 0.5, 0) },
      description: {},
      arrowContainer: {
        ...defaultVerticalStyle(theme).arrowContainer,
      },
      arrow: {
        padding: theme.spacing(1),
        color: theme.palette.secondary.main,
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  LITTLE_COLOR: {
    key: "LITTLE_COLOR",
    titleVariant: "fr-h4",
    style: (theme) => ({
      root: {
        ...defaultVerticalStyle(theme).root,
        backgroundColor: theme.palette.secondary[30],
        "a&:hover": {
          backgroundColor: theme.palette.secondary[30],
        },
      },
      content: {
        padding: theme.spacing(2),
      },
      icon: { display: "none" },
      title: { margin: theme.spacing(0, 0, 0.5, 0) },
      description: {},
      arrowContainer: {
        ...defaultVerticalStyle(theme).arrowContainer,
      },
      arrow: {
        padding: theme.spacing(1),
        color: theme.palette.componentColors[70],
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  LITTLE_COLOR_PRIMARY: {
    key: "LITTLE_COLOR_PRIMARY",
    titleVariant: "fr-h4",
    style: (theme) => ({
      root: {
        ...defaultVerticalStyle(theme).root,
        backgroundColor: theme.palette.primary.main,
        "a&:hover": {
          backgroundColor: theme.palette.primary.main,
        },
      },
      content: {
        padding: theme.spacing(2),
      },
      icon: { display: "none" },
      title: {
        margin: theme.spacing(0, 0, 0.5, 0),
        color: theme.palette.common.white,
      },
      description: {
        color: theme.palette.common.white,
      },
      arrowContainer: {
        ...defaultVerticalStyle(theme).arrowContainer,
      },
      arrow: {
        padding: theme.spacing(1),
        color: theme.palette.common.white,
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  LITTLE_LIGHT_PRIMARY: {
    key: "LITTLE_LIGHT_PRIMARY",
    titleVariant: "fr-h4",
    style: (theme) => ({
      root: {
        ...defaultVerticalStyle(theme).root,
        backgroundColor: theme.palette.common.white,
      },
      content: {
        padding: theme.spacing(2),
      },
      icon: { display: "none" },
      title: {
        margin: theme.spacing(0, 0, 0.5, 0),
      },
      description: {
        color: theme.palette.componentColors[70],
      },
      arrowContainer: {
        ...defaultVerticalStyle(theme).arrowContainer,
      },
      arrow: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  LIGHT_BIG_PRIMARY_IMAGE: {
    key: "LIGHT_BIG_PRIMARY_IMAGE",
    titleVariant: "fr-h2",
    imageRatio: "80",
    style: (theme) => ({
      root: {
        flex: "1 1 0px",
        display: "flex",
      },
      image: {
        display: "block",
        flex: 2,
      },
      content: {
        padding: theme.spacing(4),
        flex: 5,
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.primary[10],
      },
      icon: { display: "none" },
      title: {
        margin: theme.spacing(0, 0, 0.5, 0),
      },
      description: {
        color: theme.palette.componentColors[70],
      },
      arrowContainer: {
        display: "flex",
        alignItems: "flex-end",
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.primary[10],
      },
      arrow: {
        padding: theme.spacing(2, 2.25),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  LIGHT_PRIMARY_IMAGE: {
    key: "LIGHT_PRIMARY_IMAGE",
    titleVariant: "fr-h4",
    imageRatio: "100",
    style: (theme) => ({
      root: {
        flex: "1 1 0px",
        display: "flex",
        backgroundColor: theme.palette.primary[10],
        "a&:hover": {
          backgroundColor: theme.palette.primary[10],
        },
      },
      image: {
        display: "flex",
        alignItems: "center",
        flex: 2,
        padding: theme.spacing(2, 0),
      },
      content: {
        padding: theme.spacing(4, 2),
        flex: 5,
      },
      icon: { display: "none" },
      title: {
        margin: theme.spacing(0, 0, 0.5, 0),
      },
      description: {
        color: theme.palette.componentColors[70],
      },
      arrowContainer: {
        display: "flex",
        alignItems: "flex-end",
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.primary[10],
      },
      arrow: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  BIG: {
    key: "BIG",
    titleVariant: "fr-h3",
    style: (theme) => ({
      root: {
        ...defaultVerticalStyle(theme).root,
        backgroundColor: theme.palette.secondary[30],
        border: "none",
        "a&:hover": {
          backgroundColor: theme.palette.secondary[40],
        },
      },
      content: {
        padding: theme.spacing(2, 3),
      },
      icon: { display: "none" },
      title: {},
      description: {},
      arrowContainer: {
        ...defaultVerticalStyle(theme).arrowContainer,
      },
      arrow: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
        color: "white",
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  WITH_ICON_BIG: {
    key: "WITH_ICON_BIG",
    titleVariant: "fr-h5",
    style: (theme) => ({
      root: {
        ...defaultVerticalStyle(theme).root,
        backgroundColor: "white",
        borderTop: `8px solid ${theme.palette.secondary[20]}`,
        boxSizing: "border-box",
        "a&:hover": {
          backgroundColor: theme.palette.secondary[20],
          borderTopColor: theme.palette.secondary[40],
        },
      },
      content: {
        padding: theme.spacing(4, 3, 2, 3),
      },
      icon: {
        color: theme.palette.secondary.main,
        fontSize: "2rem",
      },
      title: {
        marginTop: theme.spacing(1),
      },
      description: {
        display: "none",
      },
      arrowContainer: {
        ...defaultVerticalStyle(theme).arrowContainer,
      },
      arrow: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.componentColors[70],
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  WITH_ICON_LITTLE: {
    key: "WITH_ICON_LITTLE",
    titleVariant: "fr-text--md",
    style: (theme) => ({
      root: {
        ...defaultVerticalStyle(theme).root,
        backgroundColor: "white",
        borderTop: `4px solid ${theme.palette.secondary[40]}`,
        "a&:hover": {
          backgroundColor: theme.palette.secondary[20],
        },
      },
      content: {
        padding: theme.spacing(4, 0, 2, 2),
        width: "180px",
        [theme.breakpoints.up("lg")]: {
          height: "196px",
        },
        display: "flex",
        flexDirection: "column",
        boxSizing: "content-box",
        overflow: "auto",
      },
      icon: {
        color: theme.palette.secondary.main,
        fontSize: "1.5rem",
      },
      title: {
        marginTop: theme.spacing(1),
        paddingRight: theme.spacing(2),
        overflow: "auto",
        height: "100%",
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          borderRadius: 10,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: theme.palette.componentColors[60],
          borderRadius: "10px",
        },
      },
      description: {
        display: "none",
      },
      arrowContainer: {
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
      },
      arrow: {
        display: "none",
      },
      linkLabel: {
        fontWeight: 600,
      },
    }),
  },
  HORIZONTAL: {
    key: "HORIZONTAL",
    titleVariant: "fr-text--md",
    style: (theme) => ({
      root: {
        ...defaultHorizontalStyle(theme).root,
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.componentColors[30]}`,
        "a&:hover": {
          borderColor: theme.palette.secondary.main,
          backgroundColor: theme.palette.common.white,
        },
      },
      content: {
        padding: theme.spacing(1, 1, 1, 2),
      },
      icon: {
        display: "none",
      },
      title: {
        color: theme.palette.componentColors[70],
        marginBottom: 0,
      },
      description: {
        display: "none",
      },
      arrowContainer: {
        display: "flex",
        alignItems: "center",
      },
      arrow: {
        padding: theme.spacing(2),
        color: theme.palette.secondary.main,
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  HORIZONTAL_COLOR: {
    key: "HORIZONTAL_COLOR",
    titleVariant: "fr-text--md",
    style: (theme) => ({
      root: {
        ...defaultHorizontalStyle(theme).root,
        backgroundColor: theme.palette.secondary[20],
        border: `1px solid ${theme.palette.secondary.main}`,
        boxSizing: "border-box",
        height: "64px",
        "a&:hover": {
          backgroundColor: theme.palette.secondary[20],
        },
      },
      content: {
        padding: theme.spacing(1, 1, 1, 2),
      },
      icon: { display: "none" },
      title: {
        color: theme.palette.componentColors[70],
      },
      description: {
        display: "none",
      },
      arrowContainer: {
        ...defaultHorizontalStyle(theme).arrowContainer,
      },
      arrow: {
        padding: theme.spacing(2),
        color: theme.palette.componentColors[70],
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  HORIZONTAL_BIG: {
    key: "HORIZONTAL_BIG",
    titleVariant: "fr-text--md",
    style: (theme, props) => ({
      root: {
        ...defaultHorizontalStyle(theme).root,
        backgroundColor: getCamaieu({
          camaieu: theme.palette.primary.tones,
          camaieuStart: props.camaieuStart,
          index: props.index,
          defaultColor: theme.palette.primary.main,
        }),
        minHeight: "120px",
        "a&:hover": {
          backgroundColor: getCamaieu({
            camaieu: theme.palette.primary.tones,
            camaieuStart: props.camaieuStart,
            index: props.index,
            defaultColor: theme.palette.primary.main,
          }),
        },
      },
      content: {
        padding: theme.spacing(2, 2, 2, 3),
        flex: "1 0",
      },
      icon: { display: "none" },
      title: {
        color: theme.palette.common.white,
        fontWeight: 400,
        fontSize: "1rem",
      },
      description: {
        display: "none",
      },
      arrowContainer: {
        ...defaultHorizontalStyle(theme).arrowContainer,
      },
      arrow: {
        padding: theme.spacing(2.5),
        color: theme.palette.common.white,
        "&:before": {
          "--icon-size": "2rem",
        },
      },
      linkLabel: {
        display: "none",
      },
    }),
  },

  VERTICAL_BIG_SEARCH_RESULT: {
    key: "VERTICAL_BIG_SEARCH_RESULT",
    titleVariant: "fr-h3",
    style: (theme) => ({
      root: {
        ...defaultVerticalStyle(theme).root,
        backgroundColor: theme.palette.secondary[10],
        border: "none",
        "a&:hover": {
          backgroundColor: theme.palette.secondary[20],
        },
      },
      content: {
        padding: theme.spacing(3, 1, 1, 4),
      },
      icon: { display: "none" },
      title: {
        marginBottom: theme.spacing(1),
      },
      subtitle: {
        marginBottom: theme.spacing(2),
      },
      description: {},
      arrowContainer: {
        ...defaultVerticalStyle(theme).arrowContainer,
        display: "flex",
        justifyContent: "space-between",
      },
      arrow: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
      },
      linkLabel: {
        paddingLeft: theme.spacing(4),
        fontWeight: 600,
      },
    }),
  },
  VERTICAL_BIG_ONE_LINE: {
    key: "VERTICAL_BIG_ONE_LINE",
    titleVariant: "fr-h5",
    style: (theme) => ({
      root: {
        ...defaultVerticalStyle(theme).root,
        border: "none",
        backgroundColor: theme.palette.primary.main,
        "a&:hover": {
          backgroundColor: theme.palette.primary.main,
        },
      },
      content: {
        padding: theme.spacing(2, 2, 2, 3),
      },
      icon: { display: "none" },
      title: {
        color: theme.palette.common.white,
      },
      description: {
        display: "none",
      },
      arrowContainer: {
        ...defaultVerticalStyle(theme).arrowContainer,
      },
      arrow: {
        padding: theme.spacing(2),
        color: theme.palette.common.white,
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  VERTICAL_BIG: {
    key: "VERTICAL_BIG",
    titleVariant: "fr-h6",
    style: (theme, props) => ({
      root: {
        ...defaultVerticalStyle(theme).root,
        border: "none",
        backgroundColor: getCamaieu({
          camaieu: theme.palette.primary.tones,
          camaieuStart: props.camaieuStart,
          index: props.index,
          defaultColor: theme.palette.primary.main,
        }),
        "a&:hover": {
          backgroundColor: getCamaieu({
            camaieu: theme.palette.primary.tones,
            camaieuStart: props.camaieuStart,
            index: props.index,
            defaultColor: theme.palette.primary.main,
          }),
        },
      },
      content: {
        padding: theme.spacing(2, 2, 2, 3),
      },
      icon: { display: "none" },
      title: {
        color: theme.palette.common.white,
        marginTop: theme.spacing(1),
      },
      description: {
        color: "#d3daf8",
      },
      arrowContainer: {
        ...defaultVerticalStyle(theme).arrowContainer,
      },
      arrow: {
        padding: theme.spacing(2),
        color: theme.palette.common.white,
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  VERTICAL_BIG_PROCESS_SITES: {
    key: "VERTICAL_BIG_PROCESS_SITES",
    titleVariant: "fr-text--lg",
    style: (theme) => ({
      root: {
        ...defaultVerticalStyle(theme).root,
        border: "none",
        backgroundColor: theme.palette.primary.main,
        "a&:hover": {
          backgroundColor: theme.palette.primary.main,
        },
      },
      content: {
        padding: theme.spacing(2, 2, 2, 3),
      },
      icon: {
        fontSize: "2rem",
        color: theme.palette.common.white,
      },
      title: {
        color: theme.palette.common.white,
      },
      description: {
        display: "none",
      },
      arrowContainer: {
        ...defaultVerticalStyle(theme).arrowContainer,
      },
      arrow: {
        padding: theme.spacing(2),
        color: theme.palette.common.white,
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
};

export const styleKeys = Object.keys(styles).reduce((output, k) => ({ ...output, [k]: styles[k].key }), {});

export default styles;
