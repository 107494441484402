export default {
  ACCORDION: "ACCORDION",
  ACCORDION_PANEL: "ACCORDION_PANEL",
  ATTACHMENT: "ATTACHMENT",
  ATTACHMENT_LINK: "ATTACHMENT_LINK",
  ATTACHMENTS: "ATTACHMENTS",
  BUTTON: "BUTTON",
  CARD: "CARD",
  CARD_NEWS: "CARD_NEWS",
  CARDS_LIST: "CARDS_LIST",
  CARDS_LIST_NEWS: "CARDS_LIST_NEWS",
  OLD_CARD_NEWS: "OLD_CARD_NEWS",
  OLD_CARDS_LIST_NEWS: "OLD_CARDS_LIST_NEWS",
  CONTACT_FORM_PROFILE_LIST: "CONTACT_FORM_PROFILE_LIST",
  CONTACT_FORM_PROFILE: "CONTACT_FORM_PROFILE",
  CONTACT_FORM_SUBJECT_LIST: "CONTACT_FORM_SUBJECT_LIST",
  CONTACT_FORM_SUBJECT: "CONTACT_FORM_SUBJECT",
  CONTENTS_GROUP: "CONTENTS_GROUP",
  CONTENTS_GROUPS_LIST: "CONTENTS_GROUPS_LIST",
  CHECKBOX: "CHECKBOX",
  DYNAMIC: "DYNAMIC",
  EDITABLE_INPUT: "EDITABLE_INPUT",
  EXPANSION_PANEL: "EXPANSION_PANEL",
  EXPANSION_PANEL_DETAILS: "EXPANSION_PANEL_DETAILS",
  EXPANSION_TEXT: "EXPANSION_TEXT",
  FILE: "FILE",
  FILE_IMAGE: "FILE_IMAGE",
  GEOLOCALISATION: "GEOLOCALISATION",
  GEOLOC_SOURCETYPE: "GEOLOC_SOURCETYPE",
  GLOSSARY: "GLOSSARY",
  GLOSSARY_ITEM: "GLOSSARY_ITEM",
  GRID: "GRID",
  HIGHLIGHTED_TEXT: "HIGHLIGHTED_TEXT",
  IMAGE: "IMAGE",
  IMAGE_BLOCK: "IMAGE_BLOCK",
  ICON: "ICON",
  ICON_LINK: "ICON_LINK",
  INFO_BLOCK: "INFO_BLOCK",
  INFO_BLOCK_LIST: "INFO_BLOCK_LIST",
  INPUT: "INPUT",
  LINK: "LINK",
  MAILTO: "MAILTO",
  MENU_ITEM: "MENU_ITEM",
  MENU_GROUP: "MENU_GROUP",
  NUMERIC: "NUMERIC",
  PAGE: "PAGE",
  RESULT_LIST: "RESULT_LIST",
  RESULT: "RESULT",
  QUOTE: "QUOTE",
  SELECT: "SELECT",
  SHORT_TEXT: "SHORT_TEXT",
  SITE_NAME: "SITE_NAME",
  SLIDER: "SLIDER",
  SLIDER_INLINE: "SLIDER_INLINE",
  STEP: "STEP",
  STEP_V2: "STEP_V2",
  STEP_V2_CONTENT: "STEP_V2_CONTENT",
  TABS: "TABS",
  TABS_CARDS: "TABS_CARDS",
  TAB: "TAB",
  TAB_CARDS: "TAB_CARDS",
  TEXT: "TEXT",
  TIMELINE: "TIMELINE",
  SITE_SETTINGS: "SITE_SETTINGS",
  SITE_ONBOARDING: "SITE_ONBOARDING",
  SOCIAL_NETWORK: "SOCIAL_NETWORK",
  STEPPER: "STEPPER",
  STEPPER_STEP: "STEPPER_STEP",
  STEPPER_DETAIL: "STEPPER_DETAIL",
  SUBPAGES_LIST: "SUBPAGES_LIST",
  SUBPAGE: "SUBPAGE",
  URL: "URL",
  VIDEO: "VIDEO",
  VIDEOS_LIST: "VIDEOS_LIST",
  WARNING_BANNER: "WARNING_BANNER",
};
