import React, { useContext } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import sanitizeHtml from "sanitize-html";
import EulerianService from "services/EulerianService";
import PageVersionContext from "components/PageVersionContext";

export const componentClassName = "Da-Text";

const useStyles = makeStyles((theme) => ({
  text: (props) => ({
    boxShadow: props.highlight ? `inset .22rem 0 0 0 ${theme.palette.secondary.main}` : "none",
    width: "100%",
    "& a": {
      color: "var(--text-action-high-blue-france)",
    },
    "& a[target=_blank]:after": {
      content: `"\\f08e"`,
      fontSize: "12px",
      fontFamily: `"Font Awesome 5 Pro"`,
      paddingLeft: "4px",
    },
    "&  ul": {
      listStyle: "none",
      paddingLeft: "16px",
      "& li": {
        textIndent: "-16px",
        "&:before": {
          content: `"\\f111"`,
          fontWeight: "600",
          fontSize: "8px",
          verticalAlign: "middle",
          textIndent: "0",
          width: "16px",
          display: "inline-block",
          fontFamily: `"Font Awesome 5 Pro"`,
          color: theme.palette.secondary.main,
        },
        "& ul": {
          marginLeft: "24px",
          "& li": {
            textIndent: "-22px",
            "&:before": {
              content: `"\\f00c"`,
              fontWeight: "400",
              fontSize: "14px",
              width: "22px",
              color: theme.palette.secondary.main,
            },
          },
        },
      },
    },
  }),
}));

const sanitizerOptions = {
  ...sanitizeHtml.defaults,
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(["h2", "img", "u", "s", "sub", "address", "sup"]),
  allowedAttributes: {
    ...sanitizeHtml.defaults.allowedAttributes,
    "*": ["id", "class", "style", "title"],
    a: ["href", "name", "target"],
    img: ["src", "target", "alt"],
  },
  allowedSchemesByTag: {
    ...sanitizeHtml.defaults.allowedSchemesByTag,
    img: ["https", "data"],
  },
};

const Text = (props) => {
  const { children, className, highlight, ...others } = props;

  const childrenProps = {};
  if (typeof children === "string") {
    childrenProps.dangerouslySetInnerHTML = { __html: sanitizeHtml(decodeURIComponent(children), sanitizerOptions) };
  } else {
    childrenProps.children = children;
  }

  const classes = useStyles(props);

  const { currentPageVersion } = useContext(PageVersionContext);
  const { breadcrumb = [] } = currentPageVersion || {};

  const handleClick = (e) => {
    const targetIsLink = e.target && e.target.tagName && e.target.tagName.toLowerCase() === "a";
    if (breadcrumb && targetIsLink) {
      EulerianService.sendClick({ link: [`link::click::${e.target?.firstChild?.nodeValue?.toLowerCase() || ""}`] });
    }
  };

  return (
    <p
      className={classnames(className, componentClassName, classes.text, highlight && "fr-highlight")}
      {...childrenProps}
      {...others}
      onClick={handleClick}
      role="presentation"
    />
  );
};

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  highlight: PropTypes.bool,
};

Text.defaultProps = {
  children: null,
  className: "",
  highlight: false,
};

export default Text;
