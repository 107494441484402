import React from "react";

export default (Component) => {
  if (typeof Component !== "function") {
    return null;
  }

  return (props) => {
    // eslint-disable-next-line react/prop-types
    const { Wrapper, wrapperProps, ...others } = props;

    if (!Wrapper) {
      return <Component {...others} />;
    }

    return (
      <Wrapper {...wrapperProps} wrappedComponentProps={others}>
        <Component {...others} />
      </Wrapper>
    );
  };
};
