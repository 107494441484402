export default class CookieService {
  static set = (name, value, expires) => {
    let cookieStr = `${name}=${value}; domain=${process.env.cookie_consent_domain}; `;
    if (expires) {
      cookieStr += `expires=${expires.toUTCString()}`;
    }
    document.cookie = cookieStr;
  };

  static get = (name) => {
    const cookies = `; ${document.cookie}`;
    const cookie = cookies.split(`; ${name}=`);
    if (cookie.length === 2) return cookie.pop().split(";").shift();
    return undefined;
  };

  static delete = (name) => {
    if (CookieService.get(name)) {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }
  };
}
