import React from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  imageBlock: (props) => ({
    height: "100%",
    width: props.width ? `${props.width}%` : "100%",
  }),
  image: {
    height: "100%",
    width: "100%",
  },
}));

const ImageBlock = (props) => {
  const { width, mobileWidth, image, justifyContent, legend } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles({ width: isMobile ? mobileWidth : width });

  return (
    <figure
      className="fr-content-media"
      role="group"
      aria-label={legend?.props?.value || legend}
      style={{ flexDirection: "row", justifyContent }}
    >
      <div className={classes.imageBlock}>
        {image}
        {legend && <figcaption className="fr-content-media__caption">{legend}</figcaption>}
      </div>
    </figure>
  );
};

ImageBlock.propTypes = {
  width: PropTypes.string,
  mobileWidth: PropTypes.string,
  justifyContent: PropTypes.oneOf(["flex-start", "center", "flex-end"]).isRequired,
  image: PropTypes.shape().isRequired,
  legend: PropTypes.string,
};

ImageBlock.defaultProps = {
  width: null,
  mobileWidth: null,
  legend: "",
};

export default ImageBlock;
