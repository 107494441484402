import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Block from "components/templatesComponents/Block";
import Wrapper from "components/LayoutFront/Wrapper";
import Separator from "components/templatesComponents/Separator";

const useStyles = makeStyles((theme) => ({
  background: (props) => ({
    position: "relative",
    "&:before": {
      content: `""`,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "100%",
      background: `${props.backgroundColor}`,
      zIndex: -1,
      [theme.breakpoints.up("lg")]: {
        height: "300px",
      },
    },
  }),
  backgroundSide: (props) => ({
    position: "relative",
    "&:before": {
      content: `""`,
      position: "absolute",
      top: 0,
      right: 0,
      width: "50%",
      height: "100%",
      background: `${props.backgroundColor}`,
      zIndex: -1,
      [theme.breakpoints.up("lg")]: {
        height: "300px",
      },
    },
  }),
  Wrapper: {
    zIndex: 1,
    position: "relative",
  },
  content: {
    [theme.breakpoints.up("lg")]: {
      paddingBottom: 0,
    },
  },
  title: (props) => ({
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      marginTop: props.standard ? 0 : theme.spacing(8),
    },
  }),
}));

const SubTemplateWrapper = (props) => {
  const { children, title, backgroundColor, right, standard } = props;
  const classes = useStyles({ backgroundColor, standard });

  return (
    <div>
      <Separator />
      {right ? (
        <Wrapper
          wrapperProps={{ className: classes.Wrapper }}
          leftProps={{ className: classes.backgroundSide }}
          rightProps={{ className: classes.background }}
        >
          <div className={classnames(classes.background, classes.content)}>
            <Block>
              <h2 className={classnames(classes.title, "fr-h1")}>{title}</h2>
            </Block>
            {children}
          </div>
        </Wrapper>
      ) : (
        <Wrapper leftProps={{ className: classes.background }} wrapperProps={{ className: classes.Wrapper }}>
          <div className={classnames(classes.background, classes.content)}>
            <Block>
              <h2 className={classnames(classes.title, "fr-h1")}>{title}</h2>
            </Block>
            {children}
          </div>
        </Wrapper>
      )}
    </div>
  );
};

SubTemplateWrapper.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  right: PropTypes.bool,
  standard: PropTypes.bool,
};

SubTemplateWrapper.defaultProps = {
  children: null,
  backgroundColor: "none",
  title: null,
  right: false,
  standard: false,
};

export default SubTemplateWrapper;
