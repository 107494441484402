import React, { useContext, Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import Link from "components/templatesComponents/Link";
import CardContext from "components/templatesComponents/CardContext";
import SiteService from "services/SiteService";
import useAxiosCache from "hooks/axiosCache";

const TileCard = (props) => {
  const { page: pageVersion, title, description } = props;
  const { fullPath, page } = pageVersion || {};
  let { contents = [] } = pageVersion || {};
  const { siteId } = page || {};

  if (!Array.isArray(contents)) {
    contents = [contents];
  }

  const [{ data: allSites = [] }] = useAxiosCache(SiteService.getConfig("getAllSites"));

  const findSiteById = useMemo(() => allSites?.find((site) => site.id === siteId), [allSites, siteId]);

  const { host, name: siteNameById } = findSiteById || {};

  const siteName = contents ? contents[0]?.value : "";

  const logoSite = siteName || siteNameById;

  const { Wrapper = Fragment, wrapperProps = {} } = useContext(CardContext);

  const external = useMemo(() => (window?.location?.host ? host !== window.location.host : false), [host]);
  const isBoFullPath =
    fullPath && fullPath.includes("site=") && fullPath.includes("page=") && fullPath.includes("version=");

  return (
    <Wrapper {...wrapperProps}>
      <div className="fr-tile fr-tile--horizontal fr-enlarge-link" style={{ height: "100%" }}>
        <div className="fr-tile__body">
          <div className="fr-tile__content">
            <h4 className="fr-tile__title">
              <Link
                url={isBoFullPath ? fullPath : `${host}${fullPath}`}
                external={external}
                style={{ wordBreak: "break-word" }}
              >
                {title}
              </Link>
            </h4>
            <p className="fr-tile__desc" role="presentation">
              {description}
            </p>
          </div>
        </div>
        <div className="fr-tile__img">
          <img src={logoSite ? `/logo-${logoSite}.svg` : "/default-image.png"} className="fr-responsive-img" alt="" />
        </div>
      </div>
    </Wrapper>
  );
};

TileCard.propTypes = {
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  title: PropTypes.string,
  description: PropTypes.string,
};

TileCard.defaultProps = {
  page: null,
  title: null,
  description: null,
};

export default TileCard;
