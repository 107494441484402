import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Block from "components/templatesComponents/Block";
import SubTemplateWrapper from "components/LayoutFront/SubTemplateWrapper";
import LastNewsBlock from "components/templatesComponents/LastNewsBlock";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  link: {
    marginTop: theme.spacing(7),
  },
}));

const HomeLastNews = (props) => {
  const { page: pageVersion } = props;
  const { title, contents, page } = pageVersion;
  const { lang } = page;
  const { highlightedNews, homeButton } = contents;

  const classes = useStyles();

  return (
    <SubTemplateWrapper title={title} standard>
      <Block>
        <LastNewsBlock highlightedNews={highlightedNews} lang={lang} />
        <Grid container item xs={12} className={classes.link}>
          <button type="button" className="fr-btn fr-btn--tertiary" tabIndex="-1">
            {homeButton}
          </button>
        </Grid>
      </Block>
    </SubTemplateWrapper>
  );
};

HomeLastNews.propTypes = {
  page: PropTypes.shape(),
};

HomeLastNews.defaultProps = {
  page: {},
};

export default HomeLastNews;
