import React, { useContext, useEffect } from "react";
import Icon from "components/templatesComponents/Icon";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import smoothscroll from "smoothscroll-polyfill";
import Button from "components/templatesComponents/Button";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  scroller: {
    color: theme.palette.primary[40],
    position: "fixed",
    bottom: "400px",
    margin: "0 auto",
    pointerEvents: "none",
    maxWidth: "1400px",
    width: "100%",
    left: 0,
    fontSize: "28px",
    right: 0,
    paddingRight: "80px",
    display: "flex",
    justifyContent: "flex-end",
    zIndex: "250",
    boxSizing: "border-box",
    "& button": {
      fontSize: "24px",
      pointerEvents: "auto",
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "@media print": {
      display: "none",
    },
  },
  backToTop: {
    animation: `$fadeIn ease 700ms`,
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
}));
const BackToTop = (props) => {
  const { scrollableClass } = props;

  const { language } = useContext(LanguageContext);

  if (typeof window !== "undefined") {
    smoothscroll.polyfill();
  }
  const listener = () => {
    if (typeof document !== "undefined") {
      const page = document.getElementsByClassName(scrollableClass)[0];
      if (page && page.scrollTop + page.clientHeight > 2048) {
        document.getElementById("backToTop").style.display = "block";
      } else {
        document.getElementById("backToTop").style.display = "none";
      }
    }
  };

  useEffect(() => {
    if (typeof document !== "undefined") {
      const page = document.getElementsByClassName(scrollableClass)[0];
      if (page) {
        page.addEventListener("scroll", listener);
        return () => {
          page.removeEventListener("scroll", listener);
        };
      }
    }
    return null;
  });

  const handleScrollUp = () => {
    if (typeof document !== "undefined") {
      const page = document.getElementsByClassName(scrollableClass)[0];
      page.scroll({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.scroller}>
      <Button
        id="backToTop"
        className={classes.backToTop}
        onClick={handleScrollUp}
        size="lg"
        aria-label={t[language].components.back_to_top_icon_title}
      >
        <Icon icon="long-arrow-up" iconDSFR="arrow-up-line" title={t[language].components.back_to_top_icon_title} />
      </Button>
    </div>
  );
};

BackToTop.propTypes = {
  scrollableClass: PropTypes.string.isRequired,
};

export default BackToTop;
