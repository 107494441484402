import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    color: theme.palette.componentColors[70],
    fontSize: 14,
    background: "#F8F8F8",
    flex: "1 0 auto",
    width: "100%",
    zIndex: 1,
    [theme.breakpoints.up("lg")]: {
      background: "#fff",
    },
    paddingBottom: theme.spacing(10),
    margin: "0 auto",
    "@media print": {
      "& button": {
        display: "none",
      },
    },
  },
}));

const Content = (props) => {
  const { children, pageWidth } = props;
  const classes = useStyles();

  return (
    <div className={classes.content} style={{ maxWidth: pageWidth }}>
      {children}
    </div>
  );
};

Content.propTypes = {
  children: PropTypes.node,
  pageWidth: PropTypes.string.isRequired,
};

Content.defaultProps = {
  children: null,
};

export default Content;
