import elementsTypes from "utils/elementsTypes";
import PageLinks from "components/templates/pageLinks/PageLinks";

export default {
  key: "PAGE_LINKS",
  label: "Liste de liens",
  Component: PageLinks,
  servesOtherPages: true,
  initialContents: [
    {
      key: "pageLinks",
      type: elementsTypes.CARDS_LIST,
      value: "",
      children: [],
    },
  ],
};
