import Standard from "components/templates/standard/Standard";
import elementsTypes from "utils/elementsTypes";

export default {
  key: "STANDARD",
  label: "Standard",
  Component: Standard,
  initialContents: [
    {
      key: "infoBlocks",
      type: elementsTypes.INFO_BLOCK_LIST,
      value: "",
      children: [
        {
          key: "limit",
          value: "1",
        },
      ],
    },
    {
      key: "dynamicPart",
      type: elementsTypes.DYNAMIC,
      value: "",
      children: [],
    },
  ],
};
