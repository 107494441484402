import axios from "axios";
import missingDepartments from "apps/common/comedecMissingDepartments.json";

class ComedecService {
  static getDepartments = async () => {
    const options = {
      method: "GET",
      url: `https://geo.api.gouv.fr/departements`,
    };
    const { data } = await axios(options);
    const departments = data.concat(missingDepartments);
    departments.sort((a, b) => a.code.localeCompare(b.code));
    return departments;
  };

  static getCities = async ({ city }) => {
    const options = {
      method: "GET",
      url: `https://geo.api.gouv.fr/communes`,
      params: {
        nom: city,
      },
    };
    const response = await axios(options);
    return response;
  };

  static getComedecCities = ({ nom, postalCode }) => {
    return axios.get("/@communes", { params: { nom, postalCode } }).then((response) => {
      return response;
    });
  };

  static getCityInfos = ({ nom, codeDepartement }) => {
    return axios.get("/@comedec-validation", { params: { nom, codeDepartement } }).then((response) => {
      return response.data;
    });
  };
}

export default ComedecService;
