import axios from "axios";

const configs = {
  refresh: () => ({
    url: "/@refresh",
  }),
  getAllSites: () => ({
    url: "/getSites",
  }),
};

class SiteService {
  static get = () => {
    return axios(configs.refresh()).then((response) => response.data);
  };

  static getConfig(methodName, options) {
    const getter = configs[methodName];
    if (typeof getter === "function") {
      return getter(options);
    }
    return {};
  }
}

export default SiteService;
