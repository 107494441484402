import Help from "components/templates/help/Help";
import elementsTypes from "utils/elementsTypes";

export default {
  key: "HELPMAIRIE",
  label: "Aide et contact",
  Component: Help,
  servesOtherPages: true,
  initialContents: [
    {
      key: "frequentlyAskedQuestionsTitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "Questions fréquentes",
    },
    {
      key: "frequentlyAskedQuestions",
      type: elementsTypes.TABS,
      value: "",
      children: [
        {
          key: "identifier",
          type: elementsTypes.INPUT,
          value: "tf",
        },
        {
          key: "tabs",
          type: elementsTypes.TAB,
          value: "",
          children: [
            {
              key: "title",
              type: elementsTypes.INPUT,
              value: "Onglet 1",
            },
            {
              key: "children",
              type: elementsTypes.DYNAMIC,
              value: "",
              children: [],
            },
          ],
        },
        {
          key: "tabs",
          type: elementsTypes.TAB,
          value: "",
          children: [
            {
              key: "title",
              type: elementsTypes.INPUT,
              value: "Onglet 2",
            },
            {
              key: "children",
              type: elementsTypes.DYNAMIC,
              value: "",
              children: [],
            },
          ],
        },
        {
          key: "tabs",
          type: elementsTypes.TAB,
          value: "",
          children: [
            {
              key: "title",
              type: elementsTypes.INPUT,
              value: "Onglet 3",
            },
            {
              key: "children",
              type: elementsTypes.DYNAMIC,
              value: "",
              children: [],
            },
          ],
        },
      ],
    },
    {
      key: "othersQuestionsTitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "Une autre question ?",
    },
    {
      key: "othersQuestions",
      type: elementsTypes.TABS_CARDS,
      value: "",
      children: [
        {
          key: "identifier",
          type: elementsTypes.INPUT,
          value: "to",
        },
        {
          key: "tabs",
          type: elementsTypes.TAB_CARDS,
          value: "",
          children: [
            {
              key: "title",
              type: elementsTypes.INPUT,
              value: "Onglet 1",
            },
            {
              key: "children",
              type: elementsTypes.CARDS_LIST,
              value: "",
              children: [],
            },
          ],
        },
        {
          key: "tabs",
          type: elementsTypes.TAB_CARDS,
          value: "",
          children: [
            {
              key: "title",
              type: elementsTypes.INPUT,
              value: "Onglet 2",
            },
            {
              key: "children",
              type: elementsTypes.CARDS_LIST,
              value: "",
              children: [],
            },
          ],
        },
        {
          key: "tabs",
          type: elementsTypes.TAB_CARDS,
          value: "",
          children: [
            {
              key: "title",
              type: elementsTypes.INPUT,
              value: "Onglet 3",
            },
            {
              key: "children",
              type: elementsTypes.CARDS_LIST,
              value: "",
              children: [],
            },
          ],
        },
      ],
    },
    {
      key: "contactTitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "Vous n'avez pas trouvé la réponse que vous cherchiez ?",
    },
    {
      key: "contactUsTitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "Contactez-nous:",
    },
    {
      key: "contactForm",
      value: "",
      children: [
        {
          key: "title",
          type: elementsTypes.EDITABLE_INPUT,
          value: "Par formulaire",
        },
        {
          key: "subTitle",
          type: elementsTypes.TEXT,
          value: "Le moyen le plus efficace pour contacter nos équipes",
        },
        {
          key: "delay",
          type: elementsTypes.TEXT,
          value: "Vous recevrez une réponse en moins de 24 heures",
        },
        {
          key: "description",
          type: elementsTypes.TEXT,
          value:
            "Afin d'avoir tous les éléments concernant votre dossier et pour mieux répondre a vos questions, nous vous conseillons de renseigner dans ce message les champs suivants",
        },
      ],
    },
    {
      key: "ContactFormProfilesList",
      type: elementsTypes.CONTACT_FORM_PROFILE_LIST,
      value: "",
      children: [
        {
          key: "ContactFormProfiles",
          type: elementsTypes.CONTACT_FORM_PROFILE,
          value: "",
          children: [
            {
              key: "title",
              type: elementsTypes.INPUT,
              value: "Mairie",
            },
          ],
        },
      ],
    },
    {
      key: "ContactFormSubjectsList",
      type: elementsTypes.CONTACT_FORM_SUBJECT_LIST,
      value: "",
      children: [
        {
          key: "ContactFormSubjects",
          type: elementsTypes.CONTACT_FORM_SUBJECT,
          value: "",
          children: [
            {
              key: "title",
              type: elementsTypes.INPUT,
              value: "Je souhaite créer/modifier/supprimer ma fiche (annexe) mairie",
            },
            {
              key: "recipient",
              type: elementsTypes.INPUT,
              value: "celluleweb-ants@interieur.gouv.fr",
            },
            {
              key: "autoReply",
              type: elementsTypes.CHECKBOX,
              value: "false",
            },
          ],
        },
        {
          key: "ContactFormSubjects",
          type: elementsTypes.CONTACT_FORM_SUBJECT,
          value: "",
          children: [
            {
              key: "title",
              type: elementsTypes.INPUT,
              value: "Je souhaite créer/accéder à mon compte mairie",
            },
            {
              key: "recipient",
              type: elementsTypes.INPUT,
              value: "celluleweb-ants@interieur.gouv.fr",
            },
            {
              key: "autoReply",
              type: elementsTypes.CHECKBOX,
              value: "false",
            },
          ],
        },
        {
          key: "ContactFormSubjects",
          type: elementsTypes.CONTACT_FORM_SUBJECT,
          value: "",
          children: [
            {
              key: "title",
              type: elementsTypes.INPUT,
              value: "Je souhaite avoir des informations sur la plateforme nationale de prise de rendez-vous",
            },
            {
              key: "recipient",
              type: elementsTypes.INPUT,
              value: "ants-rdvmairie@interieur.gouv.fr",
            },
            {
              key: "autoReply",
              type: elementsTypes.CHECKBOX,
              value: "false",
            },
          ],
        },
        {
          key: "ContactFormSubjects",
          type: elementsTypes.CONTACT_FORM_SUBJECT,
          value: "",
          children: [
            {
              key: "title",
              type: elementsTypes.INPUT,
              value: "Je souhaite avoir des informations sur le dispositif COMEDEC",
            },
            {
              key: "recipient",
              type: elementsTypes.INPUT,
              value: "projet-comedec-ants@interieur.gouv.fr",
            },
            {
              key: "autoReply",
              type: elementsTypes.CHECKBOX,
              value: "false",
            },
          ],
        },
      ],
    },
    {
      key: "contactPhone",
      value: "",
      children: [
        {
          key: "title",
          type: elementsTypes.EDITABLE_INPUT,
          value: "Par téléphone",
        },
        {
          key: "subtitle",
          type: elementsTypes.EDITABLE_INPUT,
          value: "Vous pouvez nous joindre du lundi au vendredi de 7h45 à 19h et le samedi de 8h à 17h",
        },
        {
          key: "detail",
          value: "",
          children: [
            {
              key: "phone1",
              type: elementsTypes.TEXT,
              value: "34 00 en France métropolitaine (numéro non surtaxé)",
            },
            {
              key: "phone2",
              type: elementsTypes.TEXT,
              value: "09 70 83 07 07 depuis l'Outre-Mer et l'étranger",
            },
            {
              key: "phone3",
              type: elementsTypes.TEXT,
              value:
                "L'Agence nationale des titres sécurisés permet aux personnes sourdes et malentendantes d'accéder par téléphone à son centre de support.",
            },
            {
              key: "deafAccess",
              type: elementsTypes.BUTTON,
              value: "",
              children: [
                {
                  key: "text",
                  value: "Accès sourds et malentendants",
                },
                {
                  key: "link",
                  type: elementsTypes.LINK,
                  value: "",
                  children: [
                    {
                      key: "url",
                      type: elementsTypes.URL,
                      value: "https://app.acce-o.fr/client/ants",
                    },
                    {
                      key: "external",
                      type: elementsTypes.CHECKBOX,
                      value: "true",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      key: "contactDigitalPoints",
      value: "",
      children: [
        {
          key: "title",
          type: elementsTypes.EDITABLE_INPUT,
          value: "",
        },
        {
          key: "subtitle",
          type: elementsTypes.EDITABLE_INPUT,
          value: "",
        },
        {
          key: "detail",
          value: "",
          children: [
            {
              key: "text",
              type: elementsTypes.TEXT,
              value: "",
            },
            {
              key: "digitalPoint",
              type: elementsTypes.BUTTON,
              value: "",
              children: [
                {
                  key: "text",
                  value: "",
                },
                {
                  key: "link",
                  type: elementsTypes.LINK,
                  value: "",
                  children: [
                    {
                      key: "url",
                      type: elementsTypes.URL,
                      value: "",
                    },
                    {
                      key: "external",
                      type: elementsTypes.CHECKBOX,
                      value: "true",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
