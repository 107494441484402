import PropTypes from "prop-types";

const Numeric = (props) => {
  let { number } = props;
  number = number.replace(",", ".");
  let [intPart] = number.split(".");
  const [, floatingPart] = number.split(".");
  intPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return intPart + (floatingPart ? `.${floatingPart}` : "");
};

Numeric.propTypes = {
  number: PropTypes.string.isRequired,
};

export default Numeric;
