import React, { useCallback, useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import DSFRModal from "components/templatesComponents/DSFRModal";
import CookiesTable from "components/CookiesTable";
import LanguageContext from "components/LanguageContext";
import SiteContext from "components/SiteContext";
import CookiesModalContext from "components/CookiesModalContext";
import Link from "components/templatesComponents/Link";
import { getSiteName } from "utils/commonUtils";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  description: {
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.up("md")]: {
      maxWidth: "50%",
    },
  },
  externalIcon: {
    paddingLeft: "6px",
    "&::before": {
      "--icon-size": "0.875rem",
    },
  },
  externalLink: {
    paddingBottom: theme.spacing(2),
    backgroundImage: "none",
  },
  radio: {
    "&.Mui-focusVisible": {
      border: "2px solid #0a76f6",
    },
  },
}));

const CookiesModal = (props) => {
  const { cookieConsent, handleCookiesModal, handleValidateClick, handleRefuseClick, ...others } = props;

  const [eulerianConsent, setEulerianConsent] = useState("Accepter");

  const { openCookiesModal } = useContext(CookiesModalContext);
  const { language } = useContext(LanguageContext);
  const site = useContext(SiteContext);
  const { name: siteName } = site;

  const classes = useStyles();

  const getCookieConsent = useCallback(() => {
    if (["false", false].includes(cookieConsent)) {
      setEulerianConsent("Refuser");
    } else {
      setEulerianConsent("Accepter");
    }
  }, [cookieConsent]);

  useEffect(() => {
    getCookieConsent();
  }, [getCookieConsent]);

  const handleConfirm = () => {
    if (eulerianConsent === "Accepter") {
      handleValidateClick();
    } else {
      handleRefuseClick();
    }
    handleCookiesModal();
  };

  const handleClose = () => {
    getCookieConsent();
    handleCookiesModal();
  };

  const createData = (name, finality, conservation) => {
    return { name, finality, conservation };
  };

  const requiredCookiesRows = [
    createData("Local Storage", t[language].cookies.storage_description),
    createData("Session", t[language].cookies.session_description),
  ];

  const eulerianCookiesRows = [createData("Eulerian", `${t[language].cookies.use_cookie} (etuix, eulerian)`)];

  const host = (typeof window !== "undefined" && `${t[language].cookies.host_prefix} ${window.location?.host}`) || "";

  return (
    <DSFRModal
      open={openCookiesModal}
      aria-labelledby="admin-content"
      aria-describedby="content-administration"
      onClickAway={handleClose}
      onClose={handleConfirm}
      size={{ md: 10, lg: 9 }}
    >
      <div className="fr-modal__content">
        <h1 id="fr-modal-title-modal-cookies" className="fr-modal__title">
          {t[language].cookies.title} {host}
        </h1>
        <p className="fr-text--md" role="presentation">
          {t[language].cookies.modal_description_1}
        </p>
        <p className="fr-text--md" role="presentation">
          {t[language].cookies.modal_description_2}
        </p>
        <hr style={{ marginTop: "16px" }} />
        <CookiesTable
          cookiesRows={requiredCookiesRows}
          disabled
          subtitle={t[language].cookies.required_title}
          description={
            <p className={`fr-text--sm fr-consent-service__desc ${classes.description}`} role="presentation">
              {t[language].cookies.required_description}
            </p>
          }
          {...others}
        />
        <div style={{ height: "24px" }} />
        <CookiesTable
          cookiesRows={eulerianCookiesRows}
          consent={eulerianConsent}
          setConsent={setEulerianConsent}
          description={
            <div className={classes.description}>
              <p className="fr-text--sm fr-consent-service__desc" role="presentation">
                {`${t[language].cookies.tracker_description_1} ${
                  typeof window !== "undefined" ? window.location.host : getSiteName(siteName)
                } ${t[language].cookies.tracker_description_2}.`}
              </p>
              <Link linkComponent external className="fr-link fr-link--sm" url="https://www.eulerian.com/vie-privee">
                {t[language].cookies.tracker_link}
              </Link>
            </div>
          }
          subtitle={t[language].cookies.tracker_title}
          classes={classes}
          {...others}
        />
        <hr />
        <p className="fr-text--sm" role="presentation" style={{ margin: "8px 0 32px" }}>
          {t[language].cookies.modal_description_3}
        </p>
        <hr />
        <ul className="fr-consent-manager__buttons fr-btns-group fr-btns-group--right fr-btns-group--inline-sm">
          <li>
            <button type="button" className="fr-btn" onClick={handleConfirm}>
              {t[language].cookies.confirm_button}
            </button>
          </li>
        </ul>
      </div>
    </DSFRModal>
  );
};

CookiesModal.propTypes = {
  cookieConsent: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  handleCookiesModal: PropTypes.func.isRequired,
  handleValidateClick: PropTypes.func.isRequired,
  handleRefuseClick: PropTypes.func.isRequired,
};

CookiesModal.defaultProps = {
  cookieConsent: null,
};

export default CookiesModal;
