import LastNewsTemplate from "components/templates/news/LastNewsTemplate";
import elementsTypes from "utils/elementsTypes";

export default {
  key: "LASTNEWS",
  label: "Dernières Actualités",
  Component: LastNewsTemplate,
  initialContents: [
    {
      key: "highlightedNews",
      type: elementsTypes.CARDS_LIST_NEWS,
      value: "",
      children: [
        {
          key: "limit",
          value: "1",
        },
      ],
    },
    {
      key: "oldCardsNews",
      type: elementsTypes.OLD_CARDS_LIST_NEWS,
      value: "",
      children: [],
    },
    {
      key: "homeButton",
      type: elementsTypes.ICON_LINK,
      value: "",
      children: [
        {
          key: "text",
          value: "Voir toute l'actualité",
        },
        {
          key: "link",
          type: elementsTypes.LINK,
          value: "",
          children: [
            {
              key: "url",
              type: elementsTypes.URL,
              value: "",
            },
            {
              key: "title",
              type: elementsTypes.INPUT,
              value: "Voir toute l'actualité",
            },
            {
              key: "external",
              type: elementsTypes.CHECKBOX,
              value: "false",
            },
          ],
        },
        {
          key: "icon",
          type: elementsTypes.ICON,
          value: "",
          children: [
            {
              key: "icon",
              type: elementsTypes.INPUT,
              value: "arrow-right",
            },
          ],
        },
      ],
    },
  ],
};
