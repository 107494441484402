import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Block from "components/templatesComponents/Block";
import SubPageContext from "components/templatesComponents/SubPageContext";
import Separator from "components/templatesComponents/Separator";
import { makeStyles } from "@material-ui/styles";
import HomeServices from "components/templates/homeProcessSites/HomeServices";
import HomeProcesses from "components/templates/homeProcessSites/HomeProcesses";
import HomeLastNews from "components/templates/home/HomeLastNews";
import pageLinks from "components/templates/pageLinks/pageLinks";
import processes from "components/templates/processes/processes";
import Wrapper from "components/LayoutFront/Wrapper";
import lastNews from "components/templates/news/lastNews";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
}));

const getSubTemplate = (k) => {
  switch (k) {
    case pageLinks.key:
      return HomeServices;
    case processes.key:
      return HomeProcesses;
    case lastNews.key:
      return HomeLastNews;
    default:
      return null;
  }
};

const HomeProcessSites = (props) => {
  const { page } = props;
  const { contents } = page;
  const { subpages, newsTitle, newsSubpage } = contents;
  const classes = useStyles();

  return (
    <SubPageContext.Provider value={{ getSubTemplate }}>
      {subpages}
      <Separator />
      <Block>
        <Wrapper>
          <h2 className={classnames(classes.title, "fr-h1")}>{newsTitle}</h2>
          {newsSubpage}
        </Wrapper>
      </Block>
    </SubPageContext.Provider>
  );
};

HomeProcessSites.propTypes = {
  page: PropTypes.shape(),
};

HomeProcessSites.defaultProps = {
  page: {},
};

export default HomeProcessSites;
