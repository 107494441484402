import axios from "axios";

const configs = {
  getPageInfos: (pathname) => ({
    url: "/getPageInfos",
    params: { pathname },
  }),
  findPagesByTemplateKeys: (params) => ({
    url: "/findPagesByTemplates",
    params,
  }),
  findHighlightNews: (params) => ({
    url: "/findHighlightNews",
    params,
  }),
  getTags: (params) => ({
    url: "/findTags",
    params,
  }),
  findPages: (params) => ({
    url: "/findPages",
    params,
  }),
};

class PageService {
  static getPageInfos = (pathname) => {
    return axios.get(configs.getPageInfos(pathname)).then((response) => response.data);
  };

  static getConfig(methodName, options) {
    const getter = configs[methodName];
    if (typeof getter === "function") {
      return getter(options);
    }
    return {};
  }

  static search = ({ query, lang, from, size }) => {
    return axios({
      method: "POST",
      url: "/search",
      data: { query, lang, from, size },
    }).then((response) => response.data);
  };
}

export default PageService;
