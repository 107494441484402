import React, { useContext } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Icon from "components/templatesComponents/Icon";
import Link from "components/templatesComponents/Link";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles(() => ({
  icon: {
    color: "#6A6AF4",
    fontSize: "2rem",
    "&::before": {
      "--icon-size": "2rem",
    },
    marginBottom: "10px",
  },
}));

const Quote = (props) => {
  const { icon = {}, image, quote, author, link, quoteType } = props;
  const { url: linkUrl, page: linkPage, external, title } = link || {};
  const { url: imageUrl, alt: imageAlt } = image || {};
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  const { language } = useContext(LanguageContext);

  let linkText;
  switch (quoteType) {
    case "basic":
      linkText = t[language].components.article_link;
      break;
    case "tweet":
      linkText = t[language].components.tweet_link;
      break;
    default:
      linkText = "";
  }

  return (
    <figure className="fr-quote fr-quote--column" style={{ marginLeft: (!imageUrl || quoteType === "tweet") && 0 }}>
      {(icon?.icon || icon?.iconDSFR) && <Icon {...icon} className={classes.icon} />}
      <blockquote cite={linkUrl} style={{ width: "100%" }}>
        {quote}
      </blockquote>
      {quoteType === "tweet" && (
        <img src={imageUrl} className="fr-responsive-img" alt={imageAlt} style={{ marginTop: "8px" }} />
      )}
      <figcaption style={{ marginTop: "16px" }}>
        <Grid
          container
          direction="column"
          className="fr-quote__author"
          style={{ marginLeft: (!imageUrl || quoteType === "tweet") && isMobile && 0 }}
        >
          {author}
          {(linkUrl || linkPage) && (
            <Grid item xs>
              <Link
                url={linkUrl}
                page={linkPage}
                external={external}
                iconPosition="right"
                icon={{ icon: "long-arrow-right", iconDSFR: "arrow-right-line" }}
                linkComponent
              >
                {title || linkText}
              </Link>
            </Grid>
          )}
        </Grid>
        {imageUrl && quoteType === "basic" && (
          <div className="fr-quote__image">
            <img src={imageUrl} className="fr-responsive-img" alt={imageAlt} />
          </div>
        )}
      </figcaption>
    </figure>
  );
};

Quote.propTypes = {
  icon: PropTypes.shape(),
  image: PropTypes.shape(),
  quote: PropTypes.shape(),
  author: PropTypes.string,
  link: PropTypes.shape(),
  quoteType: PropTypes.oneOf(["basic", "tweet"]).isRequired,
};

Quote.defaultProps = {
  icon: null,
  image: null,
  quote: null,
  author: "",
  link: null,
};

export default Quote;
