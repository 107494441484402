import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginBottom: 0,
    },
  },
}));

const PageTitle = (props) => {
  const { children, ...others } = props;
  const classes = useStyles();

  return (
    <h1 className={classes.title} {...others}>
      {children}
    </h1>
  );
};

PageTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  style: PropTypes.shape(),
};

PageTitle.defaultProps = {
  children: null,
  style: {},
};

export default PageTitle;
