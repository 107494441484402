import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Wrapper from "components/LayoutFront/Wrapper";
import NewsTabs from "components/templates/news/NewsTabs";
import LastNewsBlock from "components/templatesComponents/LastNewsBlock";
import CardHighlightedContext from "components/CardHighlightedContext";
import CardContext from "components/templatesComponents/CardContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 2),
    },
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
}));

const LastNewsTemplate = (props) => {
  const { page: pageVersion } = props;
  const { title, contents, page } = pageVersion;
  const { highlightedNews, oldCardsNews = {}, homeButton = {} } = contents;
  const { lang } = page;

  const { props: oldCardsNewsProps = {} } = oldCardsNews;
  const { cards: haveCards, children: isOnBackOffice } = oldCardsNewsProps;

  const { props: homeButtonProps = {} } = homeButton;
  const { children: homeButtonChildren, page: homeButtonPage, url: homeButtonUrl } = homeButtonProps;
  const buttonOnBackOffice = typeof homeButtonChildren === "object";

  const displaySubtitle = haveCards || isOnBackOffice || buttonOnBackOffice || homeButtonPage || homeButtonUrl;

  const displayCards = haveCards || isOnBackOffice;

  const displayButton = buttonOnBackOffice || (!haveCards && (homeButtonPage || homeButtonUrl));

  const classes = useStyles();

  return (
    <Wrapper breakpoint="xl" className={classes.wrapper}>
      <h1>{title}</h1>
      <NewsTabs currentPageVersion={pageVersion} />
      <CardHighlightedContext.Provider value={{ isHorizontal: true }}>
        <LastNewsBlock highlightedNews={highlightedNews} lang={lang} />
      </CardHighlightedContext.Provider>
      {displaySubtitle && (
        <p className={`fr-text--xl fr-text--bold ${classes.subtitle}`} role="presentation">
          {t[lang].last_news.tile_cards_title}
        </p>
      )}
      {displayCards && (
        <Grid container spacing={2}>
          <CardContext.Provider
            value={{
              Wrapper: Grid,
              wrapperProps: {
                item: true,
                xs: 12,
                md: 6,
                className: classes.cardWrapper,
              },
            }}
          >
            {oldCardsNews}
          </CardContext.Provider>
        </Grid>
      )}
      <Grid container alignItems="center" spacing={2}>
        {displayButton && (
          <Grid item>
            <button type="button" className="fr-btn fr-btn--tertiary">
              {homeButton}
            </button>
          </Grid>
        )}
        {buttonOnBackOffice && (
          <Grid item>
            <i>(Bouton également utilisé sur la page d&apos;Accueil)</i>
          </Grid>
        )}
      </Grid>
    </Wrapper>
  );
};

LastNewsTemplate.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.node,
    shortDescription: PropTypes.node,
    contents: PropTypes.shape(),
    page: PropTypes.shape(),
  }).isRequired,
};

export default LastNewsTemplate;
