import axios from "axios";

class BannerService {
  static getBanners = (params) => {
    return axios.get("/getBanners", params).then((response) => {
      return response.data || [];
    });
  };
}

export default BannerService;
