import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "components/templatesComponents/Hidden";
import { useTheme } from "@material-ui/core/styles";
import Block from "components/templatesComponents/Block";
import Button from "components/templatesComponents/Button";
import { styleKeys } from "components/templatesComponents/card/cardStyles";
import CardContext from "components/templatesComponents/CardContext";
import { ImageContainer } from "components/templatesComponents/Image";
import Link from "components/templatesComponents/Link";
import Accordion from "components/templatesComponents/Accordion";
import Separator from "components/templatesComponents/Separator";
import Wrapper from "components/LayoutFront/Wrapper";
import EulerianService from "services/EulerianService";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      fontSize: "1.75rem",
      lineHeight: "2.13rem",
    },
  },
  description: {
    padding: theme.spacing(6, 0, 3, 0),
  },
  image: {
    flex: "1 1 0px",
  },
  processes: {
    marginTop: theme.spacing(3),
  },
  imageWrapper: {
    paddingTop: theme.spacing(9.5),
    display: "flex",
  },
  top: {
    paddingTop: "276px",
    width: "32px",
    backgroundColor: theme.palette.secondary[20],
  },
  bottom: {
    paddingTop: "84px",
    width: "32px",
    backgroundColor: theme.palette.secondary.main,
  },
}));

const cardMobileStyle = {
  root: {
    height: 62,
  },
};

const HomeProcesses = (props) => {
  const { page } = props;
  const { title, image, shortDescription, contents } = page;
  const { cardsGroups, cardZero, cardOne, cardTwo, cardThree, cardFour, otherCards } = contents;
  const { id, url, external } = cardZero.props.link;

  const { language } = useContext(LanguageContext);

  let { groups = [] } = cardsGroups || {};

  if (groups && !Array.isArray(groups)) {
    groups = [groups];
  }

  const classes = useStyles();
  const theme = useTheme();

  const panels = groups.map((group) => ({
    id: group.id,
    summary: group.name,
    details: group.contents,
  }));

  const handleClickCardZero = () => {
    EulerianService.sendClick({ button: [`button::click::suivre votre demarche en ligne`] });
  };

  return (
    <Block className={classes.root}>
      <Wrapper>
        <Grid container className={classes.processes}>
          <Grid item container direction="column" lg={8}>
            <h1 className={classes.title}>{title}</h1>
            <Hidden mdDown>
              <p className={classes.description} role="presentation">
                {shortDescription || t[language].home_processes.default_description}
              </p>
              <Button
                icon={{ icon: "long-arrow-right", iconDSFR: "arrow-right-line" }}
                iconPosition="right"
                component={Link}
                linkComponent
                id={id}
                url={url}
                size="lg"
                aria-label={cardZero.props.title}
                external={external}
                onClick={handleClickCardZero}
              >
                {cardZero.props.title}
              </Button>
            </Hidden>
            <Hidden mdDown>
              {image && (
                <div className={classes.imageWrapper}>
                  <div className={classes.image}>
                    <ImageContainer ratio={43}>{image}</ImageContainer>
                  </div>
                  <div className={classes.cigarette}>
                    <div className={classes.top} />
                    <div className={classes.bottom} />
                  </div>
                </div>
              )}
            </Hidden>
          </Grid>
          <Grid item lg={4} xs={12}>
            <Hidden lgUp>
              <Box mb={1}>
                <CardContext.Provider
                  value={{
                    desktopStyleKey: styleKeys.HORIZONTAL_COLOR,
                    mobileStyleKey: styleKeys.HORIZONTAL_COLOR,
                    mobileStyleOverride: cardMobileStyle,
                  }}
                >
                  {cardZero}
                </CardContext.Provider>
              </Box>
            </Hidden>
            {(!panels || panels.length === 0) && (
              <Box mb={1}>
                <Grid container spacing={1} direction="column">
                  <CardContext.Provider
                    value={{
                      desktopStyleKey: styleKeys.HORIZONTAL_BIG,
                      mobileStyleKey: styleKeys.HORIZONTAL,
                      desktopStyleOverride: {
                        root: {
                          backgroundColor: theme.palette.primary.tones[0],
                          "a&:hover": {
                            backgroundColor: theme.palette.primary.tones[0],
                          },
                        },
                      },
                      mobileStyleOverride: cardMobileStyle,
                      Wrapper: Grid,
                      wrapperProps: {
                        item: true,
                      },
                    }}
                  >
                    {cardOne}
                  </CardContext.Provider>
                  <CardContext.Provider
                    value={{
                      desktopStyleKey: styleKeys.HORIZONTAL_BIG,
                      mobileStyleKey: styleKeys.HORIZONTAL,
                      desktopStyleOverride: {
                        root: {
                          backgroundColor: theme.palette.primary.tones[1],
                          "a&:hover": {
                            backgroundColor: theme.palette.primary.tones[1],
                          },
                        },
                      },
                      mobileStyleOverride: cardMobileStyle,
                      Wrapper: Grid,
                      wrapperProps: {
                        item: true,
                      },
                    }}
                  >
                    {cardTwo}
                  </CardContext.Provider>
                  <CardContext.Provider
                    value={{
                      desktopStyleKey: styleKeys.HORIZONTAL_BIG,
                      mobileStyleKey: styleKeys.HORIZONTAL,
                      desktopStyleOverride: {
                        root: {
                          backgroundColor: theme.palette.primary.tones[2],
                          "a&:hover": {
                            backgroundColor: theme.palette.primary.tones[2],
                          },
                        },
                      },
                      mobileStyleOverride: cardMobileStyle,
                      Wrapper: Grid,
                      wrapperProps: {
                        item: true,
                      },
                    }}
                  >
                    {cardThree}
                  </CardContext.Provider>
                  <CardContext.Provider
                    value={{
                      desktopStyleKey: styleKeys.HORIZONTAL_BIG,
                      mobileStyleKey: styleKeys.HORIZONTAL,
                      desktopStyleOverride: {
                        root: {
                          backgroundColor: theme.palette.primary.tones[0],
                          "a&:hover": {
                            backgroundColor: theme.palette.primary.tones[0],
                          },
                        },
                      },
                      mobileStyleOverride: cardMobileStyle,
                      Wrapper: Grid,
                      wrapperProps: {
                        item: true,
                      },
                    }}
                  >
                    {cardFour}
                  </CardContext.Provider>
                  <CardContext.Provider
                    value={{
                      desktopStyleKey: styleKeys.HORIZONTAL_BIG,
                      mobileStyleKey: styleKeys.HORIZONTAL,
                      mobileStyleOverride: cardMobileStyle,
                      Wrapper: Grid,
                      wrapperProps: {
                        item: true,
                      },
                      camaieuStart: 1,
                    }}
                  >
                    {otherCards}
                  </CardContext.Provider>
                </Grid>
              </Box>
            )}
            {panels && panels.length > 0 && (
              <CardContext.Provider
                value={{
                  desktopStyleKey: styleKeys.HORIZONTAL_BIG,
                  mobileStyleKey: styleKeys.HORIZONTAL,
                  Wrapper: Box,
                  wrapperProps: {
                    role: "listitem",
                  },
                  desktopStyleOverride: {
                    root: {
                      backgroundColor: "#ffffff",
                      border: `1px solid ${theme.palette.componentColors[30]}`,
                      minHeight: 0,
                      "a&:hover": {
                        backgroundColor: theme.palette.componentColors[30],
                      },
                    },
                    title: {
                      fontSize: "inherit",
                      color: theme.palette.getContrastText("#ffffff"),
                    },
                    content: {
                      padding: theme.spacing(3, 2, 3, 3),
                    },
                    arrow: {
                      color: theme.palette.getContrastText("#ffffff"),
                    },
                  },
                  camaieuStart: 1,
                }}
              >
                <Accordion panels={panels} noPadding expansionPanelRole="list" />
              </CardContext.Provider>
            )}
            <Button
              seeAll
              fullWidth
              component={Link}
              linkComponent
              page={page}
              aria-label={t[language].home_processes.button_label}
            >
              {t[language].home_processes.button_label}
            </Button>
          </Grid>
        </Grid>
      </Wrapper>
      <Separator />
    </Block>
  );
};

HomeProcesses.propTypes = {
  page: PropTypes.shape(),
};

HomeProcesses.defaultProps = {
  page: {},
};

export default HomeProcesses;
