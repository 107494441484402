import React from "react";
import PropTypes from "prop-types";
import Button from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import Link from "components/templatesComponents/Link";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    "&::before ": {
      fontSize: "1.5rem",
      position: "absolute",
      top: "15px",
      left: "15px",
      [theme.breakpoints.down("md")]: {
        left: "20px",
      },
    },
  },
}));

const InfoBlock = (props) => {
  const { icon, title, text, link } = props;
  const { icon: fontIcon, iconDSFR } = icon;
  const { title: titleLink, page, url } = link;

  const classes = useStyles();

  return (
    <div
      className={`fr-callout ${iconDSFR ? `fr-icon-${iconDSFR}` : ""}`}
      style={{
        boxShadow: "inset .25rem 0 0 0 #6A6AF4",
        backgroundColor: "#EEEEEE",
      }}
    >
      {fontIcon && !iconDSFR && <Icon icon={fontIcon} className={classes.icon} />}
      <h3 className="fr-callout__title">{title}</h3>
      <div className="fr-callout__text">{text}</div>
      {(url || page?.path) && (
        <Button component={Link} {...link} linkComponent style={{ color: "#FFFFFF", backgroundColor: "#000091" }}>
          {titleLink}
        </Button>
      )}
    </div>
  );
};

InfoBlock.propTypes = {
  icon: PropTypes.shape(),
  title: PropTypes.string,
  text: PropTypes.node,
  link: PropTypes.shape(),
};

InfoBlock.defaultProps = {
  icon: {},
  title: "",
  text: null,
  link: {},
};

export default InfoBlock;
