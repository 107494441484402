import React, { useContext } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import SearchTagsBlock from "components/templates/news/SearchTagsBlock";
import ExpansionPanel from "components/templatesComponents/ExpansionPanel";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const AllNewsFilters = (props) => {
  const {
    initialsTags,
    profilTags,
    thematicTags,
    selectedProfilTags,
    selectedTagsForm,
    startDate,
    endDate,
    validStartDate,
    validEndDate,
    handleSelectProfilTags,
    handleSelectTagsForm,
    handleChangeDate,
    siteNameString,
    usedSiteName,
    lang,
  } = props;

  const { language } = useContext(LanguageContext);

  const today = new Date(Date.now())?.toISOString()?.split("T")[0];

  return (
    <div className="fr-accordions-group">
      {Array.isArray(profilTags) && profilTags.length > 0 && (
        <ExpansionPanel
          id="profilFilter"
          summary={t[language].all_news.profil_filter_title}
          tag
          initialExpanded={selectedProfilTags?.length > 0}
          noPadding
        >
          <SearchTagsBlock
            currentSiteName={siteNameString}
            selectedTags={selectedProfilTags}
            handleSelectTagsForm={handleSelectProfilTags()}
            profil
            hiddenTitle
            lang={lang}
          />
        </ExpansionPanel>
      )}
      {Array.isArray(thematicTags) && thematicTags.length > 0 && (
        <ExpansionPanel
          id="thematicFilter"
          summary={t[language].all_news.theme_filter_title}
          tag
          initialExpanded={selectedTagsForm[usedSiteName]?.length > 0}
          noPadding
        >
          <>
            <SearchTagsBlock
              currentSiteName={usedSiteName}
              selectedTags={selectedTagsForm[usedSiteName]}
              handleSelectTagsForm={handleSelectTagsForm(usedSiteName)}
              lang={lang}
              hiddenTitle
            />
            {siteNameString === "allSites" &&
              Array.isArray(Object.keys(initialsTags)) &&
              Object.keys(initialsTags)
                .filter((init) => init !== usedSiteName)
                .map((name) => (
                  <Box key={name}>
                    <SearchTagsBlock
                      currentSiteName={name}
                      selectedTags={selectedTagsForm[name]}
                      handleSelectTagsForm={handleSelectTagsForm(name)}
                      lang={lang}
                    />
                  </Box>
                ))}
          </>
        </ExpansionPanel>
      )}
      <ExpansionPanel id="dateFilter" summary={t[language].all_news.date_filter_title} noPadding>
        <div className="fr-input-group">
          <label className="fr-label" htmlFor="startDate">
            {t[language].all_news.start_date_label}
          </label>
          <input
            className="fr-input"
            aria-describedby="text-input-start-date"
            type="date"
            id="startDate"
            name="startDate"
            min="2019-01-01"
            max={validEndDate ? endDate : today}
            value={startDate}
            onChange={handleChangeDate}
          />
          <div className="fr-messages-group" id="text-input-date-messages" aria-live="assertive" />
        </div>
        <div className="fr-input-group">
          <label className="fr-label" htmlFor="endDate">
            {t[language].all_news.end_date_label}
          </label>
          <input
            className="fr-input"
            aria-describedby="text-input-end-date"
            type="date"
            id="endDate"
            name="endDate"
            min={validStartDate ? startDate : "2019-01-01"}
            max={today}
            value={endDate}
            onChange={handleChangeDate}
          />
          <div className="fr-messages-group" id="text-input-date-messages" aria-live="assertive" />
        </div>
      </ExpansionPanel>
    </div>
  );
};

AllNewsFilters.propTypes = {
  initialsTags: PropTypes.shape().isRequired,
  profilTags: PropTypes.arrayOf(PropTypes.string),
  thematicTags: PropTypes.arrayOf(PropTypes.string),
  selectedProfilTags: PropTypes.arrayOf(PropTypes.string),
  selectedTagsForm: PropTypes.shape().isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  validStartDate: PropTypes.string.isRequired,
  validEndDate: PropTypes.string.isRequired,
  handleSelectProfilTags: PropTypes.func.isRequired,
  handleSelectTagsForm: PropTypes.func.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  siteNameString: PropTypes.string.isRequired,
  usedSiteName: PropTypes.string.isRequired,
  lang: PropTypes.string,
};

AllNewsFilters.defaultProps = {
  profilTags: [],
  thematicTags: [],
  selectedProfilTags: [],
  lang: "FR",
};

export default AllNewsFilters;
