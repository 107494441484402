import React, { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import MUISnackbar from "@material-ui/core/Snackbar";
import MuiSnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";
import t from "utils/locales/translation.json";
import LanguageContext from "components/LanguageContext";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
    "& > div > button:hover": {
      color: green[600],
    },
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    "& > div > button:hover": {
      color: theme.palette.error.dark,
    },
  },
  info: {
    backgroundColor: theme.palette.primary.main,
    "& > div > button:hover": {
      color: theme.palette.primary.main,
    },
  },
  warning: {
    backgroundColor: amber[700],
    "& > div > button:hover": {
      color: amber[700],
    },
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

function SnackbarContent(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];
  const { language } = useContext(LanguageContext);

  return (
    <MuiSnackbarContent
      className={classnames(classes[variant], className)}
      message={
        <span className={classes.message}>
          <Icon className={classnames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label={t[language].common.close} color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackbarContent.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["error", "info", "success", "warning"]),
};

SnackbarContent.defaultProps = {
  className: "",
  message: "",
  variant: "success",
};

export default function Snackbar(props) {
  const { open, onClose, message, variant, duration, anchorOrigin } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    onClose(event, reason);
  };

  return (
    <MUISnackbar anchorOrigin={anchorOrigin} open={open} autoHideDuration={duration} onClose={handleClose}>
      <SnackbarContent onClose={handleClose} variant={variant} message={message} />
    </MUISnackbar>
  );
}

Snackbar.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["error", "info", "success", "warning"]),
  duration: PropTypes.number,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(["top", "bottom"]).isRequired,
    horizontal: PropTypes.oneOf(["left", "center", "right"]).isRequired,
  }),
};

Snackbar.defaultProps = {
  open: false,
  message: "",
  variant: "success",
  duration: 3000000,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
