import React, { useContext, useEffect } from "react";
import Icon from "components/templatesComponents/Icon";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  scroller: {
    color: theme.palette.primary[40],
    position: "absolute",
    top: "500px",
    margin: "0 auto",
    maxWidth: "1400px",
    width: "100%",
    pointerEvents: "none",
    left: 0,
    fontSize: "28px",
    right: 0,
    paddingRight: "70px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    zIndex: "250",
    boxSizing: "border-box",
    visibility: "hidden",
    "& span::before": {
      "--icon-size": "3rem",
    },
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
    "@media print": {
      display: "none",
    },
  },
}));

const ScrollIndicator = (props) => {
  const { scrollableClass } = props;
  const classes = useStyles();
  const { language } = useContext(LanguageContext);

  // Vérifie si la page est assez grande pour afficher le scroller
  if (typeof document !== "undefined" && scrollableClass) {
    const page = document.getElementsByClassName(scrollableClass)[0];
    const scrollElement = document.getElementById("ScrollIndicator");
    if (scrollElement !== null) {
      if (page && page.scrollHeight > page.clientHeight + 326 && page.scrollTop < 500) {
        scrollElement.style.visibility = "visible";
        scrollElement.style.display = "flex";
      } else {
        scrollElement.style.visibility = "hidden";
        scrollElement.style.display = "none";
      }
    }
  }

  const listener = () => {
    if (typeof document !== "undefined") {
      const page = document.getElementsByClassName(scrollableClass)[0];
      const maxScrollTop = page.scrollHeight - page.offsetHeight;
      if ((page && page.scrollTop > 500) || page.scrollTop === maxScrollTop) {
        document.getElementById("ScrollIndicator").style.display = "none";
      }
    }
  };

  useEffect(() => {
    if (typeof document !== "undefined") {
      const page = document.getElementsByClassName(scrollableClass)[0];
      if (page) {
        page.addEventListener("scroll", listener);
        return () => {
          page.removeEventListener("scroll", listener);
        };
      }
    }
    return null;
  });

  return (
    <div id="ScrollIndicator" className={classes.scroller}>
      <Icon icon="chevron-double-down" iconDSFR="arrow-down-s-line" title={t[language].components.scroll_icon_title} />
      <Icon
        iconDSFR="arrow-down-s-line"
        title={t[language].components.scroll_icon_title}
        style={{ marginTop: "-35px" }}
      />
    </div>
  );
};

ScrollIndicator.propTypes = {
  scrollableClass: PropTypes.string.isRequired,
};

export default ScrollIndicator;
