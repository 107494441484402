import AllNewsTemplate from "components/templates/news/AllNewsTemplate";
import types from "utils/elementsTypes";

export default {
  key: "ALLNEWS",
  label: "Toute l'actualité",
  Component: AllNewsTemplate,
  initialContents: [
    {
      key: "siteName",
      value: "allSites",
      type: types.SITE_NAME,
    },
  ],
};
