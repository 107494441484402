import { createTheme } from "@material-ui/core/styles";
import { amber } from "@material-ui/core/colors";

const defaultMuiTheme = createTheme();

export default {
  palette: {
    warning: {
      ...amber,
      light: amber[200],
      main: amber[500],
      dark: amber[700],
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: ['"Marianne"', "sans-serif"].join(","),
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "0",
        fontSize: undefined,
        lineHeight: "22px",
        textTransform: "none",
        "& a": {
          textDecoration: "none",
        },
        "&:focus": { outline: "2px solid #0a76f6" },
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      },
      containedSizeLarge: {
        padding: "9px 22px",
      },
    },
    MuiTouchRipple: {
      root: {
        display: "none",
      },
    },
    MuiExpansionPanel: {
      root: {
        boxShadow: "none",
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        fontSize: "1.3rem",
        padding: "0",
        minHeight: "auto",
        "&$expanded": {
          minHeight: "auto",
        },
        "@media print": {
          border: "1px solid #8B8A8B",
        },
      },
      content: {
        marginLeft: defaultMuiTheme.spacing(4),
        "&$expanded": {
          marginLeft: defaultMuiTheme.spacing(4),
        },
        "@media print": {
          "& p": {
            fontSize: "1.5rem !important",
            color: "#353535",
            fontWeight: "bold !important",
          },
        },
      },
      expandIcon: {
        borderRadius: "0",
        padding: defaultMuiTheme.spacing(2),
        "&:hover": {},
        "&$expanded": {
          transform: "none",
          transition: "none",
        },
        "& .MuiIconButton-label": {
          transform: "rotate(0deg)",
          transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&$expanded .MuiIconButton-label": {
          transform: "rotate(180deg)",
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        flexDirection: "column",
        border: "solid #cecece",
        borderWidth: "0 1px 1px",
        padding: defaultMuiTheme.spacing(2.5, 2),
        [defaultMuiTheme.breakpoints.up("lg")]: {
          padding: defaultMuiTheme.spacing(2.5, 4),
        },
        "@media print": {
          display: "block !important",
        },
      },
    },
    MuiCollapse: {
      container: {
        "@media print": {
          height: "auto !important",
          visibility: "visible !important",
        },
      },
      wrapper: {
        "@media print": {
          display: "block !important",
        },
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "0.875rem",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "0.875rem",
      },
    },
    MuiTab: {
      root: {
        "&$selected": {
          fontWeight: "bold",
        },
      },
    },
    MuiMenuItem: {
      root: {
        whiteSpace: "normal",
      },
    },
    MuiSelect: {
      selectMenu: {
        whiteSpace: "normal",
      },
    },
  },
};
