import React, { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const Icon = (props) => {
  const { icon, type, iconDSFR, groupDSFR, className, isHidden, ...others } = props;
  const { language } = useContext(LanguageContext);

  if (icon === "ants-permis-noir") {
    return (
      <img
        {...others}
        className={className}
        src="/assets-icons-icon-permis-black.svg"
        alt={t[language].components.icon_black}
        aria-hidden={isHidden}
      />
    );
  }
  if (icon === "ants-permis-blanc") {
    return (
      <img
        {...others}
        className={className}
        src="/assets-icons-icon-permis-white.svg"
        alt={t[language].components.icon_white}
        aria-hidden={isHidden}
      />
    );
  }
  if (iconDSFR) {
    return <span {...others} className={classnames(className, `fr-icon-${iconDSFR}`)} aria-hidden={isHidden} />;
  }
  return <i title={icon} {...others} className={classnames(className, `${type} fa-${icon}`)} aria-hidden={isHidden} />;
};

Icon.propTypes = {
  icon: PropTypes.string,
  type: PropTypes.oneOf(["fas", "far", "fal", "fad", "fab"]),
  iconDSFR: PropTypes.string,
  groupDSFR: PropTypes.string,
  className: PropTypes.string,
  isHidden: PropTypes.bool,
};

Icon.defaultProps = {
  icon: "",
  type: "fal",
  iconDSFR: "",
  groupDSFR: "",
  className: null,
  isHidden: true,
};

export default Icon;
