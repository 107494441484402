import React, { useContext } from "react";
import PropTypes from "prop-types";
import CardContext from "components/templatesComponents/CardContext";
import { styleKeys } from "components/templatesComponents/card/cardStyles";

const CardList = (props) => {
  const { desktopStyleKey, mobileStyleKey, cards } = props;

  const currentCardContext = useContext(CardContext);

  if (!cards || cards.length === 0) {
    return null;
  }

  const newContextValue = {
    desktopStyleKey,
    mobileStyleKey,
    ...currentCardContext,
  };

  return <CardContext.Provider value={newContextValue}>{cards}</CardContext.Provider>;
};

CardList.propTypes = {
  desktopStyleKey: PropTypes.string,
  mobileStyleKey: PropTypes.string,
  cards: PropTypes.node,
};

CardList.defaultProps = {
  desktopStyleKey: styleKeys.LIGHT_BIG,
  mobileStyleKey: styleKeys.LITTLE_LIGHT,
  cards: null,
};

export default CardList;
