import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  side: {
    flex: "1",
  },
  main: ({ breakpoint }) => ({
    maxWidth: `${theme.breakpoints.values[breakpoint]}px`,
    width: "100%",
  }),
  wrapper: {
    "@media screen ": {
      display: "flex",
    },
  },
}));

const Wrapper = (props) => {
  const { children, className, wrapperProps, leftProps, rightProps, breakpoint, ...others } = props;
  const { className: leftClass, ...left } = leftProps;
  const { className: rightClass, ...right } = rightProps;
  const { className: wrapperClass } = wrapperProps;
  const classes = useStyles({ breakpoint });

  return (
    <div className={classnames(classes.wrapper, wrapperClass)}>
      <div className={classnames(classes.side, leftClass)} {...left} />
      <div className={classnames(classes.main, className)} {...others}>
        {children}
      </div>
      <div className={classnames(classes.side, rightClass)} {...right} />
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  leftProps: PropTypes.shape(),
  wrapperProps: PropTypes.shape(),
  rightProps: PropTypes.shape(),
  breakpoint: PropTypes.string,
};

Wrapper.defaultProps = {
  children: null,
  className: null,
  leftProps: {},
  rightProps: {},
  wrapperProps: {},
  breakpoint: "lg",
};

export default Wrapper;
