import axios from "axios";

class CaptchaService {
  static getAntibotInfo = async () => {
    const ip = await axios.get("https://api.ipify.org?format=json").then((r) => r.data.ip);
    return axios.get("/captcha/antibotInfo", { params: { ip } }).then((response) => response.data);
  };
}

export default CaptchaService;
