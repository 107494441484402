import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import Wrapper from "components/LayoutFront/Wrapper";
import immatTheme from "apps/front-office/client/themes/immatTheme";
import permisTheme from "apps/front-office/client/themes/permisTheme";
import passeportTheme from "apps/front-office/client/themes/passeportTheme";
import { ImageContainer } from "components/templatesComponents/Image";
import { styleKeys } from "components/templatesComponents/card/cardStyles";
import CardContext from "components/templatesComponents/CardContext";
import Block from "components/templatesComponents/Block";
import HelpForm from "components/HelpForm";

const useStyles = makeStyles((theme) => ({
  siteLinksContainer: {
    width: "100%",
    margin: 0,
    marginBottom: theme.spacing(3),
    "& > div": {
      padding: theme.spacing(0.5, 0),
    },
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% + ${theme.spacing(2)}px)`,
      margin: -theme.spacing(1),
      flexDirection: "row",
      marginBottom: theme.spacing(10),
      "& > div": {
        padding: theme.spacing(1),
      },
    },
  },
  firstContainer: {
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row-reverse",
    },
  },
  textAlign: {
    textAlign: "center",
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      textAlign: "start",
    },
  },
  secondContainer: {
    [theme.breakpoints.up("lg")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  displayMobile: {
    position: "absolute",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  displayDesktop: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      display: "inherit",
    },
  },
  hiddenDesktop: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  marginMobile: {
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(5, 2, 0),
    },
  },
  title: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      margin: "1.5rem 0",
    },
    "@media print": {
      display: "none",
    },
  },
}));

const Contact = (props) => {
  const { page } = props;

  const { image, contents } = page;

  const { contentTitle, subtitle, cardImmat, cardPermis, cardPasseport } = contents;

  const theme = useTheme();
  const classes = useStyles();

  return (
    <Fragment>
      <Wrapper>
        <Grid container justifyContent="space-between" className={classes.firstContainer}>
          {image && (
            <Grid item lg={5} container alignItems="center" justifyContent="center" className={classes.textAlign}>
              <Grid item className={classes.hiddenDesktop}>
                <ImageContainer ratio={46}>{image}</ImageContainer>
              </Grid>

              <Grid item className={classes.displayDesktop}>
                <ImageContainer ratio={89}>{image}</ImageContainer>
              </Grid>

              <Grid item xs={10} className={classes.displayMobile}>
                <h1 className={classes.title} style={{ color: "white" }}>
                  {contentTitle}
                </h1>
              </Grid>
            </Grid>
          )}
          <Grid item lg={image ? 6 : 12} container className={classes.secondContainer}>
            <Grid item className={classes.displayDesktop}>
              <Block>
                <h1 className={classes.title}>{contentTitle}</h1>
              </Block>
            </Grid>
            <Grid item className={classes.marginMobile}>
              <h2>{subtitle}</h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.siteLinksContainer}>
          <Grid item md>
            <CardContext.Provider
              value={{
                desktopStyleKey: styleKeys.VERTICAL_BIG_PROCESS_SITES,
                mobileStyleKey: styleKeys.HORIZONTAL_BIG,
                styleOverride: {
                  root: {
                    backgroundColor: theme.palette.primary.tones[2],
                    minHeight: 72,
                    "a&:hover": {
                      backgroundColor: theme.palette.primary.tones[2],
                    },
                  },
                  arrow: {
                    backgroundColor: immatTheme.palette.secondary.main,
                    fontSize: "2rem",

                    color: theme.palette.componentColors[70],
                  },
                },
              }}
            >
              {cardImmat}
            </CardContext.Provider>
          </Grid>
          <Grid item md>
            <CardContext.Provider
              value={{
                desktopStyleKey: styleKeys.VERTICAL_BIG_PROCESS_SITES,
                mobileStyleKey: styleKeys.HORIZONTAL_BIG,
                styleOverride: {
                  root: {
                    backgroundColor: theme.palette.primary.tones[0],
                    minHeight: 72,
                    "a&:hover": {
                      backgroundColor: theme.palette.primary.tones[0],
                    },
                  },
                  arrow: {
                    backgroundColor: permisTheme.palette.secondary.main,
                    fontSize: "2rem",

                    color: theme.palette.componentColors[70],
                  },
                },
                desktopStyleOverride: {
                  icon: {
                    height: "2rem",
                  },
                },
              }}
            >
              {cardPermis}
            </CardContext.Provider>
          </Grid>
          <Grid item md>
            <CardContext.Provider
              value={{
                desktopStyleKey: styleKeys.VERTICAL_BIG_PROCESS_SITES,
                mobileStyleKey: styleKeys.HORIZONTAL_BIG,
                styleOverride: {
                  root: {
                    backgroundColor: theme.palette.primary.tones[1],
                    minHeight: 72,
                    "a&:hover": {
                      backgroundColor: theme.palette.primary.tones[1],
                    },
                  },
                  arrow: {
                    backgroundColor: passeportTheme.palette.secondary.main,
                    fontSize: "2rem",

                    color: theme.palette.componentColors[70],
                  },
                },
                desktopStyleOverride: {
                  icon: {
                    fontSize: "2rem",
                  },
                },
              }}
            >
              {cardPasseport}
            </CardContext.Provider>
          </Grid>
        </Grid>
      </Wrapper>
      <HelpForm page={page} />
    </Fragment>
  );
};

Contact.propTypes = {
  page: PropTypes.shape(),
};

Contact.defaultProps = {
  page: {},
};

export default Contact;
