import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import Page from "components/Page";
import PageVersionContext from "components/PageVersionContext";
import NotFound from "components/NotFound";

const PageSwitcher = () => {
  const { currentPageVersion, loading } = useContext(PageVersionContext);

  if (!currentPageVersion && !loading) {
    return <NotFound />;
  }
  return <Page page={currentPageVersion} />;
};

export default withRouter(PageSwitcher);
