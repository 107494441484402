import ComedecService from "services/ComedecService";

const getComedecCities = async ({ val, postalCode }) => {
  const moreResults = await ComedecService.getComedecCities({ nom: `${val}*`, postalCode });
  const { data } = moreResults;
  // eslint-disable-next-line global-require
  const XMLParser = require("react-xml-parser");
  const jsonData = new XMLParser().parseFromString(data);
  const comedecData =
    Array.isArray(jsonData.children[0].children[0]?.children) &&
    jsonData.children[0].children[0]?.children.find((c) => c.name === "communes");
  const comedecCities =
    Array.isArray(comedecData.children) &&
    comedecData.children.map((c) => {
      const comedecCity =
        Array.isArray(c.children) &&
        c.children.reduce((obj, item) => Object.assign(obj, { [item.name]: item.value }), {});
      const newCity = {
        code: comedecCity.insee,
        codeDepartement: comedecCity.departement,
      };
      if (comedecCity.ancien_nom_commune_enrichi === "") {
        return {
          nom: comedecCity.nom_commune_enrichi,
          ...newCity,
        };
      }
      return {
        nom: comedecCity.ancien_nom_commune_enrichi,
        ...newCity,
      };
    });
  return comedecCities;
};

export default getComedecCities;
