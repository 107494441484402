import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { loadCache } from "hooks/axiosCache";
import { SiteContextProvider } from "components/SiteContext";
import App from "apps/front-office/client/App";

function Main() {
  React.useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  return (
    <Router>
      <SiteContextProvider>
        <App />
      </SiteContextProvider>
    </Router>
  );
}

if (window.__AXIOS_HOOKS_CACHE__) {
  loadCache(window.__AXIOS_HOOKS_CACHE__);
  delete window.__AXIOS_HOOKS_CACHE__;
}

// You cannot use hydrate because of Onboarding
// SSR will never display Onboarding
// Onboarding will be rendered client side only x times for each user / site
ReactDOM.render(<Main />, document.querySelector("#root"));
