import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Link from "components/templatesComponents/Link";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PageService from "services/PageService";
import useAxiosCache from "hooks/axiosCache";
import { useStyles as TabsStyles } from "components/templatesComponents/Tabs";
import elementsTypes from "utils/elementsTypes";
import SiteContext from "components/SiteContext";

const getAllNewsPageSiteName = (page) =>
  page?.contents?.find((content) => content.type === elementsTypes.SITE_NAME)?.value;

const NewsTabs = (props) => {
  const { currentPageVersion } = props;
  const { page: currentPage } = currentPageVersion;
  const { lang = "FR" } = currentPage;

  const site = React.useContext(SiteContext);
  const { name: currentSiteName } = site || {};

  const [{ data: pages = [] }] = useAxiosCache(
    PageService.getConfig("findPagesByTemplateKeys", {
      templates: ["ALLNEWS", "LASTNEWS"],
      siteName: currentSiteName,
      filters: [`page.lang||eq||${lang}`],
    })
  );

  const classes = TabsStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const tabPages = pages?.pages || pages;

  React.useMemo(() => {
    if (Array.isArray(tabPages) && currentSiteName) {
      const getPageOrder = (page) => {
        switch (getAllNewsPageSiteName(page)) {
          case "allSites":
            return 0;
          case currentSiteName:
            return 1;
          case "IMMAT":
            return 2;
          case "PASSEPORT_CNI":
            return 3;
          case "PERMIS":
            return 4;
          default:
            return Infinity;
        }
      };
      tabPages.sort((pageA, pageB) => {
        if (pageB.template === pageA.template) {
          if (pageA.template === "ALLNEWS") {
            return getPageOrder(pageA) - getPageOrder(pageB);
          }
          return 0;
        }
        if (pageA.template === "ALLNEWS") {
          return 1;
        }
        return -1;
      });
    }
  }, [tabPages, currentSiteName]);

  return (
    <Fragment>
      <MuiTabs
        value={currentPageVersion?.pageId}
        variant="scrollable"
        indicatorColor="primary"
        TabIndicatorProps={{ className: classes.indicator }}
        selectionFollowsFocus
        style={{ borderBottom: "1px solid #E5E5E5", margin: isMobile ? theme.spacing(0, -2) : 0 }}
      >
        {Array.isArray(tabPages) &&
          tabPages.map((page) => (
            <MuiTab
              key={page.id}
              label={page.title}
              className={classnames(classes.tab, classes.selected)}
              value={page.pageId}
              component={Link}
              url={page.fullPath}
              page={page}
            />
          ))}
      </MuiTabs>
    </Fragment>
  );
};

NewsTabs.propTypes = {
  currentPageVersion: PropTypes.shape().isRequired,
};

export default NewsTabs;
