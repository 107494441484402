import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Icon from "components/templatesComponents/Icon";
import Grid from "@material-ui/core/Grid";
import { digitFormatDate } from "utils/commonUtils";
import useAxiosCache from "hooks/axiosCache";
import PageService from "services/PageService";

const PageDate = (props) => {
  const { page: pageVersion, position, iconColor, ...others } = props;

  const { publishedDate, updated, pageId, page } = pageVersion || {};
  const { lang, originalPageId, siteId } = page;

  const [{ data: frenchPnuPages = [] }] = useAxiosCache(
    PageService.getConfig("findPages", {
      siteId,
      pageId: lang === "FR" ? pageId : originalPageId,
      filters: [`page.lang||eq||FR`],
    })
  );

  const { publishedDate: frenchPublishedDate, updated: frenchPageUpdated } = frenchPnuPages[0] || {};

  const pageDate = publishedDate || updated;
  const frenchPageDate = frenchPublishedDate || frenchPageUpdated;
  const date = lang === "FR" ? pageDate : frenchPageDate || pageDate;

  let justify = position;
  if (position === "left") {
    justify = "flex-start";
  } else if (position === "right") {
    justify = "flex-end";
  }

  return (
    <Grid {...others} container justifyContent={justify} alignItems="center">
      <Icon icon="clock" iconDSFR="time-line" style={{ color: iconColor, fontSize: "16px" }} title="Date" />
      <Box ml={1}>
        <p className="fr-text--xs" role="presentation">
          {digitFormatDate({ date, lang })}
        </p>
      </Box>
    </Grid>
  );
};

PageDate.propTypes = {
  page: PropTypes.shape().isRequired,
  position: PropTypes.oneOf(["left", "center", "right"]),
  iconColor: PropTypes.string,
};
PageDate.defaultProps = {
  position: "center",
  iconColor: "inherit",
};

export default PageDate;
