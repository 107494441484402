import React, { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Link from "components/templatesComponents/Link";
import { makeStyles } from "@material-ui/styles";
import Hidden from "components/templatesComponents/Hidden";
import Button from "components/templatesComponents/Button";
import Separator from "components/templatesComponents/Separator";
import Wrapper from "components/LayoutFront/Wrapper";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 0,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.75rem",
    },
  },
  SubpageContainer: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  shortDescription: {
    padding: theme.spacing(4, 0),
  },
  separator: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  background: {
    position: "relative",
    "&:before": {
      content: `""`,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "100%",
      background: theme.palette.primary[10],
      zIndex: -1,
      [theme.breakpoints.up("lg")]: {
        height: "300px",
      },
    },
  },
}));

const HomeMairieSubPage = (props) => {
  const { page } = props;
  const { title, shortDescription } = page;

  const { language } = useContext(LanguageContext);

  const classes = useStyles();

  return (
    <div className={classes.SubpageContainer}>
      <Hidden implementation="js" mdDown>
        <Separator />
      </Hidden>
      <Wrapper leftProps={{ className: classes.background }} className={classes.background}>
        <Separator />
        <h2 className={classnames(classes.title, "fr-h1")}>{title}</h2>
        <div className={classes.shortDescription}>{shortDescription}</div>
        <Button
          primary
          icon={{ icon: "long-arrow-right", iconDSFR: "arrow-right-line" }}
          iconPosition="right"
          component={Link}
          linkComponent
          page={page}
          aria-label={t[language].common.know_more}
          tabIndex={0}
        >
          {t[language].common.know_more}
        </Button>
      </Wrapper>
    </div>
  );
};

HomeMairieSubPage.propTypes = {
  page: PropTypes.shape(),
};

HomeMairieSubPage.defaultProps = {
  page: {},
};

export default HomeMairieSubPage;
