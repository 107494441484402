import React from "react";
import PropTypes from "prop-types";
import EulerianService from "services/EulerianService";
import { getFileExtension, getFileNameWithoutExtension, getSize } from "utils/commonUtils";
import { normalized, getFilenameFromUrl } from "utils/urlUtils";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  focusedLink: {
    "&:focus": { outline: "2px solid #0a76f6" },
  },
}));

const Attachment = (props) => {
  const { title, linkText, file, description, link } = props;
  let { name, url } = file || {};
  const { id: fileId, size } = file || {};

  const classes = useStyles();

  const extensionFile = getFileExtension(name)?.replace(".", "") || "";

  const sendTag = () => {
    EulerianService.sendClick({ download: [`${extensionFile}::download::${title || "piece jointe"}`] });
  };

  if ((name || title) && url && (url.startsWith(process.env.ged_public_url) || url.startsWith("/files"))) {
    const filename = getFilenameFromUrl(url);
    const fileNameWithoutExtension = getFileNameWithoutExtension(filename);
    if (!name) {
      name = `${normalized(title)}${getFileExtension(filename)}`;
    }
    if (name !== getFileExtension(name)) {
      url = url.replace(filename, `${fileNameWithoutExtension}/${name}`);
    }
  } else if (!name && url) {
    name = getFilenameFromUrl(url);
  }

  return (
    <div
      className={`fr-download${!link ? " fr-enlarge-link fr-download--card" : ""}`}
      style={link ? { paddingBottom: url ? "1.5rem" : 0 } : {}}
    >
      <p>
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          download={name && name !== getFileExtension(name) ? name : fileId}
          className={classNames(classes.focusedLink, "fr-download__link")}
          onClick={sendTag}
          style={{ cursor: !link || url ? "pointer" : "initial" }}
          tabIndex={0}
        >
          {linkText}
          {(name || size) && (
            <span className="fr-download__detail">
              {extensionFile.toUpperCase()} {name && size && "– "}
              {getSize({ size, unity: 0 })}
            </span>
          )}
        </a>
      </p>
      {!link && (
        <p className="fr-download__desc" role="presentation">
          {description}
        </p>
      )}
    </div>
  );
};

Attachment.propTypes = {
  title: PropTypes.string,
  linkText: PropTypes.string,
  file: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    size: PropTypes.PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  description: PropTypes.string,
  link: PropTypes.bool,
};

Attachment.defaultProps = {
  title: "",
  linkText: "",
  file: {
    name: "",
    url: "",
    size: null,
  },
  description: "",
  link: false,
};

export default Attachment;
