import React, { useEffect, useState, useContext, forwardRef, useImperativeHandle, useRef } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";
import PropTypes from "prop-types";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";
import CaptchaService from "services/CaptchaService";

const useStyles = makeStyles((theme) => ({
  captcha: {
    width: "100%",
    margin: theme.spacing(1.5, 0),
    "& .status": {
      color: "#f44336",
    },
    "& .img > a": {
      display: "inline-block",
    },
  },
}));

const Captcha = forwardRef((props, ref) => {
  const { id, className } = props;

  const classes = useStyles();

  const [captchaAntibotInfo, setCaptchaAntibotInfo] = useState(false);
  const [captchaScriptLoaded, setCaptchaScriptLoaded] = useState(false);
  const [captchaErrorMessage, setCaptchaErrorMessage] = useState("");
  const captchaContainerRef = useRef(null);

  const { language } = useContext(LanguageContext);
  const [oldLanguage, setOldLanguage] = useState(language);

  const getCaptchaAntibotInfo = async () => {
    const captchaKey = process.env.liveidentity_key;
    const captchaURL = process.env.liveidentity_URL;
    const response = await CaptchaService.getAntibotInfo();
    window.LI_ANTIBOT.loadAntibot([
      "QUESTION",
      "AUDIO",
      language,
      captchaKey,
      `${captchaURL}/captcha`,
      null,
      "/css/captcha.css",
      response.antibotId,
      response.requestId,
      true,
    ]);
    setCaptchaAntibotInfo(true);
  };

  useEffect(() => {
    // add liveidentity script
    if (process.env.liveidentity_URL && process.env.liveidentity_key && document) {
      if (document.querySelector("#liveidentityScript")) {
        setCaptchaScriptLoaded(true);
      } else {
        const scriptTag = document.createElement("script");
        scriptTag.src = `${process.env.liveidentity_URL}/captcha/public/antibot.min.v3.js`;
        scriptTag.async = true;
        scriptTag.id = "liveidentityScript";
        scriptTag.addEventListener("load", () => setCaptchaScriptLoaded(true));
        document.body.appendChild(scriptTag);
      }
    }

    return () => {
      // remove liveidentity script
      document.body.removeChild(document.querySelector("#liveidentityScript"));
      // we need to remove manually every link style that liveidentity added
      document.querySelectorAll("head > link[href='/css/captcha.css']").forEach((node) => {
        document.head.removeChild(node);
      });
    };
  }, []);

  useEffect(() => {
    // trigger on language changes
    if (language !== oldLanguage) {
      setCaptchaAntibotInfo(false);
      setCaptchaScriptLoaded(false);
      setCaptchaErrorMessage("");
      getCaptchaAntibotInfo();
      setOldLanguage(language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    const inputAntibot = document.querySelector("#li-antibot-token");
    if (captchaErrorMessage && inputAntibot) {
      const observer = new MutationObserver((_, obs) => {
        setCaptchaErrorMessage("");
        obs.disconnect();
      });
      observer.observe(inputAntibot, { attributes: true });
    }
  }, [captchaErrorMessage]);

  const getToken = () => {
    if (captchaAntibotInfo) {
      const token = document.getElementById("li-antibot-token")?.value;
      return token === "Invalid response." || token === "" ? null : token;
    }
    // false means ko from antibotinfo
    return false;
  };

  const getTokenCode = () => {
    if (captchaAntibotInfo) {
      const tokenCode = document.getElementById("li-antibot-token-code")?.value;
      return tokenCode;
    }
    return false;
  };

  const verifyCaptchaLoaded = () => {
    // if li-antibot-token is here, the script loaded
    return document.getElementById("li-antibot-token") !== null;
  };

  useImperativeHandle(ref, () => ({
    verifyCaptchaLoaded,
    getToken,
    getTokenCode,
    setCaptchaErrorMessage,
  }));

  useEffect(() => {
    if (captchaScriptLoaded && !captchaAntibotInfo) {
      getCaptchaAntibotInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captchaScriptLoaded]);

  if (!process.env.liveidentity_key) return null;

  return (
    <div key={id} className={classnames("pre-captcha-wrapper", classes.captcha, className)}>
      <div aria-label={t[language].components.captcha_message} />
      <div className="captcha-wrapper" style={{ border: captchaErrorMessage === "" ? "none" : "1px solid #f44336" }}>
        <div id="li-antibot" ref={captchaContainerRef} />
      </div>
      {captchaErrorMessage && <FormHelperText className="status">{captchaErrorMessage}</FormHelperText>}
    </div>
  );
});

Captcha.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Captcha.defaultProps = {
  className: "",
};

export default Captcha;
