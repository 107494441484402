import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Helmet from "react-helmet";
import Wrapper from "components/LayoutFront/Wrapper";
import { getQueryParams, getEnglishItems } from "utils/urlUtils";
import PageService from "services/PageService";
import Block from "components/templatesComponents/Block";
import PageTitle from "components/templatesComponents/PageTitle";
import Card from "components/templatesComponents/Card";
import CardContext from "components/templatesComponents/CardContext";
import { styleKeys } from "components/templatesComponents/card/cardStyles";
import Grid from "@material-ui/core/Grid";
import Link from "components/templatesComponents/Link";
import contact from "components/templates/contact/contact";
import help from "components/templates/help/help";
import floatingImage from "components/templates/floatingImage/floatingImage";
import SectionService from "services/SectionService";
import useAxiosCache from "hooks/axiosCache";
import generateTemplatePropsFromContents from "utils/templatePropsUtils";
import Tabs from "components/templatesComponents/Tabs";
import allNews from "components/templates/news/allNews";
import LanguageContext from "components/LanguageContext";
import SiteContext from "components/SiteContext";
import t from "utils/locales/translation.json";
import { getTranslateIndex } from "utils/commonUtils";

const useStyles = makeStyles((theme) => ({
  listLink: {
    marginTop: theme.spacing(4),
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    "& a": {
      color: theme.palette.primary.main,
    },
  },
}));

const ResultsSearch = (props) => {
  const classes = useStyles();
  const { location } = props;
  const queryParams = getQueryParams(location.search);
  const { language } = useContext(LanguageContext);
  const site = useContext(SiteContext);
  const { id: siteId, name: siteName } = site || {};

  const [result, setResult] = useState(null);

  useEffect(() => {
    PageService.search({ query: queryParams.q, lang: language, from: 0, size: 100 }).then(setResult);
  }, [queryParams.q, language]);

  const { hits } = result || {};
  const { hits: results, total } = hits || {};

  const [{ data }] = useAxiosCache(
    PageService.getConfig("findPagesByTemplateKeys", {
      templates: [contact.key, help.key, floatingImage.key],
      filters: [`page.lang||eq||${language}`],
    })
  );

  const { pages = [] } = data || {};

  const contactPage = pages && pages.find((version) => version.template === contact.key);
  const helpPage = pages && pages.find((version) => version.template === help.key);
  const floatingImagePage = pages && pages.find((version) => version.title === "Aide et Contact");

  // get menu for tabs
  const [{ data: menu }] = useAxiosCache(SectionService.getConfig("getMenu"));
  const menuContents = (menu && generateTemplatePropsFromContents(menu.contents)) || {};
  const menuItems = menuContents.menus || [];

  const [{ data: allPnuPages = [] }] = useAxiosCache(
    PageService.getConfig("findPages", {
      siteId,
      filters: ["page.originalPageId||notnull"],
    })
  );

  const menuItemsWithPNU = React.useMemo(() => {
    if (language.toUpperCase() === "EN") {
      return getEnglishItems({ items: menuItems, allPnuPages }) || [];
    }
    return menuItems || [];
  }, [language, menuItems, allPnuPages]);

  // tabs for search results
  let tabs = [];
  menuItemsWithPNU.forEach((item) => {
    if (item.link && item.link.page) {
      tabs.push({
        title: item.title,
        fullPath: item.link.page && item.link.page.fullPath,
        children: [],
      });
    } else if (item.subMenus) {
      item.subMenus.forEach((subMenu) => {
        if (subMenu?.link?.page?.template === allNews.key) {
          tabs.push({
            title: subMenu.title,
            fullPath: subMenu?.link?.page?.fullPath,
            children: [],
          });
        }
      });
    }
  });
  tabs.push({ key: "OTHER", title: t[language].search.others_tab_title, path: "", children: [] });

  if (results) {
    const othersTab = tabs?.find((tab) => tab.key && tab.key === "OTHER");

    results.forEach((res) => {
      const { _source: page } = res;
      const tab = tabs?.find((ta) =>
        ta.fullPath === "" ? page.fullPath === "/" : page.fullPath.startsWith(ta.fullPath)
      );
      let breadcrumb = page.breadcrumb.map((b) => b.title);
      if (breadcrumb && breadcrumb.length > 3) {
        breadcrumb = [breadcrumb[0], breadcrumb[1], "...", breadcrumb[breadcrumb.length - 1]];
      }
      const subtitle = breadcrumb.join(` / `);
      const PageItem = <Card page={page} subtitle={subtitle} linkLabel={t[language].common.know_more} />;
      if (tab) {
        tab.children.push(PageItem);
      } else {
        othersTab.children.push(PageItem);
      }
    });
  }
  tabs = tabs.sort((tab1, tab2) => tab2.children.length - tab1.children.length);
  tabs = tabs.map((tab) => ({
    ...tab,
    title: `${tab.title} ${tab.children.length}`,
    disabled: tab.children.length === 0,
    children: (
      <Grid container direction="column" xs={12} spacing={2} role="list">
        {tab.children}
      </Grid>
    ),
  }));

  return (
    result && (
      <Block>
        <Helmet>
          <title>
            {t[language].search.balise_title[getTranslateIndex(total.value)]}
            {siteName === "ANTS" ? "" : " - "}
            {t[language].site_name[siteName]} - France Titres (ANTS)
          </title>
        </Helmet>
        <Wrapper>
          <PageTitle>
            {t[language].search.pre_title}
            {total.value}
            {t[language].search.post_title[getTranslateIndex(total.value)]} : &quot;{queryParams.q}
            &quot;
          </PageTitle>
          {total.value > 0 && (
            <CardContext.Provider
              value={{
                desktopStyleKey: styleKeys.VERTICAL_BIG_SEARCH_RESULT,
                mobileStyleKey: styleKeys.VERTICAL_BIG_SEARCH_RESULT,
                Wrapper: Grid,
                wrapperProps: {
                  item: true,
                  role: "listitem",
                },
              }}
            >
              <Tabs tabs={tabs} variant="scrollable" scrollButtons="on" />
            </CardContext.Provider>
          )}
          {total.value === 0 && (
            <p className={classes.listLink} role="presentation">
              {t[language].search.no_result_title} &quot;
              {queryParams.q}&quot;.
              <br />
              <br />
              {t[language].search.no_result_message1}
              {(contactPage || helpPage || floatingImagePage) && t[language].search.no_result_message2}
              {contactPage && <Link page={contactPage}>{t[language].search.contact_us}</Link>}
              {helpPage && <Link page={helpPage}>{t[language].search.contact_us}</Link>}
              {floatingImagePage && <Link page={floatingImagePage}>{t[language].search.contact_us}</Link>}.
            </p>
          )}
        </Wrapper>
      </Block>
    )
  );
};

ResultsSearch.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default ResultsSearch;
