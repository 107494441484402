import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Wrapper from "components/LayoutFront/Wrapper";

const Governance = (props) => {
  const { governersTitle, dynamicPart } = props;

  return (
    <Fragment>
      <Wrapper>
        <h2>{governersTitle}</h2>
      </Wrapper>

      <Wrapper>{dynamicPart}</Wrapper>
    </Fragment>
  );
};

Governance.propTypes = {
  governersTitle: PropTypes.node.isRequired,
  dynamicPart: PropTypes.node,
};

Governance.defaultProps = {
  dynamicPart: null,
};

export default Governance;
