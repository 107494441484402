import React, { useContext } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import PageService from "services/PageService";
import useAxiosCache from "hooks/axiosCache";
import TagsComponent from "components/templatesComponents/Tags";
import { getSiteName } from "utils/commonUtils";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const profilTags = (process.env.profil_tags || "").toLowerCase().split(",");

const SearchTagsBlock = (props) => {
  const { currentSiteName, selectedTags, handleSelectTagsForm, hiddenTitle, profil, lang } = props;

  const { language } = useContext(LanguageContext);

  const [{ data }] = useAxiosCache(
    PageService.getConfig("getTags", {
      currentSiteName,
      lang,
    })
  );
  const { aggregations } = data || {};
  const { tags: objectTags } = aggregations || {};
  const { buckets } = objectTags || {};

  const allTags = React.useMemo(
    () =>
      buckets
        ?.map((bucket) => bucket.key)
        ?.filter((b) => (profil ? profilTags.includes(b.toLowerCase()) : !profilTags.includes(b.toLowerCase()))),
    [buckets, profil]
  );

  const handleSelectTags = (tag) => {
    if (selectedTags.includes(tag)) {
      handleSelectTagsForm(selectedTags.filter((st) => st !== tag));
    } else {
      handleSelectTagsForm([tag, ...selectedTags]);
    }
  };

  if (allTags?.length === 0) {
    return null;
  }

  return (
    <>
      {currentSiteName && !hiddenTitle ? (
        <>
          <hr />
          <h5>{getSiteName(currentSiteName)}</h5>
        </>
      ) : (
        <Box style={{ margin: "12px" }} />
      )}
      <div aria-label={t[language].all_news.tag_list} />
      <TagsComponent tags={allTags} withDSFR handleSelectTags={handleSelectTags} selectedTags={selectedTags} />
    </>
  );
};

SearchTagsBlock.propTypes = {
  currentSiteName: PropTypes.string,
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  handleSelectTagsForm: PropTypes.func,
  hiddenTitle: PropTypes.bool,
  profil: PropTypes.bool,
  lang: PropTypes.string.isRequired,
};

SearchTagsBlock.defaultProps = {
  currentSiteName: "",
  selectedTags: [],
  handleSelectTagsForm: null,
  hiddenTitle: false,
  profil: false,
};

export default SearchTagsBlock;
