import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { componentClassName as buttonClassName } from "components/templatesComponents/Button";
import { componentClassName as linkClassName } from "components/templatesComponents/Link";
import { componentClassName as textClassName } from "components/templatesComponents/Text";
import Icon from "components/templatesComponents/Icon";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const useStepStyles = makeStyles((theme) => ({
  head: {
    padding: theme.spacing(2, 1),
    fontSize: "1.05rem",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(3, 2),
      fontSize: "1.3rem",
    },
  },
  // TODO revoir bubble pour pas que ça pete lorsqu'on augment la font-size
  bubble: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "32px",
    width: "32px",
    backgroundColor: theme.palette.secondary[10],
    color: theme.palette.secondary.main,
    borderRadius: "50%",
    [theme.breakpoints.up("lg")]: {
      fontSize: "30px",
      lineHeight: "30px",
      height: "48px",
      width: "48px",
    },
  },
  index: {
    fontSize: "22px",
    lineHeight: "22px",
    marginTop: "-2px",
    [theme.breakpoints.up("lg")]: {
      fontSize: "30px",
      lineHeight: "30px",
      marginTop: "-3px",
    },
  },
  title: {
    display: "inline-block",
    marginLeft: "20px",
    flex: "1 1 0px",
    color: theme.palette.componentColors[70],
  },
  detail: {
    minHeight: 50,
    backgroundColor: theme.palette.secondary[10],
    // Voir si on peut faire plus propre
    [`& > .${textClassName}`]: {
      padding: theme.spacing(1),
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(2),
      },
    },
    [`& > .${buttonClassName}, & > .${linkClassName} > .${buttonClassName}`]: {
      width: "100%",
    },
    "& .MuiExpansionPanelSummary-expandIcon": {
      padding: theme.spacing(2, 2),
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(2.5),
      },
      color: theme.palette.getContrastText(theme.palette.secondary[30]),
    },
    "& .MuiExpansionPanel-root.Mui-expanded": {
      margin: 0,
    },
    "& .MuiExpansionPanelSummary-root": {
      backgroundColor: theme.palette.secondary[30],
      color: theme.palette.getContrastText(theme.palette.secondary[30]),
    },
  },
  icon: {
    display: "inline-flex",
    marginLeft: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.8em",
    width: "24px",
    height: "24px",
    backgroundColor: theme.palette.secondary[10],
    color: theme.palette.secondary.main,
    borderRadius: "50%",
  },
}));

export const Step = (props) => {
  const { index, title, tooltip, icon, detail } = props;
  const classes = useStepStyles();
  const { language } = useContext(LanguageContext);

  return (
    <div className={classes.step}>
      <div className={classes.head}>
        <span className={classes.bubble}>
          <span className={classes.index}>{index}</span>
        </span>
        <div className={classes.title}>
          {title}
          {!!tooltip && (
            <>
              <Tooltip
                title={<span style={{ fontSize: "0.74rem" }}>{tooltip}</span>}
                aria-label={t[language].components.tooltip_aria_label}
                placement="top"
              >
                <span className={classes.icon}>
                  <Icon {...icon} title="" />
                </span>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      {detail && <div className={classes.detail}>{detail}</div>}
    </div>
  );
};

Step.propTypes = {
  index: PropTypes.number,
  icon: PropTypes.shape(),
  title: PropTypes.string,
  tooltip: PropTypes.string,
  detail: PropTypes.node,
};

Step.defaultProps = {
  index: null,
  title: null,
  icon: null,
  tooltip: null,
  detail: null,
};

const useStepperStyles = makeStyles((theme) => ({
  separation: {
    height: "50px",
    borderLeft: `2px dashed ${theme.palette.secondary.light}`,
    marginLeft: "25px",
    [theme.breakpoints.up("lg")]: {
      marginLeft: "40px",
    },
  },
}));

const Stepper = (props) => {
  let { steps } = props;
  if (steps && !Array.isArray(steps)) {
    steps = [steps];
  }

  const classes = useStepperStyles();
  return (
    <Fragment>
      {steps.map((step, index) => (
        <li key={step.props.id}>
          <step.type {...step.props} index={index + 1} />
          {index + 1 !== steps.length && <div className={classes.separation} />}
        </li>
      ))}
    </Fragment>
  );
};

Stepper.propTypes = {
  steps: PropTypes.node,
};

Stepper.defaultProps = {
  steps: [],
};

export default Stepper;
