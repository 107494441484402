import unorm from "unorm";

export const getQueryParams = (url = window.location.href) => {
  const params = {};
  const search = decodeURI(url).split("?").slice(1).join("?");

  search.split("&").forEach((param) => {
    if (param) {
      const [key, value = ""] = param.split("=");
      if (!params[key]) {
        if (value && value.indexOf(",") !== -1) {
          params[key] = value.split(",");
        } else {
          params[key] = value;
        }
      }
    }
  });

  return params;
};

export const createQueryParams = (params = {}) => {
  if (typeof params !== "object") {
    return "";
  }
  return Object.keys(params)
    .map((key) => {
      if (Array.isArray(params[key])) {
        return `${key}=${params[key].join(",")}`;
      }
      return params[key] !== undefined ? `${key}=${params[key]}` : "";
    })
    .join("&");
};

export const accentNormalize = (s = "") =>
  `${unorm
    .nfd(s)
    .replace(/[\u0300-\u036f]/g, "")
    .replace("Œ", "OE")}`;

export const normalized = (s = "") =>
  `${unorm
    .nfd(s.toLowerCase())
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[\s']/g, "-")
    .replace(/[^a-z0-9-_]/g, "")
    .replace(/-{2,}/g, "-")}`;

export const stringToPath = (s = "") => `/${normalized(s).replace(/-$/g, "")}`;

export const isValidUrl = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}" + // domain
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+=]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const createUrlWithoutQuery = (url = "") => url.split("?")[0];
export const getFilenameFromUrl = (url = "") => {
  const urlWithoutQueries = createUrlWithoutQuery(url);
  return urlWithoutQueries && urlWithoutQueries.split("/")[urlWithoutQueries.split("/").length - 1];
};

export const getEnglishItems = ({ items, allPnuPages }) => {
  let itemArray = items;
  if (itemArray && !Array.isArray(itemArray)) {
    itemArray = [itemArray];
  }
  const newMenuItems = itemArray?.map((item) => {
    const { link, subMenus } = item || {};
    if (!subMenus) {
      const { page } = link || {};
      const { pageId } = page || {};
      if (allPnuPages?.some((p) => p.page.originalPageId === pageId)) {
        const pnuPage = allPnuPages.find((p) => p.page.originalPageId === pageId) || {};
        return { ...item, title: pnuPage.title, link: { ...link, page: pnuPage } };
      }
      return item;
    }
    return { ...item, subMenus: getEnglishItems({ items: subMenus, allPnuPages }) };
  });
  return newMenuItems;
};
