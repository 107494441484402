import React, { useState } from "react";
import PropTypes from "prop-types";
import videosOrigins from "utils/videosOrigins";
import { getQueryParams } from "utils/urlUtils";

const validURL = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

const Video = (props) => {
  const { src, source, legend, title, transcription, openButtonLabel, closeButtonLabel, ...others } = props;

  const [displayTranscription, setDisplayTranscription] = useState(typeof transcription?.props?.children === "object");
  const [displayModal, setDisplayModal] = useState(false);

  const createSrcByOrigin = () => {
    if (!src) return src;

    let newSrc = src.split("?")[0];
    const queryParams = getQueryParams(src);

    switch (source) {
      case videosOrigins.YOUTUBE: {
        const { v: video, t: time } = queryParams;
        if (newSrc.includes("youtu.be")) {
          newSrc = newSrc.replace("youtu.be", "www.youtube.com/embed/");
        } else {
          newSrc = newSrc.replace("watch", "embed/");
          if (video) {
            newSrc = newSrc.concat("", video);
          }
        }
        if (time) {
          newSrc = newSrc.concat("", `?start=${time}`);
        }
        return newSrc;
      }
      case videosOrigins.DAILYMOTION:
        return newSrc.replace("video", "embed/video");
      case videosOrigins.VIMEO:
        return newSrc.replace("https://vimeo.com", "https://player.vimeo.com/video");
      default:
        break;
    }
    return src;
  };

  const iframeSrc = validURL(createSrcByOrigin()) ? createSrcByOrigin() : "about:blank";

  const transcriptionLabel = displayTranscription ? closeButtonLabel : openButtonLabel;

  return (
    <figure role="group" className="fr-content-media">
      <iframe
        height="560"
        className="fr-responsive-vid"
        src={iframeSrc}
        title={title}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        {...others}
      />
      <figcaption className="fr-content-media__caption">{legend}</figcaption>
      {transcription?.props?.children && (
        <div className="fr-transcription">
          <button
            type="button"
            className="fr-transcription__btn"
            aria-expanded={displayTranscription}
            aria-label={transcriptionLabel}
            onClick={() => setDisplayTranscription(!displayTranscription)}
            data-fr-js-collapse-button="true"
          >
            {transcriptionLabel}
          </button>
          <div
            className={`fr-collapse ${displayTranscription ? "fr-collapse--expanded" : ""}`}
            data-fr-js-collapse="true"
          >
            <div className="fr-transcription__footer">
              <div className="fr-transcription__actions-group">
                <button
                  type="button"
                  className="fr-btn--fullscreen fr-btn"
                  aria-label="Agrandir la transcription"
                  onClick={() => setDisplayModal(!displayModal)}
                  data-fr-js-modal-button="true"
                >
                  Agrandir
                </button>
              </div>
            </div>
            <div
              className={`fr-modal ${displayModal ? "fr-modal--opened" : ""}`}
              aria-labelledby="fr-transcription-modal-transcription-2160-title"
              data-fr-js-modal="true"
              aria-modal={displayModal}
              open={displayModal}
              role={displayModal ? "dialog" : undefined}
            >
              <div className="fr-container fr-container--fluid fr-container-md">
                <div className="fr-grid-row fr-grid-row--center">
                  <div className="fr-col-12 fr-col-md-10 fr-col-lg-8">
                    <div className="fr-modal__body" data-fr-js-modal-body="true">
                      <div className="fr-modal__header">
                        <button
                          type="button"
                          className="fr-btn--close fr-btn"
                          aria-controls="fr-transcription-modal-transcription-2160"
                          title="Fermer"
                          onClick={() => setDisplayModal(false)}
                          data-fr-js-modal-button="true"
                        >
                          Fermer
                        </button>
                      </div>
                      <div className="fr-modal__content">
                        <h1 id="fr-transcription-modal-transcription-2160-title" className="fr-modal__title">
                          {title}
                        </h1>
                        {displayTranscription && transcription}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </figure>
  );
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  legend: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  transcription: PropTypes.shape(),
  openButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  closeButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
};

Video.defaultProps = {
  legend: "",
  title: "",
  transcription: {},
  openButtonLabel: "",
  closeButtonLabel: "",
};

export default Video;
