import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import ResultListContext from "./ResultListContext";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("md")]: {
      "& > div:nth-child(even)": {
        borderLeft: `solid 2px ${theme.palette.primary.light}`,
        paddingLeft: theme.spacing(4),
      },
      "& > div:nth-child(n+3)": {
        borderTop: `solid 2px ${theme.palette.primary.light}`,
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& > div:not(:last-child):not(:nth-child(4n)) $gridBorder": {
        borderRight: `solid 2px ${theme.palette.primary.light}`,
      },
    },
  },
  gridBorder: {
    marginTop: theme.spacing(1),
    flex: "1 1 auto",
    [theme.breakpoints.up("lg")]: {
      paddingRight: theme.spacing(4),
    },
  },
}));

const ResultList = (props) => {
  let { results } = props;

  const classes = useStyles();

  if (!results || results.length === 0) {
    return null;
  }

  if (results && !Array.isArray(results)) {
    results = [results];
  }

  let xs = 6;
  let md = 3;
  const size = React.Children.count(results);
  if (size === 1) {
    xs = 12;
    md = 6;
  } else if (size === 2) {
    md = 6;
  } else if (size === 3) {
    md = 4;
  }

  return (
    <ResultListContext.Provider value={{ classes, xs, md }}>
      <Grid container className={classes.container}>
        {results}
      </Grid>
    </ResultListContext.Provider>
  );
};

ResultList.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape()),
};

ResultList.defaultProps = {
  results: [],
};

export default ResultList;
