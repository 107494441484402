import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/styles";
import Block from "components/templatesComponents/Block";
import Grid from "@material-ui/core/Grid";
import { ImageContainer } from "components/templatesComponents/Image";
import SubTemplateWrapper from "components/LayoutFront/SubTemplateWrapper";
import { styleKeys } from "components/templatesComponents/card/cardStyles";
import CardContext from "components/templatesComponents/CardContext";

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.up("lg")]: {
    container: {
      position: "relative",
      paddingTop: "16px",
    },
    image: {
      width: "60%",
      position: "absolute",
      top: "-90px",
      right: 0,
      zIndex: -1,
    },
    content: {
      width: "50%",
    },
    shortDescription: {
      position: "relative",
      padding: "60px",
      minHeight: "170px",
      "&:before": {
        content: `""`,
        background: "white",
        zIndex: -1,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        border: "2px solid #e7e7e7",
      },
    },
  },
  links: {
    paddingLeft: theme.spacing(2),
    width: "100%",
    margin: 0,
    "& > div": {
      padding: theme.spacing(0.5, 0),
    },
    [theme.breakpoints.up("lg")]: {
      zIndex: 1,
      marginTop: "-40px",
      paddingLeft: 0,
      width: `calc(100% + ${theme.spacing(2)}px)`,
      margin: -theme.spacing(1),
      "& > div": {
        padding: theme.spacing(1),
      },
    },
  },
  card: {
    display: "flex",
  },
}));

const HomeMissions = (props) => {
  const { page } = props;
  const { title, shortDescription, image, contents } = page;
  const { pageLinks } = contents;

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  const classes = useStyles();

  return (
    <SubTemplateWrapper backgroundColor={isMobile ? "transparent" : "#f1f1f1"} title={title} right>
      <div className={classes.container}>
        <div className={classes.image}>
          <ImageContainer ratio={55}>{image}</ImageContainer>
        </div>
        <div className={classes.content}>
          <Block className={classes.shortDescription}>{shortDescription}</Block>
        </div>
        <Grid container justifyContent="center" alignItems="stretch" className={classes.links}>
          <CardContext.Provider
            value={{
              Wrapper: Grid,
              wrapperProps: {
                item: true,
                xs: 12,
                md: 4,
                className: classes.card,
              },
              desktopStyleKey: styleKeys.VERTICAL_BIG_ONE_LINE,
              mobileStyleKey: styleKeys.HORIZONTAL,
              desktopStyleOverride: {
                root: {
                  backgroundColor: theme.palette.primary[50],
                  "a&:hover": {
                    backgroundColor: theme.palette.primary[50],
                  },
                },
              },
              mobileStyleOverride: {
                title: {
                  fontWeight: 600,
                },
              },
            }}
          >
            {pageLinks && pageLinks.props && pageLinks.props.cards}
          </CardContext.Provider>
        </Grid>
      </div>
    </SubTemplateWrapper>
  );
};

HomeMissions.propTypes = {
  page: PropTypes.shape(),
};

HomeMissions.defaultProps = {
  page: {},
};

export default HomeMissions;
