import React, { useCallback, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import LazyLoad from "react-lazy-load";
import { getFileExtension, getFileNameWithoutExtension } from "utils/commonUtils";
import { normalized, getFilenameFromUrl } from "utils/urlUtils";

const useContainerStyles = makeStyles({
  ratio: (props) => ({
    paddingTop: `${props.ratio}%`,
    position: "relative",
    flex: "1 1 0px",
    "@media print": {
      display: "none",
    },
  }),
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },
});

export const ImageContainer = (props) => {
  const { ratio, children } = props;

  const classes = useContainerStyles({ ratio });

  if (Number.isNaN(parseFloat(ratio))) {
    return children;
  }

  return (
    <div className={classes.ratio}>
      <div className={classes.container}>{children}</div>
    </div>
  );
};

ImageContainer.propTypes = {
  ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  children: PropTypes.node,
};

ImageContainer.defaultProps = {
  children: null,
};

const useImageStyles = makeStyles({
  image: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
});

const Image = (props) => {
  const { file, title, alt, className, ...others } = props;

  const { thumbs } = file || {};
  let { url, name } = file || {};
  const [imgPlaceholder, setImgPlaceholder] = useState(null);

  const placeholderImgRef = useCallback((node) => {
    if (node !== null) {
      setImgPlaceholder(node.getBoundingClientRect());
    }
  }, []);

  const getThumbUrl = useCallback(() => {
    if (imgPlaceholder && thumbs) {
      let minWidth = Infinity;
      let minHeight = Infinity;
      const { width: placeHolderWidth, height: placeHolderHeight } = imgPlaceholder;
      Object.keys(thumbs).forEach((key) => {
        if (key !== "thumbUrl") {
          let [width, height] = key.split("x");
          width /= 1;
          height /= 1;
          if (width >= placeHolderWidth && width < minWidth && (height >= placeHolderHeight || height === "0")) {
            minWidth = width;
          }
          if (height >= placeHolderHeight && height < minHeight && (width >= placeHolderWidth || width === "0")) {
            minHeight = height;
          }
        }
      });
      if (minWidth !== Infinity && minHeight !== Infinity) {
        return thumbs[`${minWidth}x${minHeight}`];
      }
      if (minWidth !== Infinity) {
        return thumbs[`${minWidth}x0`];
      }
      if (minHeight !== Infinity) {
        return thumbs[`0x${minWidth}`];
      }
      return thumbs.thumbUrl;
    }
    return null;
  }, [imgPlaceholder, thumbs]);

  if ((name || title) && url && url.startsWith(process.env.ged_public_url)) {
    const filename = getFilenameFromUrl(url);
    const fileNameWithoutExtension = getFileNameWithoutExtension(filename);
    if (!name) {
      name = `${normalized(title)}${getFileExtension(filename)}`;
    }
    url = url.replace(filename, `${fileNameWithoutExtension}/${name}`);
  }
  const classes = useImageStyles();

  return (
    <LazyLoad height="100%" throttle={100}>
      {!imgPlaceholder ? (
        <div ref={placeholderImgRef} style={{ height: "100%" }} />
      ) : (
        <img
          src={getThumbUrl() || url}
          onError={(e) => {
            if (url !== e.target.src) {
              e.target.onerror = null;
              e.target.src = url;
            }
          }}
          alt={alt}
          title={title}
          className={classnames(classes.image, className)}
          {...others}
        />
      )}
    </LazyLoad>
  );
};

Image.propTypes = {
  file: PropTypes.shape().isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Image.defaultProps = {
  title: null,
  className: "",
  alt: null,
  ratio: null,
};

export default Image;
