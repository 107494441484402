import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";

const SectionTitle = (props) => {
  const { children, ...others } = props;
  return (
    <Box component="h2" {...others}>
      {children}
    </Box>
  );
};

SectionTitle.propTypes = {
  children: PropTypes.node,
};

SectionTitle.defaultProps = {
  children: null,
};

export default SectionTitle;
