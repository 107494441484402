import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Link from "components/templatesComponents/Link";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { makeStyles } from "@material-ui/styles";
import { theme } from "apps/front-office/client/themes/antsTheme";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    color: theme.palette.primary[40],
    fontWeight: 600,
  },
}));

const SeeMoreLink = (props) => {
  const { page, children, className, ...others } = props;
  const classes = useStyles();

  return (
    <Link page={page} className={classnames(className, classes.root)} {...others}>
      {children} <ChevronRightIcon role="img" />
    </Link>
  );
};

SeeMoreLink.propTypes = {
  page: PropTypes.shape(),
  children: PropTypes.node,
  className: PropTypes.string,
};

SeeMoreLink.defaultProps = {
  page: null,
  children: null,
  className: null,
};

export default SeeMoreLink;
