import merge from "lodash.merge";
import { createTheme } from "@material-ui/core/styles";
import antsTheme from "./antsTheme";

const colors = {
  10: "#E8FFF8",
  20: "#CEF8EA",
  30: "#72E7C4",
  40: "#20D1A2",
  50: "#07C496",
  60: "#01B48C",
};

export const theme = merge({}, antsTheme, {
  palette: {
    secondary: {
      light: colors[40],
      main: colors[50],
      dark: colors[60],
      hover: colors[60],
      ...colors,
    },
  },
  templates: {
    Article: {
      icon: {
        color: colors[50],
      },
    },
  },
  navigation: {
    current: {
      color: colors[50],
      hover: colors[20],
    },
    iconMenuMobile: {
      color: colors[40],
    },
    topNav: {
      background: antsTheme.palette.componentColors[20],
      backgroundLogo: "transparent",
    },
    titleMobile: {
      fontSize: "1.25rem",
    },
  },
  footer: {
    background: {
      backgroundColor: colors[40],
      backgroundColorTitleSocialMobile: colors[50],
    },
  },
  components: {
    Button: {
      login: {
        backgroundColor: colors[50],
        hover: colors[60],
      },
    },
    ExpansionPanel: {
      ExpandIcon: {
        backgroundColor: colors[50],
        expanded: colors[50],
      },
    },
  },
});

export default createTheme(theme);
