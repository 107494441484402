import React, { useEffect, useMemo, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import scrollIntoView from "scroll-into-view-if-needed";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { usePagination } from "@material-ui/lab/Pagination";
import SiteContext from "components/SiteContext";
import Wrapper from "components/LayoutFront/Wrapper";
import CardVertical from "components/templatesComponents/CardVertical";
import Modal from "components/templatesComponents/Modal";
import Pagination from "components/templatesComponents/Pagination";
import NewsTabs from "components/templates/news/NewsTabs";
import AllNewsSearch from "components/templates/news/AllNewsSearch";
import AllNewsFilters from "components/templates/news/AllNewsFilters";
import PageService from "services/PageService";
import useAxiosCache from "hooks/axiosCache";
import { createQueryParams, getQueryParams } from "utils/urlUtils";
import { debounce } from "utils/commonUtils";
import t from "utils/locales/translation.json";

const articlesPerPage = 10;

const queryPageKey = "p";

const initialsTags = {
  ANTS: [],
  IMMAT: [],
  PASSEPORT_CNI: [],
  PERMIS: [],
};

const allProfilTags = (process.env.profil_tags || "").toLowerCase().split(",");

const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.down("md")]: {
      padding: "0 16px",
    },
  },
  searchContainer: {
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 2),
    },
  },
  button: {
    marginTop: theme.spacing(3),
  },
  noResultText: {
    "& > li": {
      margin: 0,
    },
  },
  openModalButton: {
    padding: theme.spacing(1.5, 0),
    margin: theme.spacing(2, -4, 3.5),
    boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.10), 0px 16px 16px -16px rgba(0, 0, 0, 0.32)",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    "& > img": {
      paddingLeft: theme.spacing(4),
      marginRight: theme.spacing(1),
      height: "18px",
    },
  },
  searchButton: {
    width: "100%",
    justifyContent: "center",
    marginTop: "2rem",
  },
  searchTitle: {
    display: "flex",
    alignItems: "center",
    "& > img": {
      marginRight: theme.spacing(1.5),
      height: "24px",
    },
  },
}));

const AllNewsTemplate = (props) => {
  const { page: pageVersion } = props;
  const { title, contents, page } = pageVersion;
  const { siteName } = contents;
  const { lang } = page;

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));

  const history = useHistory();
  const location = useLocation();

  const { defaultSelectedTag } = location?.state || {};

  const { pathname, search } = useLocation();
  const queryParams = getQueryParams(search);

  const queryPage = useMemo(() => Number(queryParams?.[queryPageKey]) || 1, [queryParams]);

  const siteNameString = typeof siteName === "string" ? siteName : siteName?.props?.siteName;

  const site = useContext(SiteContext);
  const { name: currentSiteName } = site || {};

  const usedSiteName = useMemo(
    () => (siteNameString !== "allSites" ? siteNameString : currentSiteName),
    [currentSiteName, siteNameString]
  );

  const [value, setValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const getInitialsTagsForm = React.useCallback(() => {
    if (defaultSelectedTag && !allProfilTags.includes(defaultSelectedTag.toLowerCase())) {
      return { ...initialsTags, [currentSiteName]: [defaultSelectedTag] };
    }
    return initialsTags;
  }, [currentSiteName, defaultSelectedTag]);

  const getInitialsProfilTags = React.useCallback(() => {
    if (defaultSelectedTag && allProfilTags.includes(defaultSelectedTag.toLowerCase())) {
      return [defaultSelectedTag];
    }
    return [];
  }, [defaultSelectedTag]);

  const [selectedTagsForm, setSelectedTagsForm] = useState(getInitialsTagsForm());
  const [selectedProfilTags, setSelectedProfilTags] = useState(getInitialsProfilTags());

  const [{ data: d }] = useAxiosCache(
    PageService.getConfig("getTags", {
      currentSiteName: siteNameString,
      lang,
    })
  );
  const { aggregations } = d || {};
  const { tags: objectTags } = aggregations || {};
  const { buckets = [] } = objectTags || {};

  const allTags = React.useMemo(() => buckets?.map((bucket) => bucket.key), [buckets]);
  const profilTags = React.useMemo(() => allTags?.filter((b) => allProfilTags.includes(b.toLowerCase())), [allTags]);
  const thematicTags = React.useMemo(() => allTags?.filter((b) => !allProfilTags.includes(b.toLowerCase())), [allTags]);

  useEffect(() => {
    if (defaultSelectedTag) {
      setSelectedProfilTags(getInitialsProfilTags());
    }
  }, [getInitialsProfilTags, defaultSelectedTag]);

  useEffect(() => {
    if (defaultSelectedTag) {
      setSelectedTagsForm(getInitialsTagsForm());
    }
  }, [getInitialsTagsForm, defaultSelectedTag]);

  const selectedTags = useMemo(() => {
    const siteSelectags =
      (siteNameString === "allSites"
        ? Object.values(selectedTagsForm).find((v) => v.length > 0)
        : selectedTagsForm[siteNameString]) || [];
    return selectedProfilTags.filter((profilTag) => profilTags.includes(profilTag))?.concat(siteSelectags);
  }, [selectedTagsForm, siteNameString, selectedProfilTags, profilTags]);

  const [formDate, setFormDate] = useState({ startDate: "aaaa-mm-jj", endDate: "aaaa-mm-jj" });

  const { startDate, endDate } = formDate;

  const handleChangeDate = (e) => {
    const { name, id } = e.target;
    setFormDate({ ...formDate, [name]: document?.getElementById(id)?.value });
  };

  const validStartDate = useMemo(
    () =>
      !Number.isNaN(new Date(startDate).getTime()) && Date.parse(startDate) > 0
        ? new Date(startDate)?.toISOString()
        : "",
    [startDate]
  );

  const validEndDate = useMemo(
    () =>
      !Number.isNaN(new Date(endDate).getTime()) && Date.parse(endDate) > 0
        ? new Date(`${endDate}`)?.toISOString().replace("T00:00:00.000Z", "T23:59:59.000Z")
        : "",
    [endDate]
  );

  useEffect(() => {
    if (value && value.length > 2) {
      debounce(setSearchValue, 500)(value);
      if (queryPage !== 1) {
        history.push({
          pathname,
          search: `?${createQueryParams({ ...queryParams, [queryPageKey]: 1 })}`,
        });
      }
    } else {
      debounce(setSearchValue, 500)("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if ((selectedTags || startDate || endDate) && queryPage !== 1) {
      history.push({
        pathname,
        search: `?${createQueryParams({ ...queryParams, [queryPageKey]: 1 })}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags, startDate, endDate]);

  const filters = useMemo(() => {
    const newFilters = [`page.lang||eq||${lang}`];
    if (Array.isArray(selectedTags) && selectedTags.length > 0) {
      selectedTags.forEach((selectedTag) => newFilters.push(`tags||cont||${selectedTag}`));
    }
    if (validStartDate && (!validEndDate || validStartDate > validEndDate) && Date.now() - Date.parse(validStartDate)) {
      newFilters.push(`publishedDate||gte||${validStartDate}`);
    }
    if (!validStartDate && validEndDate) {
      newFilters.push(`publishedDate||lte||${validEndDate}`);
    }
    if (validStartDate && validEndDate && validStartDate < validEndDate) {
      newFilters.push(`publishedDate||between||${validStartDate},${validEndDate}`);
    }
    return newFilters;
  }, [lang, selectedTags, validEndDate, validStartDate]);

  const [{ data: highlightedNews = [] }] = useAxiosCache(
    PageService.getConfig("findHighlightNews", {
      siteName: siteNameString,
      search: searchValue,
      filters,
    })
  );

  const allNewsFilters = useMemo(() => {
    if (Array.isArray(highlightedNews) && highlightedNews.length > 0) {
      return [...filters, `id||ne||${highlightedNews[0].id}`];
    }
    return filters;
  }, [filters, highlightedNews]);

  const offset = (queryPage - 1) * articlesPerPage;

  const [{ data }] = useAxiosCache(
    PageService.getConfig("findPagesByTemplateKeys", {
      templates: ["NEWS"],
      siteName: siteNameString,
      filters: allNewsFilters,
      page: queryPage,
      perPage: highlightedNews && queryPage === 1 ? articlesPerPage - highlightedNews.length : articlesPerPage,
      offset: highlightedNews && queryPage !== 1 ? offset - highlightedNews.length : offset,
      search: searchValue,
    })
  );

  const { pages, total: subTotal } = data || {};

  const total = useMemo(() => subTotal + highlightedNews?.length, [subTotal, highlightedNews]);

  const count = useMemo(() => Math.ceil(total / articlesPerPage), [total]);

  const scrollSent = () => {
    scrollIntoView(document.getElementById("articleTitle"), {
      block: "center",
      inline: "center",
    });
  };

  const handleChangePage = (event, newPage) => {
    history.push({
      pathname,
      search: `?${createQueryParams({
        ...queryParams,
        [queryPageKey]: newPage,
      })}`,
    });
    scrollSent();
  };

  const handleSelectTagsForm = (name) => (tags) => setSelectedTagsForm({ ...initialsTags, [name]: tags });

  const handleSelectProfilTags = () => (tags) => setSelectedProfilTags(tags);

  const { items } = usePagination({
    count,
    page: queryPage,
    onChange: handleChangePage,
  });

  const noResultText = useMemo(() => {
    if (searchValue) {
      return `${t[lang].all_news.no_result_search_title} : « ${searchValue} »`;
    }
    if (filters?.length > 0) {
      return t[lang].all_news.no_news_title;
    }
    return t[lang].all_news.no_news;
  }, [lang, searchValue, filters]);

  const searchReinit = () => {
    setValue("");
    setSelectedTagsForm(initialsTags);
    setSelectedProfilTags([]);
    setFormDate({ startDate: "aaaa-mm-jj", endDate: "aaaa-mm-jj" });
  };

  return (
    <Wrapper breakpoint="xl" className={classes.wrapper}>
      <h1 id="articleTitle">{title}</h1>
      <NewsTabs currentPageVersion={pageVersion} />
      {typeof siteName === "object" ? (
        <Grid container spacing={2} justifyContent="center" style={{ padding: "16px 16px 0" }}>
          <Grid item>
            <h6>Sélectionner un site : </h6>
          </Grid>
          <Grid item> {siteName}</Grid>
        </Grid>
      ) : (
        ""
      )}
      <Grid container justifyContent={isMobile ? "center" : "space-between"} spacing={3} style={{ paddingTop: "40px" }}>
        {(pages || search) && (
          <Grid item xs={12} md={4}>
            <Box className={classes.searchContainer}>
              <AllNewsSearch value={value} setValue={setValue} />
              {isMobile ? (
                <Box>
                  <button
                    type="button"
                    title={t[lang].all_news.open_modal_button_title}
                    onClick={() => setOpenModal(true)}
                    className={`fr-text--sm ${classes.openModalButton}`}
                  >
                    <img src="/filter-3-line.svg" alt="" />
                    {t[lang].all_news.display_mobile_search}
                  </button>
                  <Modal
                    aria-labelledby="search"
                    aria-describedby="filters"
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    size="md"
                    alignItems="flex-start"
                  >
                    <div className="fr-modal__header">
                      <button
                        type="button"
                        className="fr-link--close fr-link"
                        title={t[lang].all_news.close_modal_button_title}
                        onClick={() => setOpenModal(false)}
                      >
                        {t[lang].common.close}
                      </button>
                    </div>
                    <div className="fr-modal__content" style={{ marginBottom: "1rem" }}>
                      <h1
                        id="fr-modal-title-modal"
                        className={`fr-modal__title ${classes.searchTitle}`}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img src="/filter-3-line.svg" alt="" />
                        {t[lang].all_news.display_mobile_search}
                      </h1>
                      <AllNewsFilters
                        initialsTags={initialsTags}
                        profilTags={profilTags}
                        thematicTags={thematicTags}
                        selectedProfilTags={selectedProfilTags}
                        selectedTagsForm={selectedTagsForm}
                        startDate={startDate}
                        endDate={endDate}
                        validStartDate={validStartDate}
                        validEndDate={validEndDate}
                        handleSelectProfilTags={handleSelectProfilTags}
                        handleSelectTagsForm={handleSelectTagsForm}
                        handleChangeDate={handleChangeDate}
                        siteNameString={siteNameString}
                        usedSiteName={usedSiteName}
                        lang={lang}
                      />
                      <button
                        type="button"
                        className={`fr-btn ${classes.searchButton}`}
                        title={t[lang].common.search.button_title}
                        onClick={() => (total > 0 ? setOpenModal(false) : searchReinit())}
                      >
                        {total > 0
                          ? `${t[lang].all_news.pre_display_results}${total}${t[lang].all_news.post_display_results}`
                          : `${t[lang].common.news.no_result} /${t[lang].all_news.reset}`}
                      </button>
                    </div>
                  </Modal>
                </Box>
              ) : (
                <AllNewsFilters
                  initialsTags={initialsTags}
                  profilTags={profilTags}
                  thematicTags={thematicTags}
                  selectedProfilTags={selectedProfilTags}
                  selectedTagsForm={selectedTagsForm}
                  startDate={startDate}
                  endDate={endDate}
                  validStartDate={validStartDate}
                  validEndDate={validEndDate}
                  handleSelectProfilTags={handleSelectProfilTags}
                  handleSelectTagsForm={handleSelectTagsForm}
                  handleChangeDate={handleChangeDate}
                  siteNameString={siteNameString}
                  usedSiteName={usedSiteName}
                  lang={lang}
                />
              )}
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={8} container alignItems="flex-start" spacing={2}>
          {data && (
            <Grid container spacing={3} style={{ padding: "0 16px" }}>
              {(Array.isArray(pages) && pages.length > 0) || highlightedNews.length > 0 ? (
                <>
                  <Grid item xs={12}>
                    <h4>
                      {total} {t[lang].all_news.results}
                    </h4>
                  </Grid>
                  {highlightedNews &&
                    queryPage === 1 &&
                    highlightedNews.map(
                      (highlight) =>
                        highlight.fullPath && (
                          <Grid item xs={12} sm={6} key={highlight.id}>
                            <CardVertical page={highlight} isHighlighted />
                          </Grid>
                        )
                    )}
                  {Array.isArray(pages) &&
                    pages.map((p) => (
                      <Grid item xs={12} sm={6} key={p.id}>
                        <CardVertical page={p} />
                      </Grid>
                    ))}
                  {items?.length > 3 && (
                    <Grid container justifyContent="center">
                      <Pagination
                        items={items}
                        handleChangePage={handleChangePage}
                        count={count}
                        queryParams={queryParams}
                      />
                    </Grid>
                  )}
                </>
              ) : (
                <Grid item container direction="column" justifyContent="center">
                  <h4>{noResultText}</h4>
                  {searchValue ? (
                    <ul className={classes.noResultText}>
                      <li className="fr-text--lg">{t[lang].all_news.no_result_message1}</li>
                      <li className="fr-text--lg">{t[lang].all_news.no_result_message2}</li>
                      <li className="fr-text--lg">{t[lang].all_news.no_result_message3}</li>
                    </ul>
                  ) : (
                    <ul className={classes.noResultText}>
                      <li className="fr-text--lg">{t[lang].all_news.no_result_message3}</li>
                      <li className="fr-text--lg">{t[lang].all_news.no_result_message4}</li>
                    </ul>
                  )}
                  <button
                    type="button"
                    className={`fr-btn fr-btn--tertiary ${classes.button}`}
                    onClick={() => searchReinit()}
                  >
                    {t[lang].all_news.search_button_label}
                  </button>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Wrapper>
  );
};

AllNewsTemplate.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.node,
    shortDescription: PropTypes.node,
    contents: PropTypes.shape(),
    page: PropTypes.shape(),
  }).isRequired,
};

export default AllNewsTemplate;
