import React from "react";
import PropTypes from "prop-types";
import MuiHidden from "@material-ui/core/Hidden";

const Hidden = (props) => {
  const { children, ...others } = props;

  return (
    <MuiHidden implementation="css" {...others}>
      {children}
    </MuiHidden>
  );
};

Hidden.propTypes = {
  children: PropTypes.node,
};

Hidden.defaultProps = {
  children: null,
};

export default Hidden;
