import NewsTemplate from "components/templates/news/NewsTemplate";
import elementsTypes from "utils/elementsTypes";

export default {
  key: "NEWS",
  label: "Actualité",
  Component: NewsTemplate,
  initialContents: [
    {
      key: "ratio",
      type: elementsTypes.SLIDER_INLINE,
      value: "50",
    },
    {
      key: "watchTime",
      type: elementsTypes.EDITABLE_INPUT,
      value: "5 minutes",
    },
    {
      key: "imageCaption",
      type: elementsTypes.EDITABLE_INPUT,
      value: "Titre de la photo, date, auteur",
    },
    {
      key: "dynamicPart",
      type: elementsTypes.DYNAMIC,
      value: "",
      children: [],
    },
    {
      key: "dynamicAttachments",
      type: elementsTypes.ATTACHMENTS,
      value: "",
      children: [],
    },
  ],
};
