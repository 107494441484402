import merge from "lodash.merge";
import { createTheme } from "@material-ui/core/styles";
import antsTheme from "./antsTheme";

const colors = {
  10: "#FEECDE",
  20: "#FDD1AE",
  30: "#FCB67D",
  40: "#FBA45D",
  50: "#FB9B4D",
  60: "#FF8F35",
};

export const theme = merge({}, antsTheme, {
  palette: {
    secondary: {
      light: colors[40],
      main: colors[50],
      dark: colors[60],
      hover: colors[60],
      ...colors,
    },
  },
  templates: {
    PageLink: {
      cardArrow: {
        color: "#3A3835",
      },
    },
    Article: {
      icon: {
        color: colors[50],
      },
    },
  },
  navigation: {
    current: {
      color: colors[50],
      hover: colors[20],
    },
    iconMenuMobile: {
      color: colors[40],
    },
    topNav: {
      background: antsTheme.palette.componentColors[20],
      backgroundLogo: "transparent",
    },
    titleMobile: {
      fontSize: "1.25rem",
    },
  },
  footer: {
    background: {
      backgroundColor: colors[40],
      backgroundColorTitleSocialMobile: colors[50],
    },
  },
  components: {
    Button: {
      login: {
        backgroundColor: colors[50],
        hover: colors[60],
      },
    },
    ExpansionPanel: {
      ExpandIcon: {
        backgroundColor: colors[50],
        expanded: colors[50],
      },
    },
  },
});

export default createTheme(theme);
