import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Wrapper from "components/LayoutFront/Wrapper";
import PageTitle from "components/templatesComponents/PageTitle";
import Block from "components/templatesComponents/Block";

const useStyles = makeStyles((theme) => ({
  feedBack: {
    textAlign: "center",
  },
  feedBackButtons: {
    margin: theme.spacing(0, 0.5),
  },
  redirect: {
    textAlign: "center",
  },
}));

const HelpDetails = (props) => {
  const { page } = props;
  const { title, contents } = page;
  const { questions, cta } = contents;

  const classes = useStyles();
  return (
    <Wrapper>
      <Block>
        <PageTitle>{title}</PageTitle>
      </Block>

      {/* questions / answers */}
      <Block>{questions}</Block>

      {/* button cta */}
      <Block className={classes.redirect}>
        <p>{cta.title}</p>
        {cta.button}
      </Block>
    </Wrapper>
  );
};

HelpDetails.propTypes = {
  page: PropTypes.shape(),
};

HelpDetails.defaultProps = {
  page: {},
};

export default HelpDetails;
