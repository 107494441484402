import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Icon from "components/templatesComponents/Icon";
import ResultListContext from "./ResultListContext";

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(2.25, 2.5),
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.primary[10],
      padding: theme.spacing(2, 0, 2, 4),
    },
    "&:focus": { zIndex: 1 },
  },
  iconWrapper: {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  icon: {
    fontSize: "1.125rem",
    height: "1.125rem",
    width: "1.69rem",
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.5rem",
      height: "1.5rem",
      width: "2.25rem",
    },
  },
}));

const Result = (props) => {
  const { icon, number, text, ...others } = props;
  const classes = useStyles();
  const { classes: resultListClasses, md, xs } = useContext(ResultListContext);

  return (
    <Grid item container direction="column" lg={md} xs={xs} className={classes.item} {...others}>
      {icon && (
        <Grid item className={classes.iconWrapper}>
          <Icon {...icon} className={classes.icon} title={text} />
        </Grid>
      )}

      <Grid item className={resultListClasses.gridBorder}>
        <h2>{number}</h2>
        <p className="fr-text--sm fr-text--bold" role="presentation">
          {text}
        </p>
      </Grid>
    </Grid>
  );
};

Result.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  text: PropTypes.string,
  details: PropTypes.node,
};

Result.defaultProps = {
  icon: null,
  number: "",
  text: "",
  details: null,
};

export default Result;
