import React, { useContext } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import getTemplateComponentByKey from "utils/templateUtils";
import { formatPageData } from "utils/templatePropsUtils";
import socialNetworksTypes from "utils/socialNetworksTypes";
import SiteContext from "components/SiteContext";
import { createUrlWithoutQuery } from "utils/urlUtils";
import JsonLd from "components/JsonLd";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const Page = (props) => {
  const { page } = props;
  const site = useContext(SiteContext);
  const { settings: siteSettings, name: siteName, host } = site;
  const { socialNetworks } = siteSettings || {};
  const { language } = useContext(LanguageContext);
  const facebook = (socialNetworks || []).find((sn) => sn.network === socialNetworksTypes.FACEBOOK) || {};

  if (!page || !page.id) {
    return null;
  }
  const { title: pageTitle, description, image, fullPath = "", template, tab } = page || {};

  const { title: tabTitle } = tab || {};

  const title = pageTitle || tabTitle;

  const TemplateTag = getTemplateComponentByKey(template);

  const pageFullPath = `${host}${fullPath}`;

  const structuredData = `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "${siteName}",
    "url": "${pageFullPath}",
    "logo":"${host}/logo-republique-francaise.svg",
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+3400",
      "contactType": "customer service"
    }],
    "sameAs": [
      ${
        socialNetworks &&
        socialNetworks.reduce(
          (str, socialNetwork, index) =>
            `${str}"${socialNetwork.url}"${socialNetworks.length !== index + 1 ? "," : ""} `,
          ""
        )
      }
    ]
  }`;

  return (
    <div>
      {/* Permet de changer les données de la balise head */}
      <Helmet>
        <title>
          {title}
          {siteName === "ANTS" ? "" : " - "}
          {t[language].site_name[siteName]} - France Titres (ANTS)
        </title>
        <meta name="Description" content={description || ""} />
        <meta property="og:url" content={pageFullPath} />
        <meta property="og:title" content={title || ""} />
        <meta property="og:description" content={description || ""} />
        {image && <meta property="og:image" content={image.url || ""} />}
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content={facebook.appId || ""} />
        <link rel="canonical" href={createUrlWithoutQuery(pageFullPath)} />
      </Helmet>

      {/* Template de la page (article ou categorie) */}
      <JsonLd structuredData={structuredData} />
      <TemplateTag page={formatPageData(page)} />
    </div>
  );
};

Page.propTypes = {
  page: PropTypes.shape(),
};

Page.defaultProps = {
  page: null,
};

export default Page;
