import React from "react";
import PropTypes from "prop-types";
import Wrapper from "components/LayoutFront/Wrapper";

const Decrees = (props) => {
  const { decrees } = props;

  return <Wrapper>{decrees}</Wrapper>;
};

Decrees.propTypes = {
  decrees: PropTypes.node,
};

Decrees.defaultProps = {
  decrees: null,
};

export default Decrees;
