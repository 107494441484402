import React, { useContext } from "react";
import PropTypes from "prop-types";
import Block from "components/templatesComponents/Block";
import Grid from "@material-ui/core/Grid";
import { ImageContainer } from "components/templatesComponents/Image";
import SeeMoreLink from "components/templatesComponents/SeeMoreLink";
import SectionTitle from "components/templatesComponents/SectionTitle";
import Tags from "components/templatesComponents/Tags";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const DefaultSubTemplate = (props) => {
  const { page } = props;

  const { language } = useContext(LanguageContext);

  return (
    <Block>
      <Grid container spacing={3} alignContent="space-between">
        {page.image && (
          <Grid item xs={12} lg={7}>
            <ImageContainer ratio={50}>{page.image}</ImageContainer>
          </Grid>
        )}
        <Grid item xs={12} lg={page.image ? 5 : 12}>
          <Grid container direction="column" justifyContent="space-between" spacing={2}>
            <Grid item>
              <SectionTitle>{page.title}</SectionTitle>
            </Grid>
            {page.tags && page.tags.length > 0 && (
              <Grid item>
                <Tags tags={page.tags} noIcon />
              </Grid>
            )}
            {page.shortDescription && <Grid item>{page.shortDescription}</Grid>}
            <Grid item>
              <SeeMoreLink page={page}>{t[language].common.read_more}</SeeMoreLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Block>
  );
};

DefaultSubTemplate.propTypes = {
  page: PropTypes.shape(),
};

DefaultSubTemplate.defaultProps = {
  page: {},
};

export default DefaultSubTemplate;
