import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Icon from "components/templatesComponents/Icon";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";
import { getTranslateIndex } from "utils/commonUtils";
import categories from "components/templates/geolocalisation/geolocalisation.categories.enum.json";

const GeolocMapHeader = (props) => {
  const { geoPointsLength, cityPosition, source } = props;
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));
  const { language } = useContext(LanguageContext);
  const { basic, specific } = categories[source] || {};

  const displayColumnItems = isMobile || source === "mairie";

  return (
    <Grid
      container
      direction={isMobile ? "column" : "row"}
      justifyContent="space-between"
      alignItems={isMobile ? "flex-start" : "center"}
      spacing={2}
    >
      <Grid item xs lg={5} style={{ marginRight: theme.spacing(1) }}>
        <p>
          <b> {geoPointsLength} </b> {t[language].geolocalisation.results_message[getTranslateIndex(geoPointsLength)]}{" "}
          {cityPosition}
        </p>
      </Grid>
      {source !== "siv" && (
        <Grid
          item
          xs
          container
          direction={displayColumnItems ? "column" : "row"}
          alignItems={displayColumnItems ? "flex-start" : "center"}
          spacing={2}
          aria-hidden
        >
          <Grid item>
            <Icon
              icon="circle"
              type="fas"
              iconDSFR={source === "mairie" ? "bank-line" : "camera-line"}
              style={{ color: "#396FF1", marginRight: theme.spacing(1) }}
            />
            {t[language].geolocalisation[basic]}
          </Grid>
          <Grid item>
            <Icon
              icon="circle"
              type="fas"
              iconDSFR={source === "mairie" ? "bank-fill" : "camera-fill"}
              style={{ color: "#F32121", marginRight: theme.spacing(1) }}
            />
            {t[language].geolocalisation[specific]}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

GeolocMapHeader.propTypes = {
  geoPointsLength: PropTypes.number,
  cityPosition: PropTypes.string,
  source: PropTypes.string.isRequired,
};

GeolocMapHeader.defaultProps = {
  geoPointsLength: null,
  cityPosition: "",
};

export default GeolocMapHeader;
